<template>
  <div class="fixed-plugin">
    <div class="shadow-lg card blur"  style="overflow: auto !important;">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-1">Slides</h5>
          <!-- <p>See our dashboard options.</p> -->
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <font-awesome-icon icon="fa-solid fa-close"/>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <div class="mt-3">
        <h6 class="mb-0">Speed: {{this.speed}} %</h6>
      </div>
      
      <input type="range" v-model="speed" v-on:mouseup="setSlideSpeed" v-on:touchend="setSlideSpeed" min="0" max="200" value="100" class="w-100">
      <br>
      <div >

        <div v-if="this.$store.state.isLoggedIn">
          <a
            class="btn bg-gradient-dark w-100"
            @click="onSlide"
            >Add Slide</a>
            <input type="file" ref="file" style="display: none" accept="image/png, image/jpeg" @input="onFile(this)">
          <draggable
            class="list-group"
            tag="ul"
            v-model="list"
            v-bind="dragOptions"
            @start="isDragging = true"
            @end="onDrop"
           
          >
            <transition-group type="transition" name="flip-list">
              <ul class="list-group"
                v-for="(element, index) in list"
                :key="element.name"
                @click="onSlideId(index)"
                @touchstart="onSlideId(index,$event)"
              >
              <li class="px-1 mb-2 border-0 list-group-item d-flex align-items-center">
                <soft-avatar
                  style="width:90px;"
                  :img="`${element.url}`"
                  alt="kal"
                  border-radius="lg"
                  shadow="regular"
                 
                />
                 <i style="width:100px; margin-left:10px;">{{ index+1 }} - {{ element.name }}</i>
                <i style="cursor:pointer; margin-left: 90px;" @touchstart="onDelete(element,$event)" @click="onDelete(element)"><font-awesome-icon icon="fa-solid fa-trash" /></i>
              </li> 
            </ul>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { engine_event } from "../engine";
import draggable from "vuedraggable";
import SoftAvatar from "@/components/SoftAvatar.vue";
export default {
  name: "slides_panel",
  props: ["toggle"],
  components:{
    draggable,
    SoftAvatar,
  },
  data() {
    return {
      enabled: true,
      dragOptions:null,
      list: [
        
      ],
      dragging: false,
      slideFile: null,
      speed: 100,
      
    };
  },
  methods: {
    ...mapActions(["toggleSidebarColor"]),
    onDelete(element,event)
    {
      if(event)
        event.preventDefault();
      const index = this.list.indexOf(element);
      if (index > -1) { // only splice array when item is found
        this.list.splice(index, 1); // 2nd parameter means remove one item only
        engine_event.emit("delete_slide",index);
      }
    },
    onDrop(event)
    {
      this.isDragging = false;
      console.log({event});
      engine_event.emit("swap_slides",event.oldIndex,event.newIndex);
    },
    onSlide()
    {
      this.$refs.file.click();
    },
    onSlideId(index,event)
    {
      if(event)
        event.preventDefault();
      engine_event.emit("onSlideId",index);
    },
    setSlideSpeed()
    {
      
      engine_event.emit("onSlideSpeed",this.speed);
    },
    async onFile(input)
    {
      console.log(input);
      if (this.$refs.file.files && this.$refs.file.files[0]) {
        
        var local_url = URL.createObjectURL(this.$refs.file.files[0]);
        engine_event.emit("onLoadSlide",local_url,true);

      }
    }
  },
  computed: {
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  mounted()
  {
    engine_event.addListener('clear_slides', function() {
      this.list=[];
    }.bind(this));
    engine_event.addListener('slide', function(url) {
      this.list.push({name:'Slide '+this.list.length,url:url})
    }.bind(this));
  },
};
</script>

<style scoped>
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .button {
    margin-top: 35px;
  }
  .flip-list-move {
    transition: transform 0.3s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  /* .list-group {
    min-height: 150px;
  }
  .list-group-item {
    height: 150px;
    cursor: move;
  }
  .list-group-item i {
    height: 150px;
    cursor: pointer;
  } */
  </style>