/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_identity_pool_id": "ap-northeast-2:00874e5c-2217-450a-ac6d-4ad1cf9230be",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_pCCmMMx9s",
    "aws_user_pools_web_client_id": "41s0q0fu1moipogfkphstfq45o",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "getassets",
            "endpoint": "https://9b8v7rfue8.execute-api.ap-northeast-2.amazonaws.com/next",
            "region": "ap-northeast-2"
        },
        {
            "name": "rpgUserProfile",
            "endpoint": "https://55pt3fw4fh.execute-api.ap-northeast-2.amazonaws.com/next",
            "region": "ap-northeast-2"
        },
        {
            "name": "rpgStoreInventory",
            "endpoint": "https://6m4tvi6l6g.execute-api.ap-northeast-2.amazonaws.com/next",
            "region": "ap-northeast-2"
        },
        {
            "name": "getturnserver",
            "endpoint": "https://6q31y07y72.execute-api.ap-northeast-2.amazonaws.com/next",
            "region": "ap-northeast-2"
        },
        {
            "name": "rpgBuyAsset",
            "endpoint": "https://3wh8vfrfmg.execute-api.ap-northeast-2.amazonaws.com/next",
            "region": "ap-northeast-2"
        },
        {
            "name": "rpgPromocode",
            "endpoint": "https://7yjn1yn67a.execute-api.ap-northeast-2.amazonaws.com/next",
            "region": "ap-northeast-2"
        },
        {
            "name": "prgUpload",
            "endpoint": "https://329bd5qssl.execute-api.ap-northeast-2.amazonaws.com/next",
            "region": "ap-northeast-2"
        },
        {
            "name": "rpgPayment",
            "endpoint": "https://1fcnn84sml.execute-api.ap-northeast-2.amazonaws.com/next",
            "region": "ap-northeast-2"
        },
        {
            "name": "rpgPaymentResponse",
            "endpoint": "https://ntomxnkq9d.execute-api.ap-northeast-2.amazonaws.com/next",
            "region": "ap-northeast-2"
        },
        {
            "name": "rpgLicense",
            "endpoint": "https://twgyqoaim4.execute-api.ap-northeast-2.amazonaws.com/next",
            "region": "ap-northeast-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-northeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "RPG_UserTable",
            "region": "ap-northeast-2"
        },
        {
            "tableName": "RPG_Market",
            "region": "ap-northeast-2"
        },
        {
            "tableName": "RPG_PromocodeMap",
            "region": "ap-northeast-2"
        },
        {
            "tableName": "RPG_Promocodes",
            "region": "ap-northeast-2"
        },
        {
            "tableName": "RPG_License",
            "region": "ap-northeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "red-pill-go",
    "aws_user_files_s3_bucket_region": "ap-northeast-2"
};


export default awsmobile;
