<template>
  <div >

    <configurator
      :toggle="toggleConfigurator"
      :class="[
        this.$store.state.showConfig ? 'show' : '',
        
      ]"
    />
    <character-panel
      :toggle="toggleCharacterPanel"
      :class="[
        this.$store.state.showCharacterPanel ? 'show' : '',
        
      ]"
    />
    <environment-panel
      :toggle="toggleEnvironmentPanel"
      :class="[
        this.$store.state.showEnvironmentPanel ? 'show' : '',
        
      ]"
    />
    <slides-panel
      :toggle="toggleSlidesPanel"
      :class="[
        this.$store.state.showSlidesPanel ? 'show' : '',
        
      ]"
    />

    <camera-panel
      :toggle="toggleCameraPanel"
      :class="[
        this.$store.state.showCameraPanel ? 'show' : '',
        
      ]"
    />
    <recording-panel
      :toggle="toggleRecordingPanel"
      :class="[
        this.$store.state.showRecordingPanel ? 'show' : '',
        
      ]"
    />

    <sidenav
        is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
        v-if="this.$store.state.showSidenav"
    />

    <div class="fixed-plugin" v-if="!this.$store.state.showSidenav" style="position: absolute; top:0; left:0; z-index: 10; margin:35px">
      <a class="px-1 py-2 fixed-plugin-button text-dark "
        style="background-color: rgba(255,255,255,0.6)"
      
        @click="showNavbar"
      >
  
      <img :src="logo" width="35" height="35" class="navbar-brand-img h-50" alt="main_logo" />
      </a>
    </div> 

    <main
      class="main-content position-relative max-height-vh-100 h-100 border-radius-lg "
    >
     
      <router-view />

    
    </main>
  </div>
</template>
<script>
import Sidenav from "./Navbars/Sidenav";
import Configurator from "@/components/Configurator.vue";
import CharacterPanel from "@/components/CharacterPanel.vue";
import EnvironmentPanel from "@/components/EnvironmentPanel.vue";
import SlidesPanel from "@/components/SlidesPanel.vue";
import CameraPanel from "@/components/CameraPanel.vue";
import RecordingPanel from "@/components/RecordingPanel.vue";
import logo from "@/assets/img/redpill.png";
import { mapMutations } from "vuex";

export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    CharacterPanel,
    EnvironmentPanel,
    SlidesPanel,
    CameraPanel,
    RecordingPanel,
    
  },
  data()
  {
    return{
      logo,
    }
  },
  created: function()
  {
    var x = window.matchMedia("(max-width: 768px)")
    this.myFunction(x)
    x.addListener(this.myFunction)

    

  },
  methods: {
    ...mapMutations(["showNavbar", "toggleConfigurator","toggleCharacterPanel","toggleEnvironmentPanel","toggleSlidesPanel", "toggleCameraPanel","toggleRecordingPanel"]),
    myFunction(x) {
      if (x.matches) { // If media query matches

        this.$store.state.showSidenav = false;
        // this.$store.state.showNavbar = true;
      } else {

        // this.$store.state.showNavbar = false;
        this.$store.state.showSidenav = true;
      }
    }
  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
    this.$store.state.color = `gored`;
    
   
  },
};
</script>
