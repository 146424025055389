<template>
  <div class="fixed-plugin">
    <div class=" card blur" style="overflow: auto !important;">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Environments</h5>
          <!-- <p>See our dashboard options.</p> -->
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <font-awesome-icon icon="fa-solid fa-close"/>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <!-- <hr class="my-1 horizontal dark" /> -->
      <div  >
        <div v-if="this.$store.state.isLoggedIn">
          <ul class="list-group" :key="index"  v-for="(env, index) in this.$store.state.environments">
            <li
                class="px-4 mb-2 border-0 list-group-item d-flex align-items-center"
                style="background-color: transparent!important; cursor: pointer;"
                v-on:click="setEnvironment(`${index}`)"
              >
                <soft-avatar
                  class="me-3"
                  :img="`${env[8]}`"
                  alt="kal"
                  border-radius="lg"
                  shadow="regular"
                />
                <!-- "require(`@/assets/img/marie.jpg`)" -->
                <div
                  class="d-flex align-items-start flex-column justify-content-center"
                >
                  <h6 class="mb-0 text-sm">{{env[7]}}</h6>
                  <p class="mb-0 text-xs">Price: Free</p>
                </div>
                <!-- <a
                  class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                  href="javascript:;"
                  >Buy</a
                > -->
              </li>
          </ul>
          <div>
            <!-- :default=" \" \\"text\\":\\"-- Video Background --\\" \"" -->
          <CustomSelect v-if="cameras.length>0" v-model="selected_cam"
            :options="cameras"
            :attribute="'text'"
            :default="'Video Background'"
            class="select"
            v-on:input="setCamera"/>
            <br>
          <a
            class="btn bg-gradient-dark w-100"
            v-on:click="getDevices"
            >Use Camera</a
          >
          <!-- <b-form-select v-if="cameras.length>0" v-model="selected_cam" :options="cameras" v-on:change="setCamera" > -->
           <!-- <template #first>
            <b-form-select-option :value="null" disabled>-- Video Background --</b-form-select-option>
          </template> -->
          <!-- :options="cameras"
            :default="'-- Video Background --'" -->
          
        <!-- </b-form-select> -->
        </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { engine_event } from '@/engine'
import SoftAvatar from "@/components/SoftAvatar.vue";
import CustomSelect from "@/components/CustomSelect.vue";
export default {
  name: "environments_panel",
  components: {
    SoftAvatar,
    CustomSelect,
  },
  props: ["toggle"],
  data() {
    return {
      loading: false,
      cameras: [],
      selected_cam: null,
    };
  },
  methods: {
    ...mapActions(["toggleSidebarColor"]),
    async setEnvironment(name)
		{
			
			this.loading = true;
      try{
         let promiseResolve;
        const promise = new Promise((resolve) => {promiseResolve=resolve;});
        await engine_event.emit('onLoadEnvironment', this.$store.state.environments[name].slice(0,6),this.$store.state.environments[name][6], promiseResolve);
        await promise;
        
        this.loading = false;
        let video = document.getElementById("video2");
       
        video.srcObject = null;
        video.stop();
        
      }catch(error){  
          this.loading = false;
          }
			
		
		},
    getDevices()
    {

      function streamHandler()
      {
          //console.log("streamHandler OK");
          navigator.mediaDevices.enumerateDevices()
          .then(function(devices){
            let video_devices = devices.filter(d=>d.kind=='videoinput')
            this.cameras = []
            for(var v in video_devices)
            {
              if(video_devices[v].deviceId!="")
              {
                //console.log("camera: "+video_devices[v].deviceId +" "+video_devices[v].label);
                this.cameras.push({ value: video_devices[v].deviceId, text: video_devices[v].label })
              }
            }
          }.bind(this))
          .catch(function(err)
          {
            console.log(err.name + ": " + err.message);
          });
      }
      function errorHandler(err)
      {
          console.log(err);
      }
      navigator.getUserMedia = (
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia
      );

        if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
            navigator.getUserMedia({
            video: true
        }, streamHandler.bind(this), errorHandler);
        } else {
            navigator.mediaDevices.getUserMedia({
                video: true
            }).then(streamHandler.bind(this)).catch(errorHandler);
        }

    },
    async setCamera(selected_cam)
    {
      console.log({selected_cam});
      if(!selected_cam.value) return;
        let video = document.getElementById("video2");
        video.load();
        let stream = await navigator.mediaDevices.getUserMedia({
                  video: {
                      deviceId: {exact: selected_cam.value},
                      width: {ideal:1920}, height: {ideal:1080},
                      frameRate: {min:20, ideal:30, max:60}
                  }
              });
        video.srcObject = stream;
        video.play();
        engine_event.emit('onDisableBackground');
    },
  },
  computed: {
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  watch: {
		loading: function (loading) {
			if (loading) 
			{
				this.loader = this.$loading.show({container: this.$refs.loadingContainer});
			} else 
			{
				this.loader.hide();
				this.loader = null;
			}
		},
  },
};
</script>
<style scoped>
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  </style>

