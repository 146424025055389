<template>
  <div class="fixed-plugin">
    <div class=" card blur"  style="overflow: auto !important;">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-1">Cameras</h5>
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <font-awesome-icon icon="fa-solid fa-close"/>
          </button>
        </div>
      </div>
      <br>
      <div >

        <div v-if="this.$store.state.isLoggedIn">
          <a
            class="btn bg-gradient-dark w-100"
            @click="onCamera"
            >Add Camera</a>
            <a v-if="$store.state.cameras.length>1"
            class="btn bg-gradient-dark w-100"
            v-on:click="play=!play; onPlay()"
            ><div v-if="!play" >Play All</div>
            <div v-else >Stop Playback</div></a>
          <draggable
            class="list-group"
            tag="ul"
            v-model="$store.state.cameras"
            v-bind="dragOptions"
            @start="isDragging = true"
            @end="onDrop"
            :options="{handle: '.drag'}"
          >
            <transition-group type="transition" name="flip-list" >
              <ul class="list-group" 
                v-for="(element, index) in $store.state.cameras"
                :key="index"
              >
              <li class="px-1 mb-2 border-0 list-group-item d-flex align-items-center" style="height:150px;">
                <font-awesome-icon icon="fa-solid fa-up-down" class="drag" style="margin-left:5px; cursor: move;"/>
                <camera-card
                  :cam="element"
                  :del="onDelete"
                  :activate="onActivate"
                  style="width:100%; margin-left:10px; "
                  border-radius="lg"
                  shadow="regular"
                  />
              </li> 
            </ul>
            </transition-group>
          </draggable>
          
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { engine_event } from "../engine";
import draggable from "vuedraggable";
import CameraCard from "@/components/CameraCard.vue";
import * as Camera from '@/camera.js';
import {v4 as uuid} from 'uuid';

export default {
  name: "camera_panel",
  props: ["toggle"],
  components:{
    draggable,
    CameraCard,
    
  },
  data() {
    return {
      enabled: true,
      dragOptions:null,
      dragging: false,
      slideFile: null,
      speed: 100,
      play:false,
      object:{}
      
    };
  },
  methods: {
    
    ...mapActions(["toggleSidebarColor"]),
    onDelete(element)
    {
      const index = this.$store.state.cameras.indexOf(element);
      if (index > -1) { // only splice array when item is found
        this.$store.state.cameras.splice(index, 1); // 2nd parameter means remove one item only
        engine_event.emit("removeCamera", element.camera);
        engine_event.emit("removeScript", element.camera, element.script);
      }
      if(this.$store.state.cameras.length==0)
      {
        engine_event.emit("resetCamera");
        this.play = false;
      }
    },
    onDummy()
    {

    },
    onDrop(event)
    {
      this.isDragging = false;
      engine_event.emit("swap_cameras",event.oldIndex,event.newIndex);
      //let temp = this.$store.state.cameras[event.oldIndex];
			//this.$store.state.cameras.splice(event.oldIndex,1);
			//this.$store.state.cameras.splice(event.newIndex, 0, temp);

      // for(let i=0; i<this.$store.state.cameras.length; i++)
      // {
      //   console.log("type "+this.$store.state.cameras[i].type);
      // }
      //engine_event.emit("swap_slides",event.oldIndex,event.newIndex);
    },
    onCamera()
    {
      
      engine_event.emit('getViewportCamera', (view_camera)=>{
        const camera = new Camera.Camera();
        camera.camera = view_camera.clone();
        camera.fov = view_camera.getFocalLength();
        camera.setStart(camera.camera.matrix);
        this.$store.state.cameras.push(camera);
        engine_event.emit("addCamera", camera.camera);
        engine_event.emit("addScript", camera.camera, camera.script);
        //this.list.push(camera);
      });
     
      
      
    },
    onActivate(event, active)
    {
      this.$store.state.cameras.forEach(element => {

        if(element!=event)
        {
          element.setActive( false);
        }
      });
      if(active)
      {
        engine_event.emit("setViewportCamera", event.camera.uuid);
        engine_event.emit('getLookAtTarget',event.setLookAtTarget.bind(event));
        event.time = 0;
      }
      else
      {
        engine_event.emit("resetCamera");
      }
    },
  
    setSlideSpeed()
    {
      engine_event.emit("onSlideSpeed",this.speed);
    },
    onPlay () 
    {
      if(this.play)
      {

        
        engine_event.emit("addScript",this.object, this.onUpdate);
        this.$store.state.cameras.forEach(element => {
          element.setActive(false);
        });
        this.$store.state.cameras[0].setActive(true);
        this.$store.state.cameras[0].time = 0;
        engine_event.emit("setViewportCamera", this.$store.state.cameras[0].camera.uuid);
        engine_event.emit('getLookAtTarget',this.$store.state.cameras[0].setLookAtTarget.bind(this.$store.state.cameras[0]));

      }
      else{
        engine_event.emit("removeScript",this.object, this.onUpdate);
        this.$store.state.cameras.forEach(element => {
          element.setActive(false);
        });
        engine_event.emit("resetCamera");
      }
    },
    onUpdate(delta)
    {
      delta;
      //console.log({delta});
      for(var i=0; i<this.$store.state.cameras.length; i++)
      {
        let element = this.$store.state.cameras[i];
        if(!element.active) continue;
        if(element.time>=element.duration)
        {
          let id = i+1;
          if(id>=this.$store.state.cameras.length)
            id=0;
          element.setActive(false);
          this.$store.state.cameras[id].setActive(true);
          this.$store.state.cameras[id].time=0;
          engine_event.emit("setViewportCamera", this.$store.state.cameras[id].camera.uuid);
          engine_event.emit('getLookAtTarget',this.$store.state.cameras[id].setLookAtTarget.bind(this.$store.state.cameras[id]));
          element.time = 0;
        }

      }
    },
   
  },
  computed: {
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  mounted()
  {
    this.object.uuid = uuid();
    engine_event.addListener('loadCamerasFromFile', function(json) {
      json;
      this.$store.state.cameras = [];
      if(json)
      {
        for(var c of json)
        {
          const camera = new Camera.Camera();
          camera.deserialize(c);
          this.$store.state.cameras.push(camera);
          //this.list.push(camera);
          engine_event.emit("addCamera", camera.camera);
          engine_event.emit("addScript", camera.camera, camera.script);
        }
      }
      //this.list.push({name:'Slide '+this.list.length,url:url})
    }.bind(this));
  },
};
</script>

<style scoped>
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .button {
    margin-top: 35px;
  }
  .flip-list-move {
    transition: transform 0.3s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  /* .list-group {
    min-height: 150px;
  }
  .list-group-item {
    height: 150px;
    cursor: move;
  }
  .list-group-item i {
    height: 150px;
    cursor: pointer;
  } */
  </style>