<template>
   <!-- class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl blur" -->
  <div style="display:table;">
    <div id="tooltip" class="hint" style="position: absolute; left:300px; top:0; z-index: 100; "></div>
    
  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl blur"
    :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'"
    id="sidenav-main"
    style="overflow-y: overlay!important; "
  >
    
    <div class="mt-2 float-start" @click="collapseAll" style="margin-left:10px;">
      <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
        <font-awesome-icon icon="fa-solid fa-angle-left"/>
      </button>
    </div>
    <br>
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <a class="m-0 navbar-brand" href="/"  @mousemove="showTooltip($event.pageX,$event.pageY,'Home')" @mouseleave="hideTooltip">
        <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" />
        <span class="ms-1 font-weight-bold">GO</span>
        <span class="ms-1" style="vertical-align: text-bottom; font-size: 12px;">beta</span>
      </a>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list id="sidenav" :cardBg="customClass" :onShowTooltip="showTooltip" :onHideTooltip="hideTooltip" />
    <div class="fixed-plugin">
      <a
        id="gear"
        class="nav-item px-3 py-2 fixed-plugin-button text-dark "
        style="left:22px!important;  bottom: -100px!important; position: relative!important; display:table-cell; margin-bottom: 0!important;"
        @click="toggleConfigurator"
        @mousemove="showTooltip($event.pageX,$event.pageY,'Settings')" @mouseleave="hideTooltip"
      >
      <font-awesome-icon icon="fa-solid fa-gear" />
        <i class="py-2 fa fa-cog"> </i>
       
      </a>
      
    </div>
  </aside> 
</div>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/redpill.png";
import { mapState,mapMutations} from "vuex";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  created: function()
  {
   
  },
  data() {
    return {
      logo,
    };
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  mounted() {
     console.log("Mounted");

     document.getElementById("gear").style.bottom =  -(document.getElementById("sidenav-main").offsetHeight-document.getElementById("sidenav").offsetHeight)/4 +"px";
     
  },
  methods: {
    ...mapMutations(["collapseAll","toggleConfigurator"]),
    showTooltip(posX,posY,titile)
    {
      let tooltip = document.getElementById("tooltip");
      tooltip.innerHTML = titile;
      //tooltip.style.display = "inline-block";
      tooltip.style.left = posX + 25 + 'px';
      tooltip.style.top = posY - 10 + 'px';
      tooltip.style.opacity = '1';
    },
    hideTooltip()
    {
      var tooltip = document.getElementById("tooltip");
      //tooltip.style.display = "none";
      tooltip.style.opacity = '0';
    },
    
  },
  computed: {
    ...mapState(["isRTL"]),
    
  },
};
</script>
<style scoped>
.hint {
  width: 120px;
  /* display: inline-block; */
  /* visibility: hidden; */
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
  transition: opacity .5s;

  opacity:0;
 
}
.hint::after {
  
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
    

  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f100;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
