<template>
  <div class="fixed-plugin">
    <div class="shadow-lg card blur"  style="overflow: auto !important;">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-1">Recording</h5>
          <!-- <p>See our dashboard options.</p> -->
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <font-awesome-icon icon="fa-solid fa-close"/>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <!-- <div class="mt-3">
        <h6 class="mb-0">Speed: {{this.speed}} %</h6>
      </div> -->
      
      <!-- <input type="range" v-model="speed" v-on:mouseup="setSlideSpeed" v-on:touchend="setSlideSpeed" min="0" max="200" value="100" class="w-100"> -->
      <br>
      <div >

        <div v-if="this.$store.state.isLoggedIn">
          <a v-if="!this.isRecording"
            class="btn bg-gradient-dark w-100"
            @click="onRec"
            >Start Recording</a>
          <a v-else
            class="btn bg-gradient-gored w-100"
            @click="onRec"
            >Stop Recording</a>
          <draggable
            class="list-group"
            tag="ul"
            v-model="list"
            v-bind="dragOptions"
            @start="isDragging = true"
            @end="onDrop"
           
          >
            <transition-group type="transition" name="flip-list">
              <ul class="list-group"
                v-for="(element, index) in list"
                :key="element.name"
                
              >
              <li class="px-0 mb-1 border-0 list-group-item d-flex align-items-center" style="cursor: grab;">
                <i v-if="!element.playing" style="margin-left:10px; cursor: pointer;" @touchstart="onRecId(index,$event)" @click="onRecId(index)"><font-awesome-icon  icon="fa-solid fa-play" /></i>
                <i v-else  style="margin-left:10px; cursor: pointer;" @touchstart="onRecId(index,$event)" @click="onRecId(index)"><font-awesome-icon  icon="fa-solid fa-stop"/></i>
                <i style="width:170px; margin-left:10px; font-size: 12px; font-weight: bold; cursor: grab;">{{ element.name }}</i>
                <i style="margin-left:10px; font-size: 12px; cursor: grab;">{{ new Date(element.duration * 1000).toISOString().slice(14, 19) }}</i>
                <i style="margin-left: auto; margin-right: 0px;">
                <i style="cursor:pointer; margin-left:10px; cursor: pointer;" @touchstart="onDelete(index)" @click="onDelete(index)"><font-awesome-icon icon="fa-solid fa-trash" /></i>
                <i style="cursor:pointer; margin-left:10px; margin-right:10px; cursor: pointer;" @touchstart="onExport(index)" @click="onExport(index)"><font-awesome-icon icon="fa-solid fa-cloud-arrow-down" /></i>
              </i>
              </li> 
            </ul>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>

import { engine_event } from "../engine";
import draggable from "vuedraggable";
import { API } from 'aws-amplify';
var CryptoJS = require("crypto-js");
// import SoftAvatar from "@/components/SoftAvatar.vue";
export default {
  name: "slides_panel",
  props: ["toggle"],
  components:{
    draggable,
    // SoftAvatar,
  },
  watch: {
		loading: function (loading) {
			if (loading) 
			{
				this.loader = this.$loading.show({container: this.$refs.loadingContainer});
			} else 
			{
				this.loader.hide();
				this.loader = null;
			}
		}
  },
  data() {
    return {
      enabled: true,
      dragOptions:null,
      list: [
        
      ],
      dragging: false,
      slideFile: null,
      speed: 100,
      isRecording: false,
      loading: false,
      
    };
  },
  methods: {
    async onExport(index)
    {
      console.log({index});

      if(this.list[index].playing)
      {
        engine_event.emit("onRecId",-1);
        this.list[index].playing = false;
      }
     
      engine_event.emit('get_etag',async(value) => {

        if(value[1]==true)//'vrm')
        {
          //await this.$dialog.alert("VRM download is currently not supported. Please switch to a GLTF/GLB character to download this motion.");
          await this.$dialog.alert("Warning! VRM model will be converted to GLB. MToon shaders will be lost.");
            //return;
        }
        this.loading = true;
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(this.list[index]), value[0]);
        let params = {
            'queryStringParameters':
            {
                etag: value[0],
                obj: ciphertext.toString()
            }
        }
        let data = await API.get('rpgBuyAsset','/price',params);
        console.log(data);
        this.loading = false;
        let price = "Free";
        if(data.price>0)
        {
          if(data.price==1)
            price = "1 credit"
          else if(data.price>1)
            price = data.price+" credits"

          const profile = await API.get('rpgUserProfile','/profile');
          if(profile.credit<data.price)
          {
            await this.$dialog.alert("You don't have enough credit to buy this animation.");
            return;
          }
        }
        console.log({price});
        let self = this;
        let name = this.list[index].uuid+value[0];
        this.$dialog.confirm('Animation price: '+price+'. Proceed with purchase?',{okText:"Purchase", cancelText:"Cancel"})
          .then(async function () 
          {
            console.log("Purchase")
            self.loading = true;
            await API.get('rpgBuyAsset','/purchase',params);
            //console.log(data);
            //engine_event.emit("onExport",index,ciphertext.toString());

            let promiseResolve;
            const promise = new Promise((resolve) => {promiseResolve=resolve;});
            await engine_event.emit('onExport', index, name, promiseResolve);
            await promise;

            self.loading = false;
          })
          .catch(function () 
          {
            console.log("Cancel")
          });
        
      });
      
      
      for(let i=0; i<this.list.length; i++)
        this.list[i].playing = false;
    },
    onDelete(index)
    {
      let self = this;

      this.$dialog.confirm('Are you sure you want to delete this clip?',{okText:"Delete", cancelText:"Cancel"})
      .then(async function () {
        if(self.list[index].playing)
        {
          engine_event.emit("onRecId",-1);
          self.list[index].playing = false;
        }
       
        if (index > -1) { // only splice array when item is found
          self.list.splice(index, 1); // 2nd parameter means remove one item only
          engine_event.emit("delete_rec",index);
        }
      })
          .catch(function () {
            
      });
      
    },
    onDrop(event)
    {
      this.isDragging = false;
      console.log({event});
      engine_event.emit("swap_slides",event.oldIndex,event.newIndex);
    },
    onRec()
    {
      this.isRecording = !this.isRecording;
      engine_event.emit("onRecord", this.isRecording);
    },
    onRecId(index, event)
    {
      if(event)
        event.preventDefault();
      if(this.list[index].playing)
      {
        engine_event.emit("onRecId",-1);
        this.list[index].playing = false;
        return;
      }
      for(let i=0; i<this.list.length; i++)
        this.list[i].playing = false;

      engine_event.emit('isConnected',(value) => {
        if(value==true)
        {
          let self = this;
          this.$dialog.confirm('You are still connected to live motion source!',{okText:"Disconnect and play", cancelText:"Stay connected"})
          .then(async function () {
            console.log('Clicked on disconnect ');
            let promiseResolve;
            const promise = new Promise((resolve) => {promiseResolve=resolve;});
            await engine_event.emit('onConnect',"000000",promiseResolve);
            await promise;
            engine_event.emit("onRecId",index);
            self.list[index].playing = true;
          })
          .catch(function () {
            console.log('Clicked on cancel')
            return;
          });
        }
        else{
          engine_event.emit("onRecId",index);
          this.list[index].playing = true;
        }

      });
      
    },
   
    
  },
  computed: {
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  mounted()
  {
    engine_event.addListener('clear_slides', function() {
      this.list=[];
    }.bind(this));
    engine_event.addListener('recording', function(clip) {
      this.list.push({name:clip.name,duration:clip.duration,playing:false, uuid:clip.uuid})
    }.bind(this));

    engine_event.addListener('recording_downloaded', async function(etag, clip) {
      let item = {name:clip.name,duration:clip.duration,playing:false, uuid:clip.uuid};
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(item), etag);
      let params = {
            'queryStringParameters':
            {
                etag: etag,
                obj: ciphertext.toString()
            }
        }
        await API.put('rpgBuyAsset','/purchase',params);
    }.bind(this));
  },
};
</script>

<style scoped>
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .button {
    margin-top: 35px;
  }
  .flip-list-move {
    transition: transform 0.3s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  /* .list-group {
    min-height: 150px;
  }
  .list-group-item {
    height: 150px;
    cursor: move;
  }
  .list-group-item i {
    height: 150px;
    cursor: pointer;
  } */
  </style>