import * as THREE from 'three'
import * as utils from "./utils.mjs";
import * as Retargeter from '@/retargeter.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import store from '@/store'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { saveAs } from "file-saver";
import { VRMExpressionPresetName, VRMLoaderPlugin } from '@pixiv/three-vrm';
import { LoadingManager } from 'three/src/loaders/LoadingManager.js';
import { Parser } from '@/parser.js'
import { Client } from '@/WebRTCClient.js';
import { API } from 'aws-amplify';
import * as aws_helper from "@/amplify_helper.js";
import { zipSync } from 'fflate';
//import { clone } from 'three/examples/jsm/utils/SkeletonUtils.js';
var emitter = require('events').EventEmitter;
var MediaStreamRecorder = require('msr');
export let engine_event = new emitter();
//export {pubsub}

var WS_URL = 'wss://webrtc.rplab.online/';

function getKeyByValue(object, value) {
	return Object.keys(object).find(key => object[key] === value);
  }

function getDateString()
{
	const dateObj = new Date();
	let year = dateObj.getFullYear();

	let month = dateObj.getMonth();
	month = ('0' + (month + 1)).slice(-2);
	// To make sure the month always has 2-character-format. For example, 1 => 01, 2 => 02

	let date = dateObj.getDate();
	date = ('0' + date).slice(-2);
	// To make sure the date always has 2-character-format

	let hour = dateObj.getHours();
	hour = ('0' + hour).slice(-2);
	// To make sure the hour always has 2-character-format

	let minute = dateObj.getMinutes();
	minute = ('0' + minute).slice(-2);
	// To make sure the minute always has 2-character-format

	let second = dateObj.getSeconds();
	second = ('0' + second).slice(-2);
	// To make sure the second always has 2-character-format

	const time = `${year}_${month}_${date}_${hour}_${minute}_${second}`;
	return time
}
function string_hash(str) 
{
  var hash = 0,
    i, chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  console.log(str+" - "+Math.abs(hash));
  return Math.abs(hash);
}
export class Engine{
	constructor()
	{
		console.log("Create Engine!!!!");
		this.container = document.getElementById('engine');
		this.sizes;
		this.scene;
		this.current_model = undefined;
		this.current_model_url;
		this.current_json_url;
		this.current_animated_clip
		this.current_animated_object;
		this.current_animated_object_url;
		this.current_environment;
		this.current_environment_urls;
		this.current_3d_environments = [];
		this.current_3d_environment_urls = [];
		this.current_environment_name = "";
		this.current_character_name = "";
		this.camera;
		this.renderer;
		//this.gui = new dat.GUI();
		this.audio = document.querySelector('audio#audio2');
		this.lightProbe;
		this.bones = [];
		this.morphs = [];
		this.recording_morphs = [];
		this.clock = new THREE.Clock()
		this.animator = new utils.Animator();
		//this.stats;
		this.controls;
		this.API;
        this.em = new emitter();
		this.current_project;
		this.loaded_environments = [];
		this.loaded_characters = [];
		this.loaded_bones = [];
		this.loaded_morphs = [];
		// this.retargeter = new MotionRetargeter();
		this.retargeter = new Retargeter.Retargeter();
		this.animation;
		this.camera_motions = [];
		this.camera_motion_object = undefined;
		this.client;
		this.skeleton;
		this.hemiLight;
		this.dirLight;
		//this.spotLight;
		this.ground;
		this.wrists = true;
		// this.web_parser;
		this.skeleton_geometry;
		this.ikRig_solved;
		this.ikRig_chains;
		this.ikRig_poles
		this.boneContainer;
		this.camera_index = 0;
		this.screen;
		this.slides = [];
		this.slide_urls = [];
		this.slide_id = 0;
		this.time = 0;
		this.fps_time =0;
		this.fps_frames =0;
		this.slide_speed = 1;
		this.slide_delay =5;
		this.mouse_down = false;
		this.mouseX = 0;
        this.mouseY = 0;
		this.rotation_gizmo = null;
		this.translation_gizmo = null;
		this.raycaster = new THREE.Raycaster()
		this.gizmos = []
		this.gizmo_mode = '';
		this.scripts = {};
		this.recording = false;
		this.recording_list=[];
		this.rec_id = -1;
		this.mediaRecorder;
		this.audioDataArray = [];

		return ( ()=>
		{
			
			this.initRenderer();
			//this.initStats();
			this.defaultCamera = this.initCamera();
			this.viewportCamera = this.defaultCamera;
			this.cameras = {};
			this.initScene();
			this.initControls();
			//this.res_func = ()=>{this.onWindowResize();}
			window.addEventListener( "resize", ()=>{this.onWindowResize()} );
			window.addEventListener('orientationchange', function() {
				console.log(window.orientation);
				if(window.orientation==0)
				{
					//console.log("portrait",window.innerHeight)
					this.onWindowResize();
				}
				else
				{
					//console.log("landscape",window.innerHeight)
					this.onWindowResize();
				}
			}, false);


			this.onWindowResize();
		

			// });
			this.update();

			engine_event.addListener('onLoadVRM', async function(urls,name,promiseResolve) {
				await this.loadVRM(urls,name);
				promiseResolve()
			}.bind(this));
			engine_event.addListener('onLoadGLB', async function(urls,name,promiseResolve) {
				await this.loadCharacter(urls,name);
				promiseResolve()
			}.bind(this));
			engine_event.addListener('onLoad3DEnvironment',  async function(name,remove,promiseResolve) {
				await this.load3DEnvironment(name,remove);
				promiseResolve()
			}.bind(this));
			engine_event.addListener('onLoadAnimatedModel', async function(name,promiseResolve) {
				await this.loadAnimatedModel(name);
				promiseResolve()
			}.bind(this));
			engine_event.addListener('onLoadEnvironment', async function(urls,name,promiseResolve) {
				await this.loadEnvironment(urls,name);
				promiseResolve()
			}.bind(this));
			engine_event.addListener('onLoadSlides', async function(urls,promiseResolve) {
				await this.onLoadSlides(urls);
				promiseResolve()
			}.bind(this));
			engine_event.addListener('onLoadSlide', async function(url,local) {
				await this.onLoadSlide(url,local);
				//promiseResolve()
			}.bind(this));
			engine_event.addListener('onLoadAnimation', async function(urls,promiseResolve) {
				await this.loadAnimation(urls);
				promiseResolve()
			}.bind(this));
			engine_event.addListener('onLoadCameraMotion', async function(urls,promiseResolve) {
				await this.loadCameraMotion(urls);
				promiseResolve()
			}.bind(this));
			// engine_event.addListener('onLoadProject', async function(json,char,env,promiseResolve) {
			// 	await this.loadProject(json,char,env);
			// 	promiseResolve()
			// }.bind(this));
			engine_event.addListener('onRotateCharacter', this.rotateCharacter.bind(this));
			engine_event.addListener('onDisableBackground', this.disableBackground.bind(this));
			engine_event.addListener('onToggleStats', this.ToggleStats.bind(this));
			engine_event.addListener('onSkeleton', function(visible) {
				this.skeleton.visible = visible;
				this.ikRigSkeleton.visible = visible;
				this.ikRigSkeletonSolved.visible = visible;
				this.ikRigPoles.visible = visible;
				// if(this.retargeter.ikRig.height>0) 
				// 	this.skeleton.visible = false;
				// else{
				// 	this.ikRigSkeleton.visible = false;
				// 	this.ikRigSkeletonSolved.visible = false;
				// 	this.ikRigPoles.visible = false;
				// }
			}.bind(this));
			engine_event.addListener('onWrist', function(val) {
				this.wrists = val;
			}.bind(this));
			engine_event.addListener('onVolume', function(buffer) {
				console.log("change volume");
				this.client.send(buffer);
			}.bind(this));
			engine_event.addListener('onCalibrate', function(buffer) {
				console.log("calibrate")
				this.client.send(buffer);
			}.bind(this));
			engine_event.addListener('onSetStage', this.setStage.bind(this));
			engine_event.addListener('onInitStats', this.initStats.bind(this));

			engine_event.addListener('onConnect', async function(room,promiseResolve) {
				this.audio.load();
				// this.web_parser.ConnectClick(room);
				if(!this.client.isConnected()){
					this.client.connect(WS_URL + room);
					this.track_user();

					this.rec_id = -1;
					this.rec_time=0;
					this.mixer.stopAllAction();
					let idle = this.mixer.clipAction( this.default_animation );
					idle.play();
					this.retargeter.ikRig.height = 0;
					// if(this.skeleton.visible || this.ikRigSkeleton.visible)
					// {
					// 	this.skeleton.visible = false;
					// 	this.ikRigSkeleton.visible = true;
					// 	this.ikRigPoles.visible = true;
					// 	this.ikRigSkeletonSolved.visible = true;
					
					// }
					
				}
				else{
					this.client.close();
					engine_event.emit("disconnect");
					// if(this.skeleton.visible || this.ikRigSkeleton.visible)
					// {
					// 	this.skeleton.visible = true;
					// 	this.ikRigSkeleton.visible = false;
					// 	this.ikRigPoles.visible = false;
					// 	this.ikRigSkeletonSolved.visible = false;
					// }
					//this.mediaRecorder = undefined;
				}
				promiseResolve()
			}.bind(this));
			
			engine_event.addListener("isConnected", function(callback){
				callback(this.client.isConnected());
			}.bind(this));

			engine_event.addListener('onCameraMotion',  function(index) {
				this.onCameraMotion(index)
			}.bind(this));
			engine_event.addListener('onSaveProject',function(promiseResolve) {
				let output = this.saveProject();
				promiseResolve(output)
			}.bind(this));
			engine_event.addListener('screenshot',function(promiseResolve) {

				this.renderer.setSize(300,300);
				this.viewportCamera.aspect = 1
				this.viewportCamera.updateProjectionMatrix()
				this.renderer.render(this.scene, this.viewportCamera);
				var strMime = "image/jpeg";
				let output = this.renderer.domElement.toDataURL(strMime);
				promiseResolve(output.replace(strMime, "image/octet-stream"))
				this.renderer.setSize(this.container.clientWidth,this.container.clientHeight)
				this.viewportCamera.aspect = this.container.clientWidth/this.container.clientHeight;
				this.viewportCamera.updateProjectionMatrix()
			}.bind(this));
			engine_event.addListener('getSlides',function(promiseResolve) {
				promiseResolve(this.slides)
			}.bind(this));
			engine_event.addListener('onLoadProject', async function(json,promiseResolve) {
				await this.loadProject(json);
				promiseResolve()
			}.bind(this));
			engine_event.addListener('onMode',  function(mode) {
				if(mode==this.gizmo_mode)
				{
					this.gizmo_mode = '';
					this.scene.remove( this.translation_gizmo );
					this.scene.remove( this.rotation_gizmo );

					return;
				}
				if(mode=="rotate")
				{
					//this.rotation_gizmo.visible = true;
					//this.translation_gizmo.visible = false;
					this.scene.add( this.rotation_gizmo );
					this.scene.remove( this.translation_gizmo );
				}
				else if(mode == "translate")
				{
					//this.rotation_gizmo.visible = false;
					//this.translation_gizmo.visible = true;
					this.scene.remove( this.rotation_gizmo );
					this.scene.add( this.translation_gizmo );
				}
				this.gizmo_mode = mode;

			}.bind(this));
			engine_event.addListener('swap_slides',function(oldIndex,newIndex){
				
				let temp = this.slides[oldIndex];
				this.slides.splice(oldIndex,1);
				this.slides.splice(newIndex, 0, temp);

				let temp2 = this.slide_urls[oldIndex];
				this.slide_urls.splice(oldIndex,1);
				this.slide_urls.splice(newIndex, 0, temp2);
			}.bind(this));
			engine_event.addListener('swap_cameras',function(oldIndex,newIndex){
				
				let temp = this.cameras[oldIndex];
				this.cameras.splice(oldIndex,1);
				this.cameras.splice(newIndex, 0, temp);

				
			}.bind(this));
			engine_event.addListener('delete_slide',function(index){
				
				this.slides.splice(index, 1);
				this.slide_urls.splice(index, 1);
			}.bind(this));
			engine_event.addListener('onSlideSpeed',function(speed){
				this.slide_speed = speed/100;
			}.bind(this));
			engine_event.addListener('onSlideId',function(index){
				this.time = this.slide_delay;
				this.slide_id = index;
				
			}.bind(this));
			engine_event.addListener('onExport',async function(index, name, promiseResolve){

				this.rec_id = index;
				this.rec_time=0;
				this.mixer.stopAllAction();
				let idle = this.mixer.clipAction( this.recording_list[index].clip );
				idle.play();

				
				let tracks = [];
				let mr = this.retargeter;
				let export_skeleton_position = [];
				let export_skeleton_times = []
				let export_skeleton_rotations = [];
				let export_skeleton_morphs =[];
				let _rawHumanBones = [];
				const morph_map = new Map();
				if(this.current_model.matrix==undefined)
				{
					_rawHumanBones = Retargeter.VRMSkeleton.getRawBones(this.current_model);

					this.current_model.springBoneManager._objectSpringBonesMap.forEach((value, key) => {
						_rawHumanBones.push(key);
						value;
					});

					for ( let i = 0; i < _rawHumanBones.length; i ++ ) {
						export_skeleton_rotations.push([]);
					}
					
					this.current_model.expressionManager._expressions.forEach((value, key) => {

						if(key!=undefined)
						{
							value._binds.forEach((bind) => { 
								bind.primitives.forEach((primitive) => morph_map.set(primitive.name, primitive));
							});
						}
					});


				}
				else
				{
					for ( let i = 0; i < this.retargeter.target_bones.length; i ++ ) {
						export_skeleton_rotations.push([]);
					}
					for ( let i = 0; i < this.morphs.length; i ++ ) 
					{
						for(let b = 0; b < this.morphs[i].length; b++)
						{
							if(this.morphs[i][b].morph!=undefined)
								morph_map.set(this.morphs[i][b].morph.name, this.morphs[i][b].morph)
						}
					}
				}
				
				
				

				morph_map.forEach(() => { 
					export_skeleton_morphs.push([]);
				} )

				for(let t=0; t<this.recording_list[index].clip.duration*1000; t+= 1000.0/60)
				{
					let delta = t/1000.0;
					this.mixer.update(1.0/60);
					let self = this;
					this.animation.traverse( function ( child ) 
					{
						mr.source_bones[child.uid].position.set(child.position.x,child.position.y,child.position.z);
						mr.source_bones[child.uid].quaternion.set(child.quaternion.x,child.quaternion.y,child.quaternion.z,child.quaternion.w);
						if(child.uid==0)
						{
							for(let b = 0; b < child.morphTargetInfluences.length; b++)
							{
								for(let j=0; j<self.morphs[b].length; j++)
								{
									if(self.morphs[b][j].morph!=undefined)
									self.morphs[b][j].morph.morphTargetInfluences[self.morphs[b][j].id] = child.morphTargetInfluences[b];
								}
								self.updateVrmMorph(b,child.morphTargetInfluences[b]);
							}
						}
					});
					mr.update();

					if(this.current_model.matrix==undefined)
					{
						this.current_model.update( (1.0/60)*3 ); 
						let pos=_rawHumanBones[0].position;
						export_skeleton_position.push(pos.x,pos.y,pos.z);
						export_skeleton_times.push(delta);
						for ( let i = 0; i < _rawHumanBones.length; i++ ) 
						{
							if(_rawHumanBones[i]!=undefined)
								export_skeleton_rotations[i].push(_rawHumanBones[i].quaternion.x,
									_rawHumanBones[i].quaternion.y,
									_rawHumanBones[i].quaternion.z,
									_rawHumanBones[i].quaternion.w);
						}

					}
					else
					{

						//let pos= new THREE.Vector3();
						let pos=mr.target_bones[0].position;//.getWorldPosition(pos);
						export_skeleton_position.push(pos.x,pos.y,pos.z);
						export_skeleton_times.push(delta);
						for ( let i = 0; i < mr.target_bones.length; i++ ) 
						{
							if(mr.target_bones[i]!=undefined)
								export_skeleton_rotations[i].push(mr.target_bones[i].quaternion.x,mr.target_bones[i].quaternion.y,mr.target_bones[i].quaternion.z,mr.target_bones[i].quaternion.w);
						}
					}
					let m=0;
					morph_map.forEach((value) => { 
						export_skeleton_morphs[m] = export_skeleton_morphs[m].concat(value.morphTargetInfluences.map((x) => x));
						m++;
					} )
				}
				if(this.current_model.matrix==undefined)
				{
					const positionKF = new THREE.VectorKeyframeTrack( _rawHumanBones[0].name+'.position', export_skeleton_times.map((x) => x), export_skeleton_position.map((x) => x));
					tracks.push(positionKF);
					for ( let i = 0; i < _rawHumanBones.length; i ++ ) 
					{
						if(_rawHumanBones[i]!=undefined)
						{
							const quaternionKF = new THREE.QuaternionKeyframeTrack( _rawHumanBones[i].name+'.quaternion', export_skeleton_times.map((x) => x), export_skeleton_rotations[i].map((x) => x));
							tracks.push(quaternionKF);
						}
					}
				}
				else
				{
					const positionKF = new THREE.VectorKeyframeTrack( mr.target_bones[0].name+'.position', export_skeleton_times.map((x) => x), export_skeleton_position.map((x) => x));
					tracks.push(positionKF);
					for ( let i = 0; i < this.retargeter.target_bones.length; i ++ ) 
					{
						if(mr.target_bones[i]!=undefined)
						{
							const quaternionKF = new THREE.QuaternionKeyframeTrack( mr.target_bones[i].name+'.quaternion', export_skeleton_times.map((x) => x), export_skeleton_rotations[i].map((x) => x));
							tracks.push(quaternionKF);
						}
					}
				}

				let m=0;
				morph_map.forEach((key) => { 
					const morphsKF = new THREE.NumberKeyframeTrack(key.name+'.morphTargetInfluences',export_skeleton_times.map((x) => x),export_skeleton_morphs[m].map((x)=>x));
					tracks.push(morphsKF);
					m++;
				} )
				const clip = new THREE.AnimationClip( "RedPillGoAnimClip", this.recording_list[index].clip.duration, tracks );
				this.current_model.animations = [clip];

				const wav = new Blob( [ this.recording_list[index].audio  ], { type: 'audio/wav' } );

				this.exportCharacter(wav,string_hash(name),promiseResolve);

				if(this.current_model.matrix==undefined)
					await this.loadVRM(this.current_model_url,this.current_model_url);

				//let mediaRecorder = new MediaStreamRecorder(new MediaStream());
				//mediaRecorder.mimeType = 'audio/wav';
				//mediaRecorder.save(this.recording_list[index].audio,"audio.wav");
				
				this.rec_id = -1;
				this.rec_time=0;
				this.mixer.stopAllAction();
				idle = this.mixer.clipAction( this.default_animation);
				idle.play();
				let etag = this.current_model.etag;
				engine_event.emit('recording_downloaded',etag,this.recording_list[index].clip);
				
				
			}.bind(this));
			engine_event.addListener('delete_rec',function(index){
				
				this.recording_list.splice(index, 1);
			}.bind(this));
			engine_event.addListener('onRecord', function(rec) {
				console.log("onRecord")
				if(rec==true)
				{

					this.rec_time = 0;
					this.audioDataArray = [];
					this.recording_skeleton_position = [];
					this.recording_skeleton_rotations = [];
					for ( let i = 0; i < this.retargeter.source_bones.length; i ++ ) {
						this.recording_skeleton_rotations.push([]);
					}
					this.recording_skeleton_morphs = [];
					this.recording_skeleton_times = [];
					if(this.mediaRecorder!=undefined)
					{
						this.mediaRecorder.start(60*60*1000);
						
						
					}

				}
				else
				{
					let names = Retargeter.SourceSkeleton.getNames();
					let tracks = [];
					const positionKF = new THREE.VectorKeyframeTrack( names[0]+'.position', this.recording_skeleton_times.map((x) => x), this.recording_skeleton_position.map((x) => x));
					tracks.push(positionKF);
					for ( let i = 0; i < this.retargeter.source_bones.length; i ++ ) 
					{
						const quaternionKF = new THREE.QuaternionKeyframeTrack( names[i]+'.quaternion', this.recording_skeleton_times.map((x) => x), this.recording_skeleton_rotations[i].map((x) => x));
						tracks.push(quaternionKF);
					}
					
					const morphsKF = new THREE.NumberKeyframeTrack (names[0]+'.morphTargetInfluences', this.recording_skeleton_times.map((x) => x),this.recording_skeleton_morphs.map((x) => x));
					tracks.push(morphsKF);
					const clip = new THREE.AnimationClip( "Rec_"+getDateString(), -1, tracks );
					
					engine_event.emit('recording',clip);
					let audioData = undefined;
					this.recording_list.push({clip:clip,audio:audioData});
					if(this.mediaRecorder!=undefined)
					{
						let self = this;
						this.mediaRecorder.ondataavailable = function (blob) {
							// POST/PUT "Blob" using FormData/XHR2
							//var blobURL = URL.createObjectURL(blob);
							
							self.audioDataArray.push(blob);
							self.recording_list[self.recording_list.length-1].audio = new Blob(self.audioDataArray,{type: "audio/webm"});
							//document.write('<a href="' + blobURL + '">' + blobURL + '</a>');
						};
						this.mediaRecorder.stop();
						
					}
					
				}
				this.recording = rec;
			}.bind(this));
			engine_event.addListener('onRecId',function(index){
				
				this.rec_id = index;
				this.rec_time=0;
				this.mixer.stopAllAction();
				let idle;
				let audio_replay = document.querySelector('audio#replay');
				if(this.rec_id>-1)
				{
					idle = this.mixer.clipAction( this.recording_list[index].clip );
					if(this.recording_list[index].audio!=undefined)
					{
						const audioURL = URL.createObjectURL(this.recording_list[index].audio);
						
						audio_replay.src = audioURL;
						audio_replay.loop = true;
						audio_replay.oncanplay=()=>
						{
							audio_replay.play();
						};
					}
					
				}
				else 
				{
					idle = this.mixer.clipAction( this.default_animation );
					audio_replay.pause();
				}
				idle.play();

				
				
				
			}.bind(this));
			// this.web_parser = new Parser('');
			// this.web_parser.engine = this;
			// this.client = this.web_parser.client;

			//
			this.lastTime  = new Date();
			this.client = new Client();//webrtc
			this.client.addEventListener("message", (e)=>{
				let [type, data] = Parser.parse(e.data);
				this.em.emit(type, data);

				var endTime = new Date();
				var timeDiff = endTime - this.lastTime; //in ms
				// strip the ms
				timeDiff /= 1000;
				// get seconds 
				var seconds = Math.round(timeDiff);
				//console.log(seconds + " seconds");
				if(seconds>=60)
				{
					this.lastTime = endTime;
					this.track_user();
				}

			});
			this.client.addEventListener("track", (e) => {
            
				//console.log("track event "+ e);
				if (e.data.track.kind === "audio")
				{
					//console.log("track event is audio "+ e.data.streams[0]);
					this.audio.srcObject = e.data.streams[0];
					this.audio.loop = false;
					this.mediaRecorder = new MediaStreamRecorder(e.data.streams[0]);
					this.mediaRecorder.mimeType = 'audio/wav'; // check this line for audio/wav
					
					
					this.audio.oncanplay=()=>{this.audio.play();};
				}
			});
			this.client.addEventListener("room", function (e) {
				console.log('Connected to room: ' + e.data);
				//parent.$emit('room',data);
				engine_event.emit('room',e.data);	   
			});
			this.em.addListener("ikrig", (data)=>{
				this.retargeter.setIKRig(data);
			});
			this.em.addListener("rotations", (data)=>{
				this.retargeter.setRotations(data);
			});
			this.em.addListener("translations", (data)=>{
				this.retargeter.setTranslation(...data);
			});
			this.em.addListener("morph", (function(data){
				for(var b = 0; b < this.morphs.length; b++)
				{
					const x = data[b];
					this.recording_morphs[b] = x;
					for(let j=0; j<this.morphs[b].length; j++)
					{
						if(this.morphs[b][j].morph!=undefined)
					
						this.morphs[b][j].morph.morphTargetInfluences[this.morphs[b][j].id] = x;
					}
					this.updateVrmMorph(b,x);
				}
			}).bind(this));
			// this.em.addListener("calib", ()=>{});

			this.em.addListener('mic', function (mic) {
				//console.log('mic: '+mic);
				//parent.$emit('mic',mic);
				engine_event.emit('mic', mic);
			});

		
			engine_event.addListener('addObject', function(obj){
				this.scene.add(obj);
			}.bind(this));
			engine_event.addListener('removeObject', function(obj){
				this.scene.remove(obj);
			}.bind(this));
			engine_event.addListener('addScript', function(obj, script){
				this.addScript(obj, script);
			}.bind(this));
			engine_event.addListener('removeScript', function(obj, script){
				this.removeScript(obj, script);
			}.bind(this));
			engine_event.addListener('addCamera', function(cam){
				this.cameras[cam.uuid]=cam;
			}.bind(this));
			engine_event.addListener('removeCamera', function(cam){
				delete this.cameras[cam.uuid];
				if(Object.keys(this.cameras).length==0)
				{
					this.viewportCamera = this.defaultCamera;
					this.onWindowResize();
				}
			}.bind(this));
			engine_event.addListener('resetCamera', function(){
				this.viewportCamera = this.defaultCamera;
				this.onWindowResize();
		
			}.bind(this));
			engine_event.addListener('setViewportCamera', function(uuid){

				this.viewportCamera = this.cameras[uuid];
				this.onWindowResize();
				//var target = this.controls.target;
				//this.controls = new OrbitControls( this.viewportCamera, this.renderer.domElement );
				//this.controls.target.set( target.x,target.y,target.z );
				//this.controls.update();

		
			}.bind(this));
			engine_event.addListener('getViewportCamera', function(callback){
				callback(this.viewportCamera);
			}.bind(this));
			engine_event.addListener('getLookAtTarget', function(callback){
				callback(this.retargeter.target_bones[11]);
			}.bind(this));
			engine_event.addListener("hasScreen", function(callback){
				callback(this.screen!=undefined);
			}.bind(this));
			engine_event.addListener("get_etag", function(callback){
				//callback(this.current_model.matrix?this.current_model.etag:'vrm');
				callback([this.current_model.etag, this.current_model.matrix==undefined])
			}.bind(this));

			return this;
		})();
	}
	async track_user(){
		let char_name = this.current_character_name;
		if(char_name==undefined)
		char_name = "Unknown"
		const myInit = {
			headers: { 
			"Content-Type":"application/json"
			},
			body: {
				"last_character": this.current_character_name,
				"last_environment": this.current_environment_name
			}
		}
		try{
			await API.put('rpgUserProfile','/profile', myInit);//don't await
			//console.log(res);
		}catch(error)
		{
			console.log(error);
		}
	}

	dispose()
	{
		this.renderer.dispose();
	}
	initScene()
	{
		this.scene = new THREE.Scene()
		this.scene.background = new THREE.Color( 0xa0a0a0 );
		
		this.hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444 );
		this.hemiLight.position.set( 0, 20, 0 );
		

		const targetObject = new THREE.Object3D();
		targetObject.position.set(0, 0, -3 );
		this.scene.add(targetObject);

		this.skeleton_geometry = new THREE.BufferGeometry();
		this.ikRig_chains = new THREE.BufferGeometry();
		this.ikRig_solved = new THREE.BufferGeometry();
		this.ikRig_poles = new THREE.BufferGeometry();
		this.recording_skeleton_position = [];
		this.recording_skeleton_rotations = [];
		this.recording_morphs = [];
		for ( let i = 0; i < 17; i ++ ) {
			this.recording_morphs.push(0.0);
		}

		this.recording_skeleton_morphs = [];
		this.recording_skeleton_times = [];
		const vertices = [];
		const vertices2 = [];
		const vertices3 = [];
		const vertices4 = [];
		const colors = [];
		const colors2 = [];
		const colors3 = [];
		const colors4 = [];
		const color1 = new THREE.Color( 1, 0.765, 0.309 );
		const color2 = new THREE.Color(  1, 0.765, 0.309 );
		const color3 = new THREE.Color(  0.309, 0.765, 1 );
		const color4 = new THREE.Color(  1, 0.0, 0.309 );
		for ( let i = 0; i < this.retargeter.source_bones.length; i ++ ) {
				vertices.push( 0, 0, 0 );
				vertices.push( 0, 0, 0 );
				colors.push( color1.r, color1.g, color1.b );
				colors.push( color2.r, color2.g, color2.b );
				this.recording_skeleton_rotations.push([]);
		}
		for(let i = 0; i < 16; i ++)
		{
			vertices2.push( 0, 0, 0 );
			vertices2.push( 0, 0, 0 );
			colors2.push( color1.r, color1.g, color1.b );
			colors2.push( color2.r, color2.g, color2.b );
		}
		for(let i = 0; i < 12*2+10*3; i ++)
		{
			vertices3.push( 0, 0, 0 );
			vertices3.push( 0, 0, 0 );
			colors3.push( color3.r, color3.g, color3.b );
			colors3.push( color3.r, color3.g, color3.b );
		}
		for(let i = 0; i < 16; i ++)
		{
			vertices4.push( 0, 0, 0 );
			vertices4.push( 0, 0, 0 );
			colors4.push( color4.r, color4.g, color4.b );
			colors4.push( color4.r, color4.g, color4.b );
		}

		this.skeleton_geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
		this.skeleton_geometry.setAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );
		this.ikRig_chains.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices2, 3 ) );
		this.ikRig_chains.setAttribute( 'color', new THREE.Float32BufferAttribute( colors2, 3 ) );
		this.ikRig_solved.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices3, 3 ) );
		this.ikRig_solved.setAttribute( 'color', new THREE.Float32BufferAttribute( colors3, 3 ) );
		this.ikRig_poles.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices4, 3 ) );
		this.ikRig_poles.setAttribute( 'color', new THREE.Float32BufferAttribute( colors4, 3 ) );

		const skel_material = new THREE.LineBasicMaterial( { vertexColors: true, depthTest: false, depthWrite: false, toneMapped: false, transparent: true , linewidth :3 } );
		this.skeleton = new THREE.LineSegments( this.skeleton_geometry, skel_material );
		this.scene.add( this.skeleton );
		this.ikRigSkeleton = new THREE.LineSegments( this.ikRig_chains, skel_material );
		this.scene.add( this.ikRigSkeleton );
		this.ikRigSkeletonSolved = new THREE.LineSegments( this.ikRig_solved, skel_material );
		this.scene.add( this.ikRigSkeletonSolved );
		this.ikRigPoles = new THREE.LineSegments( this.ikRig_poles, skel_material );
		this.scene.add( this.ikRigPoles );

		//this.skeleton = new THREE.SkeletonHelper( this.retargeter.source_bones[0] );
		//this.scene.add( this.skeleton );
		this.skeleton.visible = false;
		this.ikRigSkeleton.visible = false;
		this.ikRigSkeletonSolved.visible = false;
		this.ikRigPoles.visible = false;
		this.boneContainer = new THREE.Group();
		this.boneContainer.add( this.retargeter.source_bones[0] );
		this.scene.add( this.boneContainer );

		
		this.dirLight = new THREE.DirectionalLight( 0xffffff );
		//this.dirLight = new THREE.PointLight( 0xffffff, 2, 100 );
		this.dirLight.position.set( 0, 5, 3 );
		this.dirLight.target = targetObject;
		this.dirLight.castShadow = true;
		// this.dirLight.shadow.camera.top = 1;
		// this.dirLight.shadow.camera.bottom = -1;
		// this.dirLight.shadow.camera.left = -1;
		// this.dirLight.shadow.camera.right = 1;
		// this.dirLight.shadow.camera.near = 0.01;
		// this.dirLight.shadow.camera.far = 200;
		//this.dirLight.shadow.bias =  0.00000001;

		//Set up shadow properties for the light
		this.dirLight.shadow.mapSize.width = 512; // default
		this.dirLight.shadow.mapSize.height = 512; // default


		this.scene.add( this.dirLight );

		// this.spotLight = new THREE.SpotLight( 0xffffff );
		// this.spotLight.position.set( 0, 1, 1 );
		// const targetObject = new THREE.Object3D();
		// targetObject.position.set(0,0,0);
		// this.scene.add(targetObject);
		// this.spotLight.target = targetObject;
		// this.spotLight.castShadow = true;

		// this.spotLight.shadow.mapSize.width = 1024;
		// this.spotLight.shadow.mapSize.height = 1024;

		// this.spotLight.shadow.camera.near = 500;
		// this.spotLight.shadow.camera.far = 4000;
		// this.spotLight.shadow.camera.fov = 30;

		//this.spotLight.shadow.camera.top = 5;
		//this.spotLight.shadow.camera.bottom = - 5;
		//this.spotLight.shadow.camera.left = - 5;
		//this.spotLight.shadow.camera.right = 5;
		// this.spotLight.shadow.camera.near = 0.0001;
		// this.spotLight.shadow.camera.far = 400;
		//this.spotLight.shadow.bias = 0.00000000001;

		// this.scene.add( this.spotLight );

		this.dirLight.shadow.camera.top = 7;
		this.dirLight.shadow.camera.bottom = -7;
		this.dirLight.shadow.camera.left = -5;
		this.dirLight.shadow.camera.right = 5;
		this.dirLight.shadow.camera.near = 0.1;
		this.dirLight.shadow.camera.far = 20;
		this.dirLight.shadow.bias = -0.005;
		// ground
		this.ground = new THREE.Mesh( new THREE.PlaneGeometry( 100, 100 ), new THREE.ShadowMaterial( { opacity: 0.3 } ) );
		this.ground.rotation.x = - Math.PI / 2;
		this.ground.receiveShadow = true;
		this.scene.add( this.ground );

		const grid = new THREE.GridHelper( 100, 20, 0x00ff00, 0x00ff00 );
		grid.material.opacity = 1;//0.2;
		grid.material.transparent = true;

		const geometry = new THREE.BoxGeometry( 1, 1, 1 );
		const material = new THREE.MeshBasicMaterial( {color: 0x00ff00} );
		material.depthTest = false;
		const material2 = new THREE.MeshBasicMaterial( {color: 0xff0000} );
		material2.depthTest = false;
		const left_cube = new THREE.Mesh( geometry, material );
		const right_cube = new THREE.Mesh( geometry, material );
		const left_knee = new THREE.Mesh( geometry, material );
		const right_knee = new THREE.Mesh( geometry, material );
		const helper_root = new THREE.Mesh( geometry, material );
		const helper_root2 = new THREE.Mesh( geometry, material2 );
		
		right_cube.position.set(-10,0,0);
		left_cube.position.set(10,0,0);
		right_knee.position.set(-10,0,0);
		left_knee.position.set(10,0,0);
		helper_root.position.set(0,0,0);
		helper_root2.position.set(0,0,0);
		left_cube.renderOrder =100;
		right_cube.renderOrder =100;
		left_knee.renderOrder =100;
		right_knee.renderOrder =100;
		helper_root.renderOrder = 100;
		helper_root2.renderOrder = 100;
		// this.scene.add( left_cube );
		// this.scene.add( right_cube );
		// this.scene.add( left_knee );
		// this.scene.add( right_knee );
		//this.scene.add( helper_root );
		// this.scene.add( helper_root2 );
		this.retargeter.lHelperFoot = left_cube;
		this.retargeter.rHelperFoot = right_cube;
		this.retargeter.lHelperKnee = left_knee;
		this.retargeter.rHelperKnee = right_knee;
		this.retargeter.rHelperRoot = helper_root;
		this.retargeter.rHelperRoot2 = helper_root2;
		//this.scene.add( grid );
	}
	initCamera()
	{
		//this.camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 2000 );
		//this.camera.position.set( 0, 100, 300 );
		const camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 20 );
		camera.near = 10;
		camera.far = 200;
		camera.setFocalLength(30);
		camera.position.set( 0, 1, 4 );
		camera.lookAt( 0, 0, 1 );
		return camera;
	}
	onMouseMove(event)
	{
		if (!this.engine.mouseDown) {
            return;
        }
		if(this.engine.current_model==null) return;
		event.preventDefault();
		let model = this.engine.current_model;
		if(!model.matrix)
			model =  this.engine.current_model.scene;
		
		var deltaX = event.clientX - this.engine.mouseX;
		//var deltaY = event.clientY - this.engine.mouseY;
		this.engine.mouseX = event.clientX;
		this.engine.mouseY = event.clientY;
		if(this.engine.gizmo_mode=='rotate')
		{
			model.rotation.y+=deltaX / 100;
			this.engine.gizmos[0].rotation.z = model.rotation.y;
		}
		else if(this.engine.gizmo_mode=='translate')
		{	
			const mouse = {
				x: (event.clientX / this.clientWidth) * 2 - 1,
				y: -(event.clientY / this.clientHeight) * 2 + 1,
			}
			// = new THREE.Raycaster()
			this.engine.raycaster.setFromCamera(mouse, this.engine.viewportCamera)
			
			const intersect = this.engine.raycaster.intersectObject(this.engine.intersection_ground, false)
			
			if(intersect.length > 0)
			{
				
				model.position.copy(intersect[0].point);
				for(let i=0; i<this.engine.gizmos.length; i++)
					this.engine.gizmos[i].position.copy(intersect[0].point);
			}
			
		}
		model.updateMatrix();

		
	}
	onMouseDown(evt) {
        evt.preventDefault();

       
        this.engine.mouseX = evt.clientX;
        this.engine.mouseY = evt.clientY;
		

		const mouse = {
			x: (evt.clientX / this.clientWidth) * 2 - 1,
			y: -(evt.clientY / this.clientHeight) * 2 + 1,
		}
		this.engine.raycaster.setFromCamera(mouse, this.engine.viewportCamera)
		const intersects = this.engine.raycaster.intersectObjects(this.engine.gizmos, false)
		if (intersects.length > 0) 
		{
			let found = false;
			for(let i=0; i<intersects.length; i++)
			{
				if(intersects[i].object.parent==this.engine.scene)
				{
					found = true;
					break;
				}
			}

			if(found)
			{
				this.engine.mouseDown = true;
				this.engine.controls.enabled = false;
			}


		}

    }

    onMouseUp(evt) {
        evt.preventDefault();

        this.engine.mouseDown = false;
		this.engine.controls.enabled = true;
    }

	initControls()
	{
		this.controls = new OrbitControls( this.viewportCamera, this.renderer.domElement );
		this.controls.target.set( 0, 1, 0 );
		//this.controls.update();
		this.renderer.domElement.engine = this;
		this.renderer.domElement.addEventListener('mousemove', this.onMouseMove, false)
		this.renderer.domElement.addEventListener('mousedown', this.onMouseDown, false);
		this.renderer.domElement.addEventListener('mouseup', this.onMouseUp, false);

		const geometry = new THREE.CircleGeometry( 0.4, 32 );
		//{ vertexColors: true, depthTest: false, depthWrite: false, toneMapped: false, transparent: true , linewidth :3 }
		const material = new THREE.MeshBasicMaterial( { color: 0xffff00, side: THREE.DoubleSide,depthTest: false, depthWrite: false, toneMapped: false, transparent: true, opacity:0.3  } );
		this.rotation_gizmo = new THREE.Mesh( geometry, material );
		this.rotation_gizmo.position.set(0,0,0);
		this.rotation_gizmo.rotation.set(-1.5708,0,0);

		const plane_geometry = new THREE.PlaneGeometry( 0.5, 0.5 );
		const plane_material = new THREE.MeshBasicMaterial( {color: 0x0055ff, side:THREE.DoubleSide,depthTest: false, depthWrite: false, toneMapped: false, transparent: true, opacity:0.3} );
		this.translation_gizmo  = new THREE.Mesh( plane_geometry, plane_material );
		this.translation_gizmo.position.set(0,0,0);
		this.translation_gizmo.rotation.set(-1.5708,0,0);

		// this.scene.add( this.rotation_gizmo );
		// this.scene.add( this.translation_gizmo );
		this.gizmos.push(this.rotation_gizmo);
		this.gizmos.push(this.translation_gizmo);

		this.intersection_ground = new THREE.Mesh( new THREE.PlaneGeometry( 100, 100 ), new THREE.ShadowMaterial( { opacity: 0 } ) );
		this.intersection_ground.rotation.x = - Math.PI / 2;
		this.scene.add( this.intersection_ground );

		//this.rotation_gizmo.visible = false;
		//this.translation_gizmo.visible = false;

		//this.transformControls = new TransformControls( this.camera, this.renderer.domElement );
		//this.transformControls.engine = this;
		//this.transformControls.setMode('');
		//let objectPositionOnDown = null;
		//let objectRotationOnDown = null;
		//let objectScaleOnDown = null;
		//const box = new THREE.Box3();

		//const selectionBox = new THREE.Box3Helper( box );
		//selectionBox.material.depthTest = false;
		//selectionBox.material.transparent = true;
		//selectionBox.visible = false;
		//this.scene.add( selectionBox );
		//this.scene.add(new THREE.AxesHelper(5))

		// this.transformControls.addEventListener( 'mouseDown', function () {

		// 	const object = this.object;

		// 	objectPositionOnDown = object.position.clone();
		// 	objectRotationOnDown = object.rotation.clone();
		// 	objectScaleOnDown = object.scale.clone();

		// 	this.engine.controls.enabled = false;

		// } );
		// this.transformControls.addEventListener( 'mouseUp', function () {

		// 	const object = this.object;

		// 	if ( object !== undefined ) {

		// 		switch ( this.getMode() ) {

		// 			case 'translate':

		// 				if ( ! objectPositionOnDown.equals( object.position ) ) {

		// 					//editor.execute( new SetPositionCommand( editor, object, object.position, objectPositionOnDown ) );

		// 				}

		// 				break;

		// 			case 'rotate':

		// 				if ( ! objectRotationOnDown.equals( object.rotation ) ) {

		// 					//editor.execute( new SetRotationCommand( editor, object, object.rotation, objectRotationOnDown ) );

		// 				}

		// 				break;

		// 			case 'scale':
						
		// 				if ( ! objectScaleOnDown.equals( object.scale ) ) {
							
		// 					//editor.execute( new SetScaleCommand( editor, object, object.scale, objectScaleOnDown ) );

		// 				}

		// 				break;

		// 		}

		// 	}

		// 	this.engine.controls.enabled = true;

		// } );

		// this.scene.add( this.transformControls );
	
	}
	initRenderer()
	{
		this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true, preserveDrawingBuffer: true})
		this.renderer.domElement.style.cssText = 'position:absolute;top:0px;left:0px;bottom:0px;right:0px;';
		this.renderer.setPixelRatio(window.devicePixelRatio)
		this.renderer.shadowMap.enabled = true;
		//this.renderer.shadowMap.type = THREE.VSMShadowMap;
		this.renderer.shadowMap.type = THREE.VSMShadowMap;
		this.renderer.outputEncoding = THREE.sRGBEncoding;
		
		
		// linear color space
		this.API = 
		{
			lightProbeIntensity: 1,
			directionalLightIntensity: 0.2,
			envMapIntensity: 1
		};

	}
	

	initStats()
	{
		if(!this.container)
			this.container = document.getElementById('engine');

		this.container =document.getElementById('engine');
		
		this.sizes = {
				width : this.container.clientWidth,
				height : this.container.clientHeight
			};
		this.renderer.setSize(this.sizes.width, this.sizes.height)
		// if(!this.stats)
		// {
		// this.stats = new Stats();
		// this.stats.domElement.style.cssText = 'position:absolute;bottom:0px;left:0px;';
		// }
		//this.container = document.getElementById('engine');
		if(this.container)
		{
			this.container.appendChild(this.renderer.domElement);
			//this.container.appendChild( this.stats.dom );
			const resizeObserver = new ResizeObserver((entries) => {
				for (const entry of entries) {

					//const contentBoxSize = entry.contentBoxSize[0];
					console.log(entry.contentRect.width,entry.contentRect.height);
					this.viewportCamera.aspect = entry.contentRect.width / entry.contentRect.height
					this.viewportCamera.updateProjectionMatrix()
					// // Update renderer
					this.renderer.setSize(entry.contentRect.width, entry.contentRect.height)
				}
			});
			console.log("Oserve "+document.getElementById("engine"));
			resizeObserver.observe(document.getElementById("engine"));
			
		}
		this.audio = document.querySelector('audio#audio2');
		
		//this.gui.domElement.style.cssText = 'position:absolute;top:0px;left:50%;margin-left: -100px;';
	}

	onWindowResize() 
	{
		if(this.sizes==undefined) 
		{
			console.log("Fail to resize");
			return;
		}
		//console.log("onWindowResize "+window.innerWidth+" "+window.innerHeight);
		//console.log("screen "+screen.width+" "+screen.height);
		if(utils.isFullscreen())// Update sizes
		{
			//console.log("is fullscreen");
			this.sizes.width = screen.width;
			
			this.sizes.height = screen.height;
		}
		else
		{
			this.sizes.width = window.innerWidth
			this.sizes.height = window.innerHeight
		}
		console.log("Resize",this.sizes.width, this.sizes.height, window.innerWidth,window.innerHeight);
		// Update camera
		this.viewportCamera.aspect = window.innerWidth/window.innerHeight
		this.viewportCamera.updateProjectionMatrix()
		// // Update renderer
		this.renderer.setSize(window.innerWidth,window.innerHeight)
		//this.update();
		// //this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
		// this.renderer.setPixelRatio(1)
		//this.renderer.domElement.style.cssText = 'position:absolute;bottom:0px;left:0px;';
		//this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1))
		
	}

	onFullScreenChange() 
	{
		//window.removeEventListener( "resize", this.res_func, false );

		// console.log("onFullScreenChange "+window.innerWidth+" "+window.innerHeight);
		// // Update sizes
		// this.sizes.width = window.innerWidth
		// this.sizes.height = window.innerHeight
	
		// // Update camera
		// this.camera.aspect = this.sizes.width / this.sizes.height
		// this.camera.updateProjectionMatrix()
	
		// // Update renderer
		// this.renderer.setSize(this.sizes.width, this.sizes.height)
		// this.renderer.setPixelRatio(1)

		//window.addEventListener( "resize", this.res_func, false );
		
	}

	addScript(object, script){
		if(this.scripts[object.uuid]===undefined)
			this.scripts[object.uuid] = [];
		this.scripts[object.uuid].push(script);
	}
	removeScript(object, script){
		if ( this.scripts[ object.uuid ] === undefined ) return;
		var index = this.scripts[ object.uuid ].indexOf( script );
		if ( index !== - 1 ) {
			this.scripts[ object.uuid ].splice( index, 1 );
		}
	}
	update()
	{
		
		if(!this) return;

		requestAnimationFrame(this.update.bind(this));

		const delta = this.clock.getDelta();

		for(const key in this.scripts)
			for(const script of this.scripts[key])
				script(delta);

		this.fps_time+=delta;
		this.fps_frames++;
		if(this.fps_time>1)
		{
			//console.log("fps "+Math.round(1.0/(this.fps_time/this.fps_frames)));
			engine_event.emit('fps',Math.round(1.0/(this.fps_time/this.fps_frames)));
			this.fps_time = 0;
			this.fps_frames=0;
		}
		if(this.screen!=undefined)
		{
			this.time+=delta*this.slide_speed*Math.max(1,this.slide_speed);
			if(this.time> this.slide_delay )
			{
				this.time = 0;
				
				
				this.screen.material.map = this.slides[this.slide_id];
				this.screen.material.needsUpdate = true;
				this.slide_id++;
				if(this.slide_id>= this.slides.length)
					this.slide_id = 0;

				
			}
		}
		
		if ( this.mixer ) 
			this.mixer.update(delta);

		if ( this.anim_mixer ) 
			this.anim_mixer.update(delta);

		if ( this.camera_mixer ) 
			this.camera_mixer.update(delta);
		if(this.controls)
		{
			if(this.viewportCamera === this.defaultCamera)
			{
				if(this.gizmo_mode=="")
				{
					this.controls.enabled = true;
					this.controls.update();
				}
			}
			else{
				this.controls.enabled = false;
			}
			
		}

		this.retargeter.update();
		let chains = this.retargeter.chains;
		let ikRig_solved = this.retargeter.ikRig_solved;
		let ikRig_poles = this.retargeter.ikRig_poles;

		if ( this.current_model ) 
		{
			if(typeof this.current_model.update === 'function')
			{
				this.current_model.update( delta*3 ); 
			}
		}
		
		
		// // Render
		this.renderer.render(this.scene, this.viewportCamera)	
	
		if(this.retargeter)
		{
			let mr = this.retargeter;

			if(this.recording==true)
			{

				let pos= new THREE.Vector3();
				mr.source_bones[0].getWorldPosition(pos);
				this.recording_skeleton_position.push(pos.x,pos.y,pos.z);
				this.recording_skeleton_times.push(this.rec_time);
				this.recording_skeleton_morphs = this.recording_skeleton_morphs.concat(this.recording_morphs.map((x) => x));
				for ( let i = 0; i < mr.target_bones.length; i++ ) 
				{
					this.recording_skeleton_rotations[i].push(mr.source_bones[i].quaternion.x,mr.source_bones[i].quaternion.y,mr.source_bones[i].quaternion.z,mr.source_bones[i].quaternion.w);
				}

				this.rec_time+=delta;
			}

			
			if(this.animation)
			{
				if(this.client)
					if(!this.client.pc)
						{
							this.retargeter.ikRig.height = 0;
							let self = this;
							this.animation.traverse( function ( child ) 
							{
								//console.log("anim "+child.name);
								mr.source_bones[child.uid].position.set(child.position.x,child.position.y,child.position.z);
								mr.source_bones[child.uid].quaternion.set(child.quaternion.x,child.quaternion.y,child.quaternion.z,child.quaternion.w);
								if(child.uid==0)
								{
									for(var b = 0; b < child.morphTargetInfluences.length; b++)
									{
										for(let j=0; j<self.morphs[b].length; j++)
										{
											if(self.morphs[b][j].morph!=undefined)
											self.morphs[b][j].morph.morphTargetInfluences[self.morphs[b][j].id] = child.morphTargetInfluences[b];
										}
										self.updateVrmMorph(b,child.morphTargetInfluences[b]);
									}
								}
								
							});

							
							
						}

			}
			if(this.skeleton.visible || this.ikRigSkeleton.visible)
			{
				if(this.retargeter.ikRig.height>0)
				{
					this.skeleton.visible = false;
					this.ikRigSkeleton.visible = true;
					this.ikRigPoles.visible = true;
					this.ikRigSkeletonSolved.visible = true;
				}
				else
				{
					this.skeleton.visible = true;
					this.ikRigSkeleton.visible = false;
					this.ikRigPoles.visible = false;
					this.ikRigSkeletonSolved.visible = false;
				}
				if(this.skeleton_geometry)
				{
					const position = this.skeleton_geometry.getAttribute( 'position' );
					for ( let i = 1, j = 0; i < mr.source_bones.length; i ++ ) {
						const bone = mr.source_bones[ i ];
						let pos= new THREE.Vector3();
						bone.getWorldPosition(pos);
						// pos = mr.InverseTransformPoint(this.boneContainer.matrixWorld,pos);
						pos.applyMatrix4(this.boneContainer.matrixWorld.clone().invert());
						position.setXYZ( j, pos.x, pos.y, pos.z );
						bone.parent.getWorldPosition(pos);
						// pos = mr.InverseTransformPoint(this.boneContainer.matrixWorld,pos);
						pos.applyMatrix4(this.boneContainer.matrixWorld.clone().invert());
						position.setXYZ( j + 1, pos.x, pos.y, pos.z );
						j += 2;
					}
					this.skeleton_geometry.getAttribute( 'position' ).needsUpdate = true;
				}
				if(this.ikRig_chains)
				{
					const position = this.ikRig_chains.getAttribute( 'position' );
					for ( let i = 0; i < chains.length; i ++ ) 
					{
						let pos = chains[i];
						position.setXYZ( i, pos.x, pos.y, pos.z );
					}
					this.ikRig_chains.getAttribute( 'position' ).needsUpdate = true;
				}
				if(this.ikRig_solved)
				{
					const position = this.ikRig_solved.getAttribute( 'position' );
					for ( let i = 0; i < ikRig_solved.length; i ++ ) 
					{
						let pos = ikRig_solved[i];
						position.setXYZ( i, pos.x, pos.y, pos.z );
					}
					this.ikRig_solved.getAttribute( 'position' ).needsUpdate = true;
				}
				if(this.ikRig_poles)
				{
					const position = this.ikRig_poles.getAttribute( 'position' );
					for ( let i = 0; i < ikRig_poles.length; i ++ ) 
					{
						let pos = ikRig_poles[i];
						position.setXYZ( i, pos.x, pos.y, pos.z );
					}
					this.ikRig_poles.getAttribute( 'position' ).needsUpdate = true;
				}
			}
		}
		


	}

	ToggleStats(show)
	{
		if(this.stats)
		{
			console.log("stats hidden: "+show);
			this.stats.domElement.hidden=!show;
		}
	}

	async load3DEnvironment(name, remove)
	{
		
		if(remove)
		{
			for(let i=0; i<this.current_3d_environments.length; i++)
				this.scene.remove( this.current_3d_environments[i] );
			this.scene.remove(this.ground);
			this.current_3d_environments = [];
			this.current_3d_environment_urls = [];
		}
		this.current_3d_environment_urls.push(name);
		let loader = new GLTFLoader();
		const loacal_url = await aws_helper.getS3File(name)
		let object = await loader.loadAsync( loacal_url);
		let model = object.scene;
		//object = model;
		let screen;
		model.traverse( function ( child ) 
		{
			if ( child.isMesh ) 
			{
				if(child.name == "screen1")
				{
					screen = child;
					const m = new THREE.MeshBasicMaterial( {			
						map : child.material.map
						
						
					} );
			
					child.material = m;
					
				}
				child.castShadow = false;
				child.receiveShadow = true;
			}
		});
		if(screen!=undefined)
			this.screen = screen;
		console.log("Screen "+this.screen);
		this.scene.add( model );
		this.current_3d_environments.push(model);
		model.receiveShadow = true;	
		this.time = 0;
		
	}

	async loadAnimatedModel(name)
	{
		
		this.current_animated_object_url = name;
		const local_url = await aws_helper.getS3File('public/GymInstructor.glb')
		//const local_url = await aws_helper.getS3File('public/test.glb')
		this.scene.remove( this.current_animated_model );
		this.current_animated_model = null;

		let loader = new GLTFLoader();
		let object = await loader.loadAsync(local_url);
		
		let model = object.scene;
		model.traverse( function ( child ) 
		{
			if ( child.isMesh ) 
			{

				child.castShadow = true;
				child.receiveShadow = true;
			}
		});

		this.scene.add( model );
		this.current_animated_model = model;
		this.current_animated_model.receiveShadow = true;	
		this.time = 0;

		this.playAnimation(object)
	
	}

	playAnimation(object)
	{
		const animations = object.animations;
		this.current_animated_clip = animations[0];
		this.anim_mixer = new THREE.AnimationMixer(object.scene);
		let idle = this.anim_mixer.clipAction( animations[0] )
		idle.play();
	}
	async exportCharacter( wav, name, resolve )
	{
		const exporter = new GLTFExporter();
		const options = {
			binary: true,
			animations: this.current_model.animations,
			includeCustomExtensions: true
		};
		let model = this.current_model
		if(this.current_model.matrix==undefined)
		{

			model = this.current_model.scene;
			
			model.traverse( function ( child ) 
			{
				if ( child.isMesh ) 
				{
					child.receiveShadow = true;
					child.frustumCulled =false;
					
					if(child.material.length==undefined)
					{
						if(child.material.userData.gltfExtensions!=undefined)
						{
							const m = new THREE.MeshStandardMaterial( {

								color: child.material.color,			
								map : child.material.uniforms.map.value,
								alphaTest  : child.material.alphaTest,
								side : THREE.DoubleSide
								
							} );
							child.material = m;
						}
					}
					else{
						for(let i=0; i<child.material.length; i++)
						{
							if(child.material[i].userData.gltfExtensions!=undefined)
							{
								const m = new THREE.MeshStandardMaterial( {

									color: child.material[i].color,	
									map : child.material[i].uniforms.map.value,
									alphaTest  : child.material[i].alphaTest,
									side : THREE.DoubleSide
									
								} );
								child.material[i] = m;
							}
						}
					}
					
				}
			});
		}

		exporter.parse(model, async function (result) 
		{
			if ( result instanceof ArrayBuffer ) {

	
				//const blob = new Blob( [ result  ], { type: 'application/octet-stream' } );
				//saveAs(blob, "character.glb");
				//saveAs(wav, "audio.wav");

				//const zipped = await zip( {'character.glb':blob, 'audio.wav':wav} ).promise();
				//const z = new Blob( [ zipped.buffer ], { type: 'application/zip' } );
				//saveAs(z, "recording.zip");
				const archiveContent = {}
				archiveContent['character.glb'] = new Uint8Array(result);
				//const archiveStruct2 = {}
				archiveContent['audio.wav'] = new Uint8Array(await wav.arrayBuffer());
				const zipped = zipSync( archiveContent, { level: 9 } );
				saveAs(new Blob([zipped.buffer]), name+`.zip`);
				

			} else {
				console.log(result );
				const jsonString = JSON.stringify(result );
				console.log(jsonString);

				// The following doesn't seem to work due to iframe sandboxing.
				// But please save the gltf json from the Console to obtain the file.
				const blob = new Blob([jsonString], { type: "application/json" });
				saveAs(blob, "character.gltf");
				saveAs(wav, "audio.wav");
				
			}
			console.log("Download requested");
			resolve();
			
		},
		function (error){
			console.log(error)
			resolve();
		},
		options);
	}
	async loadCharacter(name, jname)
	{
		let local_url = await aws_helper.getS3File(name, 'force-cache', true);
		let local_json_url =await aws_helper.getS3File(jname,'no-store');

		if(this.current_model)
		{
			this.scene.remove( this.current_model );
			this.scene.remove( this.current_model.scene );
			this.current_model = null;
		}
		// if ( this.currentVrm ) {

		// 	this.scene.remove( this.currentVrm.scene );
		// 	this.currentVrm.dispose();

		// }

		const jd = await fetch(local_json_url)
		
		const jdata = await jd.json();
		let loader = new GLTFLoader();

		let object = await loader.loadAsync(local_url[0]);
		this.current_model_url = name;
		this.current_json_url = jname;

		let morphs = [];

		for(var l in jdata.BlendshapeLink)
		{
            let blendshape = []
            for(var d in jdata.BlendshapeLink[l].data)
            {
				let m = 
                {
                    name: jdata.BlendshapeLink[l].data[d],
					full_name: jdata.BlendshapeLink[l].data[d],
                    morph: undefined,
                    id: -1
                }
				for(var k in jdata.MorphTargets)
				{
					var spl = jdata.BlendshapeLink[l].data[d].split(".");
					if(jdata.MorphTargets[k].Label == spl[0])
					{
						for(var n in jdata.MorphTargets[k].data)
						{
							if(jdata.MorphTargets[k].Label+"."+jdata.MorphTargets[k].data[n] == jdata.BlendshapeLink[l].data[d])
							{
								m.full_name = jdata.BlendshapeLink[l].data[d];
								m.name = jdata.MorphTargets[k].Label+"."+n;
								//jdata.BlendshapeLink[l].data[d] = jdata.MorphTargets[k].Label+"."+n;
							}
						}
					}
				}
                
                blendshape.push(m);
            }
            morphs.push(blendshape);
        }
		let model = object.scene;
		object = model;
		console.log("traverse: "+model.name);
		let API = this.API;
		let current_environment = this.current_environment;
		let current_environment_name = this.current_environment_name;
		this.current_character_name = jdata.Name;
		object.scale.set(jdata.Scale[0]*1,jdata.Scale[1]*1,jdata.Scale[2]*1)
		
		this.scene.add( this.hemiLight );

		// let map = [
		// 	[MotionRetargeter.Hips,"Hips"],
		// 	[MotionRetargeter.RightUpperLeg,"RightUpperLeg"],
		// 	[MotionRetargeter.RightLowerLeg,"RightLowerLeg"],
		// 	[MotionRetargeter.RightFoot,"RightFoot"],
		// 	[MotionRetargeter.LeftUpperLeg,"LeftUpperLeg"],
		// 	[MotionRetargeter.LeftLowerLeg,"LeftLowerLeg"],
		// 	[MotionRetargeter.LeftFoot,"LeftFoot"],
		// 	[MotionRetargeter.Spine,"Spine"],
		// 	[MotionRetargeter.Chest,"Spine1"],
		// 	[MotionRetargeter.UpperChest,"Spine3"],
		// 	[MotionRetargeter.Neck,"Neck"],
		// 	[MotionRetargeter.Head,"Head"],
		// 	[MotionRetargeter.LeftShoulder,"LeftShoulder"],
		// 	[MotionRetargeter.LeftUpperArm,"LeftUpperArm"],
		// 	[MotionRetargeter.LeftLowerArm,"LeftLowerArm"],
		// 	[MotionRetargeter.LeftHand,"LeftHand"],
		// 	[MotionRetargeter.RightShoulder,"RightShoulder"],
		// 	[MotionRetargeter.RightUpperArm,"RightUpperArm"],
		// 	[MotionRetargeter.RightLowerArm,"RightLowerArm"],
		// 	[MotionRetargeter.RightHand,"RightHand"],
		// 	[MotionRetargeter.RightToes,"RightToe"],
		// 	[MotionRetargeter.LeftToes,"LeftToe"],
		// 	[MotionRetargeter.RightThumbProximal,"RightThumb1"],
		// 	[MotionRetargeter.RightThumbIntermediate,"RightThumb2"],
		// 	[MotionRetargeter.RightThumbDistal,"RightThumb3"],
		// 	[MotionRetargeter.RightIndexProximal,"RightIndex1"],
		// 	[MotionRetargeter.RightIndexIntermediate,"RightIndex2"],
		// 	[MotionRetargeter.RightIndexDistal,"RightIndex3"],
		// 	[MotionRetargeter.RightMiddleProximal,"RightMiddle1"],
		// 	[MotionRetargeter.RightMiddleIntermediate,"RightMiddle2"],
		// 	[MotionRetargeter.RightMiddleDistal,"RightMiddle3"],
		// 	[MotionRetargeter.RightRingProximal,"RightRing1"],
		// 	[MotionRetargeter.RightRingIntermediate,"RightRing2"],
		// 	[MotionRetargeter.RightRingDistal,"RightRing3"],
		// 	[MotionRetargeter.RightLittleProximal,"RightPinky1"],
		// 	[MotionRetargeter.RightLittleIntermediate,"RightPinky2"],
		// 	[MotionRetargeter.RightLittleDistal,"RightPinky3"],
		// 	[MotionRetargeter.LeftThumbProximal,"LeftThumb1"],
		// 	[MotionRetargeter.LeftThumbIntermediate,"LeftThumb2"],
		// 	[MotionRetargeter.LeftThumbDistal,"LeftThumb3"],
		// 	[MotionRetargeter.LeftIndexProximal,"LeftIndex1"],
		// 	[MotionRetargeter.LeftIndexIntermediate,"LeftIndex2"],
		// 	[MotionRetargeter.LeftIndexDistal,"LeftIndex3"],
		// 	[MotionRetargeter.LeftMiddleProximal,"LeftMiddle1"],
		// 	[MotionRetargeter.LeftMiddleIntermediate,"LeftMiddle2"],
		// 	[MotionRetargeter.LeftMiddleDistal,"LeftMiddle3"],
		// 	[MotionRetargeter.LeftRingProximal,"LeftRing1"],
		// 	[MotionRetargeter.LeftRingIntermediate,"LeftRing2"],
		// 	[MotionRetargeter.LeftRingDistal,"LeftRing3"],
		// 	[MotionRetargeter.LeftLittleProximal,"LeftPinky1"],
		// 	[MotionRetargeter.LeftLittleIntermediate,"LeftPinky2"],
		// 	[MotionRetargeter.LeftLittleDistal,"LeftPinky3"]
		// ];

		let body = object.getObjectByName( "Wolf3D_Body" );
		let body2 = object.getObjectByName( "Wolf3D_Avatar" );
		const isWolf3D = (body!=undefined || body2!=undefined);
		// if(body!=undefined || body2!=undefined)
		// {
		// 	map[MotionRetargeter.RightThumbProximal][1] = 		"RightHandThumb1";
		// 	map[MotionRetargeter.RightThumbIntermediate][1] = 	"RightHandThumb2";
		// 	map[MotionRetargeter.RightThumbDistal][1] = 		"RightHandThumb3";
		// 	map[MotionRetargeter.RightIndexProximal][1] = 		"RightHandIndex1";
		// 	map[MotionRetargeter.RightIndexIntermediate][1] = 	"RightHandIndex2";
		// 	map[MotionRetargeter.RightIndexDistal][1] = 		"RightHandIndex3";
		// 	map[MotionRetargeter.RightMiddleProximal][1] = 		"RightHandMiddle1";
		// 	map[MotionRetargeter.RightMiddleIntermediate][1] = 	"RightHandMiddle2";
		// 	map[MotionRetargeter.RightMiddleDistal][1] = 		"RightHandMiddle3";
		// 	map[MotionRetargeter.RightRingProximal][1] = 		"RightHandRing1";
		// 	map[MotionRetargeter.RightRingIntermediate][1] = 	"RightHandRing2";
		// 	map[MotionRetargeter.RightRingDistal][1] = 			"RightHandRing3";
		// 	map[MotionRetargeter.RightLittleProximal][1] = 		"RightHandPinky1";
		// 	map[MotionRetargeter.RightLittleIntermediate][1] = 	"RightHandPinky2";
		// 	map[MotionRetargeter.RightLittleDistal][1] = 		"RightHandPinky3";
		// 	map[MotionRetargeter.LeftThumbProximal][1] = 		"LeftHandThumb1";
		// 	map[MotionRetargeter.LeftThumbIntermediate][1] = 	"LeftHandThumb2";
		// 	map[MotionRetargeter.LeftThumbDistal][1] = 			"LeftHandThumb3";
		// 	map[MotionRetargeter.LeftIndexProximal][1] = 		"LeftHandIndex1";
		// 	map[MotionRetargeter.LeftIndexIntermediate][1] = 	"LeftHandIndex2";
		// 	map[MotionRetargeter.LeftIndexDistal][1] = 			"LeftHandIndex3";
		// 	map[MotionRetargeter.LeftMiddleProximal][1] = 		"LeftHandMiddle1";
		// 	map[MotionRetargeter.LeftMiddleIntermediate][1] = 	"LeftHandMiddle2";
		// 	map[MotionRetargeter.LeftMiddleDistal][1] = 		"LeftHandMiddle3";
		// 	map[MotionRetargeter.LeftRingProximal][1] = 		"LeftHandRing1";
		// 	map[MotionRetargeter.LeftRingIntermediate][1] = 	"LeftHandRing2";
		// 	map[MotionRetargeter.LeftRingDistal][1] = 			"LeftHandRing3";
		// 	map[MotionRetargeter.LeftLittleProximal][1] = 		"LeftHandPinky1";
		// 	map[MotionRetargeter.LeftLittleIntermediate][1] = 	"LeftHandPinky2";
		// 	map[MotionRetargeter.LeftLittleDistal][1] = 		"LeftHandPinky3";

		// }

		// for(let i=0; i<map.length; i++)
		// {
		// 	var found = jdata.SkeletonLink.filter(function(item) { return item.Label === map[i][1]; });

		// 	let name = "";
		// 	if(found.length>0)
		// 		name = found[0].data;
		// 	if((body!=undefined ||body2!=undefined) && i>21)
		// 		name = map[i][1];
		// 	if(name!="")
		// 	{
		// 		let child = object.getObjectByName( name );
		// 		if(child!=undefined && found.length>0 && found[0].rotation!=undefined )
		// 		{
		// 			child.setRotationFromQuaternion(new THREE.Quaternion(found[0].rotation.x,-found[0].rotation.y,-found[0].rotation.z,found[0].rotation.w));
		// 		}
		// 		bones.push(child);
		// 	}
		// 	else
		// 		bones.push(undefined);
			
		// }

		object.traverse( function ( child ) 
		{
			
			if ( child.isMesh ) 
			{
				// if(child.morphTargetDictionary && child.name==='Wolf3D_Avatar')
				// {

				// }
				if(child.morphTargetDictionary)
				{
					
					for(var key in child.morphTargetDictionary)
					{
						var morph_name = child.name+"."+child.morphTargetDictionary[key];
						
						if(child.parent && child.parent.userData.name!="" && child.parent.userData.name!=undefined&& child.parent.userData.fromFBX!=undefined)
							morph_name = child.parent.userData.name+"."+key;
						if(child.name==='Wolf3D_Avatar' || child.name==='Wolf3D_Head' || child.name==='Wolf3D_Teeth') 
						{
							morph_name = child.name+"."+key;
						}
						
						
						for(var m = 0; m<morphs.length; m++)
						{
							
							for(var j=0; j<morphs[m].length; j++)
							{
								var spl = morphs[m][j].name.split(".");
								let full_name = spl[0]+"."+getKeyByValue(child.morphTargetDictionary,parseInt(spl[spl.length-1]));
								if(child.morphTargetDictionary[key]!=key)
								{
									spl = morphs[m][j].full_name.split(".");
									full_name = spl[spl.length-2]+"."+spl[spl.length-1];
								}
								//console.log({full_name});
								if(morphs[m][j].name == morph_name && morphs[m][j].morph==undefined)
								{
									morphs[m][j].morph = child;
									morphs[m][j].id = child.morphTargetDictionary[key];
									
								}
								if(full_name == morph_name && morphs[m][j].morph==undefined)
								{
									morphs[m][j].full_name = key;
									morphs[m][j].morph = child;
									morphs[m][j].id = child.morphTargetDictionary[key];
								}
								
							}
						}
					}
				}
				//for (var i = 0; i < child.material.length; i++)
				{
					const m = new THREE.MeshStandardMaterial( {
						color: child.material.color,
						metalness: jdata.Metalness,
						roughness: jdata.Roughness,							
						map : child.material.map,
						skinning: true,
						morphTargets: child.material.morphTargets,
						
					} );
					if(jdata.Material)
					{
						child.material = m;
						if(jdata.DoubleFace)
							child.material.side = THREE.DoubleSide;
					}
					if(current_environment_name!="GreenScreen" && current_environment_name!="BlueScreen")
					{
						child.material.envMap = current_environment;
						child.material.envMapIntensity = API.envMapIntensity;
					}
					
					
				}
				
				child.castShadow = true;
				child.receiveShadow = true;
				child.frustumCulled =false;
			}
		});
		
		this.scene.add( object );
		this.current_model = object;
		this.current_model.etag = local_url[1];
		//this.bones = bones;
		this.morphs = morphs;
		//this.mixer = new THREE.AnimationMixer(object);
		engine_event.emit('new_character');
		
		this.retargeter.scanBones(isWolf3D ? Retargeter.Wolf3DSkeleton.getBones(object, jdata) : Retargeter.GeneralSkeleton.getBones(object, jdata));
		//var b = 7;
		//for(var b = 0; b < 7; b++)
			//for(let j=0; j<this.morphs[b].length; j++)
		// this.morphs[0][0].morph.morphTargetInfluences[this.morphs[0][0].id] = 1;
		// this.morphs[1][0].morph.morphTargetInfluences[this.morphs[1][0].id] = 1;
		// this.morphs[2][0].morph.morphTargetInfluences[this.morphs[2][0].id] = 1;
		// this.morphs[3][0].morph.morphTargetInfluences[this.morphs[3][0].id] = 1;
		// this.morphs[4][0].morph.morphTargetInfluences[this.morphs[4][0].id] = 1;
		// this.morphs[5][0].morph.morphTargetInfluences[this.morphs[5][0].id] = 1;
		// this.morphs[6][0].morph.morphTargetInfluences[this.morphs[6][0].id] = 1;
		// this.morphs[7][0].morph.morphTargetInfluences[this.morphs[7][0].id] = 1;
		// this.morphs[8][0].morph.morphTargetInfluences[this.morphs[8][0].id] = 1;
		//this.morphs[9][0].morph.morphTargetInfluences[this.morphs[9][0].id] = 1;
		//this.morphs[10][0].morph.morphTargetInfluences[this.morphs[10][0].id] = 1;
		//this.morphs[11][0].morph.morphTargetInfluences[this.morphs[11][0].id] = 1;
		//this.morphs[12][0].morph.morphTargetInfluences[this.morphs[12][0].id] = 1;

		//this.play();
		//if(client.pc)
		//	client.pc.dispatchEvent({type: "datachannel", data: undefined});
		//this.$root.$emit('new_character')
		this.dirLight.castShadow = true;
		//this.spotLight.castShadow = true;
		this.ground.receiveShadow = true;	

		// for(let i=0; i<this.gizmos.length; i++)
		// {
		// 	this.gizmos[i].position.copy(this.current_model.position);
		// }

		this.current_model.position.copy(this.gizmos[0].position);
		this.current_model.rotation.y =this.gizmos[0].rotation.z;
				

	}

	updateVrmMorph(id, val)
	{
		if(this.current_model && this.current_model.expressionManager)
		{
			if(id==0) this.current_model.expressionManager.setValue( VRMExpressionPresetName.Aa, val);
			if(id==1) this.current_model.expressionManager.setValue( VRMExpressionPresetName.Ee, val);
			if(id==2) this.current_model.expressionManager.setValue( VRMExpressionPresetName.Ih, val);
			if(id==3) this.current_model.expressionManager.setValue( VRMExpressionPresetName.Oh, val);
			if(id==4) this.current_model.expressionManager.setValue( VRMExpressionPresetName.Ou, val);

			if(id==12) this.current_model.expressionManager.setValue( VRMExpressionPresetName.Blink, val);
			if(id==13) this.current_model.expressionManager.setValue( VRMExpressionPresetName.Relaxed, val);
			if(id==14) this.current_model.expressionManager.setValue( VRMExpressionPresetName.Sad, val);
			if(id==15) this.current_model.expressionManager.setValue( VRMExpressionPresetName.Angry, val);
			if(id==16) this.current_model.expressionManager.setValue( VRMExpressionPresetName.Surprised, val);
		}
	}
	async onLoadSlide(url,local)
	{
		let u = url;
		if(!local)
			u = await aws_helper.getS3File(u)
		var loader = new THREE.TextureLoader();
		this.slides.push(await loader.load(u));
		this.slides[this.slides.length-1].flipY=false;
		engine_event.emit('slide',u);
		
	}
	async onLoadSlides(urls)
	{
		engine_event.emit('clear_slides');
		this.slide_urls = urls;
		this.slides = [];
		for(var u of urls)
		{
			u = await aws_helper.getS3File(u,'no-store')
			var loader = new THREE.TextureLoader();
			this.slides.push(await loader.load(u));
			this.slides[this.slides.length-1].flipY=false;
			engine_event.emit('slide',u);
		}
	}
	async loadVRM( url , name) 
	{
		this.current_model_url = url;
		let vrm_loader = new GLTFLoader();
		vrm_loader.register( parser => new VRMLoaderPlugin(parser) );

		vrm_loader.crossOrigin = 'anonymous';
		//const local_url = await aws_helper.getS3File(url,'force-cache');
		let local_url = await aws_helper.getS3File(url, 'force-cache', true);
		var gltf = await vrm_loader.loadAsync(local_url[0]);
		// let bones = [];
		let morphs = [];

		gltf.scene.scale.set(1,1,1);
		//VRMUtils.removeUnnecessaryVertices( gltf.scene );
		//VRMUtils.removeUnnecessaryJoints( gltf.scene );
		const vrm = gltf.userData.vrm;
		//VRMUtils.rotateVRM0( vrm );
		if (vrm.meta?.metaVersion === '0') {
			// vrm.humanoid.scene.rotation.y = Math.PI;
			vrm.humanoid.getNormalizedBoneNode("hips").rotation.y = Math.PI;
		}

		// if ( this.currentVrm ) {

		// 	this.scene.remove( this.currentVrm.scene );
		// 	this.currentVrm.dispose();

		// }
		if(this.current_model)
		{
			this.scene.remove( this.current_model );
			this.scene.remove( this.current_model.scene );
			this.current_model = null;
			this.current_character_name=name;
			//this.scene.remove( this.hemiLight );
		}
		this.current_character_name=name;
		this.current_model = vrm;
		this.current_model.etag = local_url[1];
		this.scene.add( vrm.scene );
		
		// VRMSchema;
		// vrm.humanoid.getBoneNode( VRMSchema.HumanoidBoneName.Hips).rotation.y = Math.PI;
		if(vrm.lookAt)
			vrm.lookAt.target = this.viewportCamera;
		
		gltf.scene.traverse( function ( child ) 
		{
			if ( child.isMesh ) 
			{
				child.receiveShadow = false;//true;
				child.frustumCulled =false;
				let m = child.material;
				m.castShadow = true;
			}
		});

		for(let i=0; i< 17; i++)
		{
			let blendshape = []
			let m = 
			{
				name: "",
				full_name: "",
				morph: undefined,
				id: -1
			}
			blendshape.push(m);
			morphs.push(blendshape);
		}

		this.morphs = morphs;
		engine_event.emit('new_character');
		this.retargeter.scanBones(Retargeter.VRMSkeleton.getBones(vrm));
		this.dirLight.castShadow = true;
		this.ground.receiveShadow = true;	

		this.current_model.scene.position.copy(this.gizmos[0].position);
		this.current_model.scene.rotation.y =this.gizmos[0].rotation.z;

	}

	async loadAnimation(name)
	{
		const manager = new LoadingManager();
		let loader = new GLTFLoader(manager);
		let local_url = await aws_helper.getS3File(name);
		let object = await loader.loadAsync( local_url );
		let model = object.scene;
		model.scale.set(100,100,100)
		//this.scene.add( model );
		
		//model.face = {morphTargetInfluences:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]};
		this.animation = model.children[0].children[0];
		
		this.animation.traverse( function ( child ) 
		{
			if(child.name=="Hip")
			{
				child.uid = 0;
				child.morphTargetInfluences = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
			}
			else if(child.name=="RHip")
				child.uid = 1;
			else if(child.name=="RKnee")
				child.uid = 2;
			else if(child.name=="RAnkle")
				child.uid = 3;
			else if(child.name=="LHip")
				child.uid = 4;
			else if(child.name=="LKnee")
				child.uid = 5;
			else if(child.name=="LAnkle")
				child.uid = 6;
			else if(child.name=="Spine")
				child.uid = 7;
			else if(child.name=="Spine1")
				child.uid = 8;
			else if(child.name=="Spine2")
				child.uid = 9;
			else if(child.name=="Neck")
				child.uid = 10;
			else if(child.name=="Head")
				child.uid = 11;
			else if(child.name=="LShoulder")
				child.uid = 12;
			else if(child.name=="LArm")
				child.uid = 13;
			else if(child.name=="LForeArm")
				child.uid = 14;
			else if(child.name=="LWrist")
				child.uid = 15;
			else if(child.name=="RShoulder")
				child.uid = 16;
			else if(child.name=="RArm")
				child.uid = 17;
			else if(child.name=="RForeArm")
				child.uid = 18;
			else if(child.name=="RWrist")
				child.uid = 19;
			else if(child.name=="RToe")
				child.uid = 20;
			else if(child.name=="LToe")
				child.uid = 21;
			else if(child.name=="RightThumb1")
				child.uid = 22;
			else if(child.name=="RightThumb2")
				child.uid = 23;
				else if(child.name=="RightThumb3")
				child.uid = 24; 
			else if(child.name=="RightThumb4")
				child.uid = 25; 
			else if(child.name=="RightIndex1")
				child.uid = 26; 
			else if(child.name=="RightIndex2")
				child.uid = 27; 
			else if(child.name=="RightIndex3")
				child.uid = 28; 
			else if(child.name=="RightIndex4")
				child.uid = 29; 
			else if(child.name=="RightMiddle1")
				child.uid = 30; 
			else if(child.name=="RightMiddle2")
				child.uid = 31; 
			else if(child.name=="RightMiddle3")
				child.uid = 32; 
			else if(child.name=="RightMiddle4")
				child.uid = 33; 
			else if(child.name=="RightRing1")
				child.uid = 34; 
			else if(child.name=="RightRing2")
				child.uid = 35; 
			else if(child.name=="RightRing3")
				child.uid = 36; 
			else if(child.name=="RightRing4")
				child.uid = 37; 
			else if(child.name=="RightPinky1")
				child.uid = 38; 
			else if(child.name=="RightPinky2")
				child.uid = 39; 
			else if(child.name=="RightPinky3")
				child.uid = 40; 
			else if(child.name=="RightPinky4")
				child.uid = 41; 
			else if(child.name=="LeftThumb1")
				child.uid = 42; 
			else if(child.name=="LeftThumb2")
				child.uid = 43; 
			else if(child.name=="LeftThumb3")
				child.uid = 44; 
			else if(child.name=="LeftThumb4")
				child.uid = 45; 
			else if(child.name=="LeftIndex1")
				child.uid = 46; 
			else if(child.name=="LeftIndex2")
				child.uid = 47; 
			else if(child.name=="LeftIndex3")
				child.uid = 48; 
			else if(child.name=="LeftIndex4")
				child.uid = 49; 
			else if(child.name=="LeftMiddle1")
				child.uid = 50; 
			else if(child.name=="LeftMiddle2")
				child.uid = 51;
			else if(child.name=="LeftMiddle3")
				child.uid = 52; 
			else if(child.name=="LeftMiddle4")
				child.uid = 53; 
			else if(child.name=="LeftRing1")
				child.uid = 54; 
			else if(child.name=="LeftRing2")
				child.uid = 55; 
			else if(child.name=="LeftRing3")
				child.uid = 56; 
			else if(child.name=="LeftRing4")
				child.uid = 57; 
			else if(child.name=="LeftPinky1")
				child.uid = 58; 
			else if(child.name=="LeftPinky2")
				child.uid = 59; 
			else if(child.name=="LeftPinky3")
				child.uid = 60; 
			else if(child.name=="LeftPinky4")
				child.uid = 61; 


		});
		
		const animations = object.animations;
		this.default_animation = animations[0];
		this.mixer = new THREE.AnimationMixer(model);
		let idle = this.mixer.clipAction( animations[0] )
		idle.play();
		
	}

	async loadCameraMotion(name)
	{
		const manager = new LoadingManager();
		let loader = new GLTFLoader(manager);
		let local_url = await aws_helper.getS3File(name);
		let object = await loader.loadAsync( local_url );
		let model = object.scene;
		this.camera_motion_object = model.children[0].children[0];
		this.camera_motions = object.animations;
		this.camera_mixer = new THREE.AnimationMixer(model);
		

		//let idle = this.camera_mixer.clipAction( this.camera_motions[0] )
		//idle.play();
		//engine_event.emit('camera_motions',this.camera_motions);
		store.state.camera_motions = [];
        store.state.camera_motions.push({"name":"free"});
        store.state.camera_motions.push({"name":"follow"});
        for(let m in this.camera_motions)
          store.state.camera_motions.push({"name":this.camera_motions[m].name})
	}

	onCameraMotion(index)
	{
		index;
		// this.camera_index = index;
		// this.camera_mixer.stopAllAction();
		// if(index==0)
		// {
		// 	this.controls.enableRotate = true;
		// 	this.controls.enablePan = true;
		// 	this.controls.enableZoom = true;
		// 	this.camera.near = 0.001;
		// 	this.camera.setFocalLength(30);
		// 	this.camera.position.set( 0, 1, 4 );
		// 	this.camera.lookAt( 0, 0, 1 );
		// 	this.controls.target.set( 0, 1, 0 );
		// 	this.controls.update();
		// }
		
		// else{
		// 	this.controls.enableRotate = false;
		// 	this.controls.enablePan = false;
		// 	this.controls.enableZoom = false;
		// 	this.camera.setFocalLength(80);
		// }
		// if(index == 1)
		// {
		// 	this.camera.position.set( 0, 1, 4 );
		// 	this.camera.lookAt( 0, 0, 1 );
		// 	this.controls.target.set( 0, 1, 0 );
		// 	this.controls.update();
		// 	console.log("follow camera")
		// }
		// if(index>=2)
		// {
		// 	let idle = this.camera_mixer.clipAction( this.camera_motions[index-2] )
		// 	idle.play();
		// }
	}

	async loadEnvironment(urls, name = "")
	{
		this.current_environment_urls = urls;
		let loader = new THREE.CubeTextureLoader();
		if(urls[0]=="")
		{
			this.disableBackground();
			return;
		}
		let local_urls = [
			await aws_helper.getS3File(urls[0]),
			await aws_helper.getS3File(urls[1]),
			await aws_helper.getS3File(urls[2]),
			await aws_helper.getS3File(urls[3]),
			await aws_helper.getS3File(urls[4]),
			await aws_helper.getS3File(urls[5])]
		let cubeTexture = await loader.loadAsync(local_urls);
	
		cubeTexture.encoding = THREE.sRGBEncoding;
		
		this.scene.background = cubeTexture;
		this.current_environment = cubeTexture;
		if(name==="")
			name = urls[0];
		this.current_environment_name = name;
	
		let API = this.API;
		if(this.current_model!=undefined)
		if(typeof this.current_model.traverse === 'function')
		{
			this.current_model.traverse( function ( child ) 
			{
				if ( child.isMesh ) 
				{
					if(name!="GreenScreen" && name!="BlueScreen")
					{
						child.material.envMap = cubeTexture;
						child.material.envMapIntensity = API.envMapIntensity;
					}
					
				}
			});
		}
		//this.em.emit('new_environment');
		engine_event.emit('new_environment');
		
	}
	rotateCharacter(value)
	{
		//if(this.current_model)
		//	this.current_model.rotation.y = value*3.6*0.0174533;
		//if(this.currentVrm)
			//this.scene.rotation.y= value*1.8*0.0174533;
			//this.currentVrm.humanoid.y = value*3.6*0.0174533;
			let q = new THREE.Quaternion;
			q.setFromEuler(new THREE.Euler(0, (360*(value/100))*0.0174533, 0));
			this.scene.quaternion.set(q.x,q.y,q.z,q.w);
			
	}
	findCharacterURL(name, charater_list)
	{
		for(let i=0; i< charater_list.length; i++)
		{
			if(charater_list[i][3] == name)
				return [charater_list[i][0],charater_list[i][2]];
		}
		return ["",""];
	}
	findEnvironmentURL(name, environment_list)
	{
		for(let i=0; i< environment_list.length; i++)
		{
			if(environment_list[i][6] == name)
				return environment_list[i].slice(0,6);
		}
		return ["","","","","",""];
	}
	saveProject()
	{
		let output = {
			metadata: {},
			camera: this.viewportCamera.toJSON(),
			controls: this.controls.target,
			character_url: {
				url: this.current_model_url,
				matrix: this.current_model.matrix? this.current_model.matrix.elements : this.current_model.scene.matrix.elements,
			},
			definition: this.current_json_url,
			environment: this.current_environment_urls,
			enviroments_3d: [],
			slides:[],
			cameras:[],
			ground: this.ground.parent === this.scene,
			animated_object: this.current_animated_object_url,
			recordings:[],

		};
		
		
		for(let i=0; i<this.slide_urls.length; i++)
		{
			output.slides.push(this.slide_urls[i]);
		}
		for(let i=0; i<this.current_3d_environment_urls.length; i++)
			output.enviroments_3d.push(this.current_3d_environment_urls[i])
		for(let i=0; i<this.recording_list.length; i++)
			output.recordings.push({clip:this.recording_list[i].clip.toJSON(),audio:this.recording_list[i].audio});
		return output;
	}
	async loadProject(proj)
	{
		for(let i=0; i<this.current_3d_environments.length; i++)
			this.scene.remove( this.current_3d_environments[i] );
		this.scene.remove(this.ground);
		this.scene.remove(this.current_animated_model);
		this.current_animated_model = null;
		this.current_3d_environments = [];
		this.current_3d_environment_urls= [];
		this.current_environment_urls = [];
		this.current_model_url = undefined;
		this.current_animated_object_url = undefined;
		this.anim_mixer = undefined;
		this.recording_list = [];
		this.audioDataArray = [];

		const json = JSON.parse(proj);
		var loader = new THREE.ObjectLoader();
		this.viewportCamera = loader.parse( json.camera);
		this.viewportCamera.near = 0.1;
		this.viewportCamera.far = 200;
		this.defaultCamera = this.viewportCamera;
		this.screen = undefined;
		if(json.controls )
		{
			this.controls = new OrbitControls( this.viewportCamera, this.renderer.domElement );
			this.controls.target.set( json.controls.x,json.controls.y,json.controls.z );
			//this.controls.update();
		}
		else{
			this.initControls();
		}

		if(json.character_url)
		{
			let ext = json.character_url.url.split(".");
            if(ext.length>1)
            {
                ext = ext[ext.length-1];
            }
			if(ext=="glb" &&  json.definition)
				await this.loadCharacter(json.character_url.url,json.definition);
			else if(ext=="vrm")
				await this.loadVRM(json.character_url.url,json.character_url.url);
			if(json.character_url.matrix)
			{
				let model = this.current_model;
				if(!model.matrix)
					model = this.current_model.scene;
					model.matrix.fromArray(json.character_url.matrix);
				if ( model.matrixAutoUpdate ) model.matrix.decompose( model.position, model.quaternion, model.scale )
				model.updateMatrix();
				for(let i=0; i<this.gizmos.length; i++)
				{
					this.gizmos[i].position.copy(model.position);
					this.gizmos[0].rotation.z = model.rotation.y;
				}
			}
		}

		if(json.environment)
		{
			await this.loadEnvironment(json.environment);
		}
		if(json.enviroments_3d)
		{
			for(let i=0; i<json.enviroments_3d.length; i++)
			{
				await this.load3DEnvironment(json.enviroments_3d[i],false);
			}
		}
		if(json.animated_object)
		{
			await this.loadAnimatedModel(json.animated_object);
		}
		if(json.ground)
		{
			this.scene.add(this.ground);
		}
		

		if(json.slides)
		{
			await this.onLoadSlides(json.slides);
		}
		
		if(json.recordings)
		{
			
			for(var r of json.recordings)
			{
				let clip = THREE.AnimationClip.parse(r.clip);
				engine_event.emit('recording',clip);
				let audioData = r.audio;
				this.recording_list.push({clip:clip,audio:audioData});

				if(r.audio!={} && r.audio!=undefined)
				{
					let url = await aws_helper.getS3File(r.audio,'no-store');
					let blob = await fetch(url).then(r => r.blob());
			
					this.recording_list[this.recording_list.length-1].audio = blob;
				}
			}

			
		}

		this.onWindowResize();

		this.time = 0;
		this.clock = new THREE.Clock();
		this.slide_id = 0;
		if(this.screen!=undefined)
		{
			this.screen.material.map = this.slides[this.slide_id];
			this.screen.material.needsUpdate = true;	
		}
		//this.initControls();
	}
	//document.querySelector('#app').__vue__.$children[1].engine.setStage("Stage 1");
	setStage(stage_name)
	{
		if(this.current_project)
		{
			for(var c in this.loaded_characters)
				this.loaded_characters[c].visible = false;
			for(var l in this.current_project.Stages)
			{
				if(this.current_project.Stages[l].name == stage_name)
				{
					//console.log("character id "+this.current_project.Stages[l].current_character);
					//console.log(this.current_project.characters[this.current_project.Stages[l].current_character] + " "+this.current_project.environments[this.current_project.Stages[l].current_environment]);
					//for(var char in this.loaded_characters)
					//	console.log("loaded characters "+char+" "+this.loaded_characters[char].name);
					this.scene.background = this.loaded_environments[this.current_project.Stages[l].current_environment];
					this.current_environment = this.loaded_environments[this.current_project.Stages[l].current_environment];
					this.current_model = this.loaded_characters[this.current_project.Stages[l].current_character];
					this.current_model.visible = true;
					this.morphs = this.loaded_morphs[this.current_project.Stages[l].current_character];
					this.bones = this.loaded_bones[this.current_project.Stages[l].current_character];
					this.retargeter.scanBones(this.bones);
					let API = this.API;
					let cubeTexture = this.current_environment;

					this.current_model.traverse( function ( child ) 
					{
						if ( child.isMesh ) 
						{
							child.material.envMap =cubeTexture;
							child.material.envMapIntensity = API.envMapIntensity;	
						}
					});
					//this.em.emit('new_character');
					engine_event.emit('new_character');
					break;
				}
			}
		}

	}
	disableBackground()
	{
		this.scene.background = null;
	}
}




