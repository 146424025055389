import * as THREE from 'three';

export const ikRig = {
    root_pos: new THREE.Vector3(),
    root_rot: new THREE.Quaternion(),
    l_hand_rot: new THREE.Quaternion(),
    r_hand_rot: new THREE.Quaternion(),
    l_foot_rot: new THREE.Quaternion(),
    r_foot_rot: new THREE.Quaternion(),
    l_arm_direction: new THREE.Vector3(),
    l_arm_pole: new THREE.Vector3(),
    l_arm_len: 0.0,
    r_arm_direction: new THREE.Vector3(),
    r_arm_pole: new THREE.Vector3(),
    r_arm_len: 0.0,
    l_leg_direction: new THREE.Vector3(),
    l_leg_pole: new THREE.Vector3(),
    l_leg_len: 0,
    r_leg_direction: new THREE.Vector3(),
    r_leg_pole: new THREE.Vector3(),
    r_leg_len: 0,
    spine_direction: new THREE.Vector3(),
    spine_pole: new THREE.Vector3(),
    spine_len: 0,
    neck_direction: new THREE.Vector3(),
    neck_pole: new THREE.Vector3(),
    neck_len: 0,
    l_thumb_direction: new THREE.Vector3(),
    l_thumb_len: 0,
    r_thumb_direction: new THREE.Vector3(),
    r_thumb_len: 0,
    l_index_direction: new THREE.Vector3(),
    l_index_len: 0,
    r_index_direction: new THREE.Vector3(),
    r_index_len: 0,
    l_middle_direction: new THREE.Vector3(),
    l_middle_len: 0,
    r_middle_direction: new THREE.Vector3(),
    r_middle_len: 0,
    l_ring_direction: new THREE.Vector3(),
    l_ring_len: 0,
    r_ring_direction: new THREE.Vector3(),
    r_ring_len: 0,
    l_pinky_direction: new THREE.Vector3(),
    l_pinky_len: 0,
    r_pinky_direction: new THREE.Vector3(),
    r_pinky_len: 0,
    height: 0
}