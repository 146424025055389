import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    isAdmin: false,
    email: undefined,
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    showCharacterPanel: false,
    showEnvironmentPanel: false,
    showSlidesPanel: false,
    showRecordingPanel: false,
    showCameraPanel: false,
    showEditorPanel: true,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: false,
    showFooter: true,
    showMain: true,
    experimental: true,
    showSkeleton: false,
    micVoulume: 100,
    characters: [],
    environments: [],
    camera_motions: [],
    slides: [],
    cameras: [],
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    hasSlides: false,
    has3dEnvironment: false,
  },
  mutations: {
    closeAllPanels(state)
    {
      state.showConfig = false;
      state.showCharacterPanel = false;
      state.showEnvironmentPanel = false;
      state.showSlidesPanel = false;
      state.showCameraPanel = false;
      state.showRecordingPanel = false;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
      if(state.showConfig==true)
      {
        state.showCharacterPanel = false;
        state.showEnvironmentPanel = false;
        state.showSlidesPanel = false;
        state.showCameraPanel = false;
        state.showRecordingPanel = false;
      }
    },
    toggleCharacterPanel(state) {
      state.showCharacterPanel = !state.showCharacterPanel;
      if(state.showCharacterPanel == true)
      {
        state.showConfig = false;
        state.showEnvironmentPanel = false;
        state.showSlidesPanel = false;
        state.showCameraPanel = false;
        state.showRecordingPanel = false;
      }
    },
    toggleEnvironmentPanel(state) {
      state.showEnvironmentPanel = !state.showEnvironmentPanel;
      if(state.showEnvironmentPanel == true)
      {
        state.showConfig = false
        state.showCharacterPanel = false;
        state.showSlidesPanel = false;
        state.showCameraPanel = false;
        state.showRecordingPanel = false;
      }
    },
    toggleSlidesPanel(state) {
      state.showSlidesPanel = !state.showSlidesPanel;
      if(state.showSlidesPanel == true)
      {
        state.showConfig = false
        state.showCharacterPanel = false;
        state.showEnvironmentPanel = false;
        state.showCameraPanel = false;
        state.showRecordingPanel = false;
      }
    },
    toggleRecordingPanel(state) {
      state.showRecordingPanel = !state.showRecordingPanel;
      if(state.showRecordingPanel == true)
      {
        state.showConfig = false
        state.showCharacterPanel = false;
        state.showEnvironmentPanel = false;
        state.showCameraPanel = false;
        state.showSlidesPanel = false;
      }
    },
    toggleCameraPanel(state) {
      state.showCameraPanel = !state.showCameraPanel;
      if(state.showCameraPanel == true)
      {
        state.showConfig = false
        state.showCharacterPanel = false;
        state.showEnvironmentPanel = false;
        state.showSlidesPanel = false;
        state.showRecordingPanel = false;
      }
    },
    toggleEditorPanel(state)
    {
      state.showEditorPanel = !state.showEditorPanel;
    },
    collapseAll(state) {
      state.showSidenav = false;
      state.showSlidesPanel = false;
      state.showCameraPanel = false;
      state.showConfig = false
      state.showCharacterPanel = false;
      state.showEnvironmentPanel = false;
      state.showEditorPanel = false;
      state.showRecordingPanel = false;
      

    },
    navbarMinimize(state) {
      state.showSidenav = false;
      // state;
      // const sidenav_show = document.querySelector(".g-sidenav-show");
      // console.log({sidenav_show})
      // if (sidenav_show.classList.contains("g-sidenav-hidden")) {
      //   sidenav_show.classList.remove("g-sidenav-hidden");
      //   sidenav_show.classList.remove("g-sidenav-pinned");
      //   //state.isPinned = false;
        
      // } else {
      //   sidenav_show.classList.add("g-sidenav-hidden");
      //   sidenav_show.classList.add("g-sidenav-pinned");
      //   //state.isPinned = true;
      // }

    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    setLoggedIn(state, val){
      state.isLoggedIn = val;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      state;
      // if (state.isNavFixed === false) {
      //   state.isNavFixed = true;
      // } else {
      //   state.isNavFixed = false;
      // }
    },
    showNavbar(state)
    {
      state.showNavbar = false;
      state.showSidenav = true;
      //state.showEditorPanel = true;
    },
    toggleEveryDisplay(state) {
      //state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
  },
  getters: {},
});
