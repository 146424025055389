import * as THREE from 'three';

export class Camera{
    static CameraType = {
		Fixed: "Fixed",
		Slider: "Slider",
		Crane: "Crane"
    }
    
    constructor()
    {
        this.type = Camera.CameraType.Fixed;
        this.start_transform = new THREE.Matrix4();
        this.end_transform = new THREE.Matrix4();
        this.fov = 30;
        this.duration = 5;
        this.time = 0;
        this.lookAtTarget = null;
        this.camera = this.initCamera(this.fov);
        this.active = false;
        this.script = (delta)=>{this.update(delta);};
    }

    initCamera(fov)
    {
        const camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 20 );
		camera.near = 0.01;
		camera.far = 100;
		camera.setFocalLength(fov);
		camera.position.set( 0, 1, 4 );
		camera.lookAt( 0, 0, 1 );
        camera.updateProjectionMatrix()
        return camera;
    }
    setStart(m)
    {
        this.start_transform.copy(m);
        if(this.type=="Fixed")
            this.setEnd(m);
    }
    setEnd(m)
    {
        this.end_transform.copy(m);
    }
    setLookAtTarget(target)
    {
        this.lookAtTarget = target;
    }
    setActive(a)
    {
        this.active = a;
    }
    update(delta)
    {
        if(!this.active) return;
        this.time+=delta;

        if(this.time>=this.duration)
            this.time = this.duration;//this.time-this.duration;

        var filter = this.time/this.duration
        if(this.type=="Fixed")
            filter = 0;

        const start_position = new THREE.Vector3().setFromMatrixPosition(this.start_transform);
        const end_position = new THREE.Vector3().setFromMatrixPosition(this.end_transform);
        const start_rotation = new THREE.Euler().setFromRotationMatrix(this.start_transform);
        var current_position = start_position.clone();
        var current_rotation = start_rotation.clone();
        this.camera.rotation.copy(current_rotation);
        
        if(this.type=="Slider")
        {
            current_position = start_position.clone().multiplyScalar(1-filter).add(end_position.clone().multiplyScalar(filter));
            current_rotation = new THREE.Euler().setFromRotationMatrix(this.start_transform);
        }
        else if(this.type == "Crane")
        {
            let look_target = new THREE.Vector3(0,0,0);
            this.lookAtTarget?.getWorldPosition(look_target);
            let d1 = start_position.distanceTo(look_target);
            let d2 = end_position.distanceTo(look_target);

            var s = start_position.clone().sub(look_target.clone());
            var e = end_position.clone().sub(look_target.clone());
            var v = s.clone().multiplyScalar(1-filter).add(e.clone().multiplyScalar(filter));
            current_position = look_target.clone().add(v.normalize().multiplyScalar(d1*(1-filter)+d2*filter));//start_position.clone().multiplyScalar(1-filter).add(end_position.clone().multiplyScalar(filter));
            //current_rotation = new THREE.Euler().setFromRotationMatrix(this.start_transform);
            if(this.camera.look_target!=undefined)
                look_target.lerp(this.camera.look_target,(1-delta) * 0.95);
            this.camera.lookAt(look_target)
            this.camera.look_target = look_target;
        }
        this.camera.position.copy(current_position);
        
        this.camera.setFocalLength(this.fov);
    }
    serialize()
    {
        let output = {
            type: this.type,
            camera: this.camera.toJSON(),
            start_transform: this.start_transform,
            end_transform: this.end_transform,
            fov: this.fov,
            duration: this.duration
        }
        return output;
    }
    deserialize(json)
    {
        this.type = json.type;
        var loader = new THREE.ObjectLoader();
        this.camera = loader.parse( json.camera);
        this.start_transform.fromArray(json.start_transform.elements);
        this.end_transform.fromArray(json.end_transform.elements);
        this.fov = json.fov;
        this.duration = json.duration;
    }
    
}
