import * as THREE from 'three';
import * as THREE_VRM from '@pixiv/three-vrm';
import {ikRig} from '@/ikRig.js';

export class SourceSkeleton{
	static parents = [
		-1,0,1,2,0,4,5,0,7,8,9,10,9,12,13,14,9,16,17,18,3,6,
		19,22,23,24,19,26,27,28,19,30,31,32,19,34,35,36,19,38,39,40,
		15,42,43,44,15,46,47,48,15,50,51,52,15,54,55,56,15,58,59,60 
	];
	static bone_names = [
		"Hip","RHip","RKnee","RAnkle","LHip","LKnee","LAnkle","Spine","Spine1","Spine2","Neck","Head",
		"LShoulder","LArm","LForeArm","LWrist","RShoulder","RArm","RForeArm","RWrist","RToe","LToe",
		"RightThumb1","RightThumb2","RightThumb3","RightThumb4","RightIndex1","RightIndex2","RightIndex3","RightIndex4",
		"RightMiddle1","RightMiddle2","RightMiddle3","RightMiddle4","RightRing1","RightRing2","RightRing3","RightRing4",
		"RightPinky1","RightPinky2","RightPinky3","RightPinky4","LeftThumb1","LeftThumb2","LeftThumb3","LeftThumb4",
		"LeftIndex1","LeftIndex2","LeftIndex3","LeftIndex4","LeftMiddle1","LeftMiddle2","LeftMiddle3","LeftMiddle4",
		"LeftRing1","LeftRing2","LeftRing3","LeftRing4","LeftPinky1","LeftPinky2","LeftPinky3","LeftPinky4"
	];
	static getNames(){return SourceSkeleton.bone_names;}
	static bone_map = {
		Hips : 0,
		RightUpperLeg : 1,
		RightLowerLeg : 2,
		RightFoot : 3,
		LeftUpperLeg : 4,
		LeftLowerLeg : 5,
		LeftFoot : 6,
		Spine : 7,
		Chest : 8,
		UpperChest : 9,
		Neck : 10,
		Head : 11,
		LeftShoulder : 12,
		LeftUpperArm : 13,
		LeftLowerArm : 14,
		LeftHand : 15,
		RightShoulder : 16,
		RightUpperArm : 17,
		RightLowerArm : 18,
		RightHand : 19,
		RightToes : 20,
		LeftToes : 21,
		RightThumbProximal : 22,
		RightThumbIntermediate : 23,
		RightThumbDistal : 24,
		RightIndexProximal : 25,
		RightIndexIntermediate : 26,
		RightIndexDistal : 27,
		RightMiddleProximal : 28,
		RightMiddleIntermediate : 29,
		RightMiddleDistal : 30,
		RightRingProximal : 31,
		RightRingIntermediate : 32,
		RightRingDistal : 33,
		RightLittleProximal : 34,
		RightLittleIntermediate : 35,
		RightLittleDistal : 36,
		LeftThumbProximal : 37,
		LeftThumbIntermediate : 38,
		LeftThumbDistal : 39,
		LeftIndexProximal : 40,
		LeftIndexIntermediate : 41,
		LeftIndexDistal : 42,
		LeftMiddleProximal : 43,
		LeftMiddleIntermediate : 44,
		LeftMiddleDistal : 45,
		LeftRingProximal : 46,
		LeftRingIntermediate : 47,
		LeftRingDistal : 48,
		LeftLittleProximal : 49,
		LeftLittleIntermediate : 50,
		LeftLittleDistal : 51,
		None : 61
	};

	static vp_bone_map = {
		Hip : 0,
		RHip : 1,
		RKnee : 2,
		RAnkle : 3,
		LHip : 4,
		LKnee : 5,
		LAnkle : 6,
		Spine : 7,
		Spine1 : 8,
		Spine2 : 9,
		Neck : 10,
		Head : 11,
		LShoulder : 12,
		LArm : 13,
		LForeArm : 14,
		LWrist : 15,
		RShoulder : 16,
		RArm : 17,
		RForeArm : 18,
		RWrist : 19,
		RToe : 20,
		LToe : 21,
		RightThumb1 : 22,
		RightThumb2 : 23,
		RightThumb3 : 24,
		RightThumb4 : 25,
		RightIndex1 : 26,
		RightIndex2 : 27,
		RightIndex3 : 28,
		RightIndex4 : 29,
		RightMiddle1 : 30,
		RightMiddle2 : 31,
		RightMiddle3 : 32,
		RightMiddle4 : 33,
		RightRing1 : 34,
		RightRing2 : 35,
		RightRing3 : 36,
		RightRing4 : 37,
		RightPinky1 : 38,
		RightPinky2 : 39,
		RightPinky3 : 40,
		RightPinky4 : 41,
		LeftThumb1 : 42,
		LeftThumb2 : 43,
		LeftThumb3 : 44,
		LeftThumb4 : 45,
		LeftIndex1 : 46,
		LeftIndex2 : 47,
		LeftIndex3 : 48,
		LeftIndex4 : 49,
		LeftMiddle1 : 50,
		LeftMiddle2 : 51,
		LeftMiddle3 : 52,
		LeftMiddle4 : 53,
		LeftRing1 : 54,
		LeftRing2 : 55,
		LeftRing3 : 56,
		LeftRing4 : 57,
		LeftPinky1 : 58,
		LeftPinky2 : 59,
		LeftPinky3 : 60,
		LeftPinky4 : 61
        
	}
	static mirrorMap = [	
		0,4,5,6,1,2,3,7,8,9,10,11,16,17,18,19,12,13,
		14,15,21,20,42,43,44,45,46,47,48,49,50,51,52,
		53,54,55,56,57,58,59,60,61,22,23,24,25,26,27,
		28,29,30,31,32,33,34,35,36,37,38,39,40,41
	]

	
	static getBones(){
		const positions = [];
		positions.push(new THREE.Vector3(0,0.8,0));//0
		positions.push(new THREE.Vector3(-0.12,0,0));//1
		positions.push(new THREE.Vector3(-0,-0.4,0));//2
		positions.push(new THREE.Vector3(-0,-0.34,0));//3
		positions.push(new THREE.Vector3(0.12,0,0));//4
		positions.push(new THREE.Vector3(-0,-0.4,0));//5
		positions.push(new THREE.Vector3(-0,-0.34,0));//6
		positions.push(new THREE.Vector3(-0,0,0));//7
		positions.push(new THREE.Vector3(-0,0.27,0));//8
		positions.push(new THREE.Vector3(-0,0.27,0));//9
		positions.push(new THREE.Vector3(-0,0,0));//10
		positions.push(new THREE.Vector3(-0,0.22,0));//11
		positions.push(new THREE.Vector3(-0,0.0,0));//12
		positions.push(new THREE.Vector3(0.17,0,0));//13
		positions.push(new THREE.Vector3(0.28,0,0));//14
		positions.push(new THREE.Vector3(0.25,0,0));//15
		positions.push(new THREE.Vector3(-0,0,0));//16
		positions.push(new THREE.Vector3(-0.17,0,0));//17
		positions.push(new THREE.Vector3(-0.28,0,0));//18
		positions.push(new THREE.Vector3(-0.25,0,0));//19
		positions.push(new THREE.Vector3(-0,0,0.15));//20
		positions.push(new THREE.Vector3(-0,0,0.15));//21
	
		positions.push(new THREE.Vector3(-0.7405, -0.3218, 0.5900).multiplyScalar(0.06));
		positions.push(new THREE.Vector3(-0.7746, -0.4472, 0.4472).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(-0.7746, -0.4472, 0.4472).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(-0.7746, -0.4472, 0.4472).multiplyScalar(0.02));
	
		positions.push(new THREE.Vector3(-0.9662, -0.0392, 0.2548).multiplyScalar(0.06));
		positions.push(new THREE.Vector3(-1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(-1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(-1.000, -0.0000, 0.0000).multiplyScalar(0.02));
	
		positions.push(new THREE.Vector3(-1.0000, -0.0000, 0.0000).multiplyScalar(0.06));
		positions.push(new THREE.Vector3(-1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(-1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(-1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
	
		positions.push(new THREE.Vector3(-0.9774, -0.0189, -0.2106).multiplyScalar(0.06));
		positions.push(new THREE.Vector3(-1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(-1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(-1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
	
		positions.push(new THREE.Vector3(-0.8965, -0.0368, -0.4415).multiplyScalar(0.06));
		positions.push(new THREE.Vector3(-1.0000, -0.0000, -0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(-1.0000, -0.0000, -0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(-1.0000, -0.0000, -0.0000).multiplyScalar(0.02));
	
		positions.push(new THREE.Vector3(0.7405, -0.3218, 0.5900).multiplyScalar(0.06));
		positions.push(new THREE.Vector3(0.7746, -0.4472, 0.4472).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(0.7746, -0.4472, 0.4472).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(0.7746, -0.4472, 0.4472).multiplyScalar(0.02));
	
		positions.push(new THREE.Vector3(0.9662, -0.0392, 0.2548).multiplyScalar(0.06));
		positions.push(new THREE.Vector3(1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
	
		positions.push(new THREE.Vector3(1.0000, -0.0000, 0.0000).multiplyScalar(0.06));
		positions.push(new THREE.Vector3(1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
	
		positions.push(new THREE.Vector3(0.9774, -0.0189, -0.2106).multiplyScalar(0.06));
		positions.push(new THREE.Vector3(1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(1.0000, -0.0000, 0.0000).multiplyScalar(0.02));
	
		positions.push(new THREE.Vector3(0.8965, -0.0368, -0.4415).multiplyScalar(0.06));
		positions.push(new THREE.Vector3(1.0000, -0.0000, -0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(1.0000, -0.0000, -0.0000).multiplyScalar(0.02));
		positions.push(new THREE.Vector3(1.0000, -0.0000, -0.0000).multiplyScalar(0.02));
	
		// const names = Object.keys(SourceSkeleton.bone_map);
		const bones = [];
		for(let i=0; i<positions.length; i++){
			const bone = new THREE.Bone();//quaternion = (0, 0, 0, 1)

			bone.position.copy(positions[i]);
			bones.push(bone);
			if(SourceSkeleton.parents[i]!=-1)
				bones[SourceSkeleton.parents[i]].add(bone);
		}
		return bones;
	}
}

export class GeneralSkeleton{
	static bone_map = {
		Hips: "Hips",
		RightUpperLeg: "RightUpperLeg",
		RightLowerLeg: "RightLowerLeg",
		RightFoot: "RightFoot",
		LeftUpperLeg: "LeftUpperLeg",
		LeftLowerLeg: "LeftLowerLeg",
		LeftFoot: "LeftFoot",
		Spine: "Spine",
		Chest: "Spine1",
		UpperChest: "Spine3",
		Neck: "Neck",
		Head: "Head",
		LeftShoulder: "LeftShoulder",
		LeftUpperArm: "LeftUpperArm",
		LeftLowerArm: "LeftLowerArm",
		LeftHand: "LeftHand",
		RightShoulder: "RightShoulder",
		RightUpperArm: "RightUpperArm",
		RightLowerArm: "RightLowerArm",
		RightHand: "RightHand",
		RightToes: "RightToe",
		LeftToes: "LeftToe",
		RightThumbProximal: "RightThumb1",
		RightThumbIntermediate: "RightThumb2",
		RightThumbDistal: "RightThumb3",
		RightIndexProximal: "RightIndex1",
		RightIndexIntermediate: "RightIndex2",
		RightIndexDistal: "RightIndex3",
		RightMiddleProximal: "RightMiddle1",
		RightMiddleIntermediate: "RightMiddle2",
		RightMiddleDistal: "RightMiddle3",
		RightRingProximal: "RightRing1",
		RightRingIntermediate: "RightRing2",
		RightRingDistal: "RightRing3",
		RightLittleProximal: "RightPinky1",
		RightLittleIntermediate: "RightPinky2",
		RightLittleDistal: "RightPinky3",
		LeftThumbProximal: "LeftThumb1",
		LeftThumbIntermediate: "LeftThumb2",
		LeftThumbDistal: "LeftThumb3",
		LeftIndexProximal: "LeftIndex1",
		LeftIndexIntermediate: "LeftIndex2",
		LeftIndexDistal: "LeftIndex3",
		LeftMiddleProximal: "LeftMiddle1",
		LeftMiddleIntermediate: "LeftMiddle2",
		LeftMiddleDistal: "LeftMiddle3",
		LeftRingProximal: "LeftRing1",
		LeftRingIntermediate: "LeftRing2",
		LeftRingDistal: "LeftRing3",
		LeftLittleProximal: "LeftPinky1",
		LeftLittleIntermediate: "LeftPinky2",
		LeftLittleDistal: "LeftPinky3"
	};
	static getBonesForMap(object, jdata, map){
		let bones = [];
		for(let i=0; i<map.length; i++)
		{
			var found = jdata.SkeletonLink.filter(function(item) { return item.Label === map[i][1]; });

			let name = "";
			if(found.length>0)
				name = found[0].data;
			else//found.length==0
				name = map[i][1];
			if(name!="")
			{
				let child = object.getObjectByName( name );
				if(child!=undefined && found.length>0 && found[0].rotation!=undefined )
				{
					child.setRotationFromQuaternion(new THREE.Quaternion(found[0].rotation.x,-found[0].rotation.y,-found[0].rotation.z,found[0].rotation.w));
				}
				bones.push(child);
			}
			else
				bones.push(undefined);
		}
		return bones;
	}
	static getBones(object, jdata){
		return GeneralSkeleton.getBonesForMap(object, jdata, Object.entries(GeneralSkeleton.bone_map));
	}
}

export class Wolf3DSkeleton{
	static bone_map = {
		Hips: "Hips",
		RightUpperLeg: "RightUpperLeg",
		RightLowerLeg: "RightLowerLeg",
		RightFoot: "RightFoot",
		LeftUpperLeg: "LeftUpperLeg",
		LeftLowerLeg: "LeftLowerLeg",
		LeftFoot: "LeftFoot",
		Spine: "Spine",
		Chest: "Spine1",
		UpperChest: "Spine3",
		Neck: "Neck",
		Head: "Head",
		LeftShoulder: "LeftShoulder",
		LeftUpperArm: "LeftUpperArm",
		LeftLowerArm: "LeftLowerArm",
		LeftHand: "LeftHand",
		RightShoulder: "RightShoulder",
		RightUpperArm: "RightUpperArm",
		RightLowerArm: "RightLowerArm",
		RightHand: "RightHand",
		RightToes: "RightToe",
		LeftToes: "LeftToe",
		RightThumbProximal : 		"RightHandThumb1",
		RightThumbIntermediate : 	"RightHandThumb2",
		RightThumbDistal : 			"RightHandThumb3",
		RightIndexProximal : 		"RightHandIndex1",
		RightIndexIntermediate : 	"RightHandIndex2",
		RightIndexDistal : 			"RightHandIndex3",
		RightMiddleProximal : 		"RightHandMiddle1",
		RightMiddleIntermediate : 	"RightHandMiddle2",
		RightMiddleDistal : 		"RightHandMiddle3",
		RightRingProximal : 		"RightHandRing1",
		RightRingIntermediate : 	"RightHandRing2",
		RightRingDistal : 			"RightHandRing3",
		RightLittleProximal : 		"RightHandPinky1",
		RightLittleIntermediate : 	"RightHandPinky2",
		RightLittleDistal : 		"RightHandPinky3",
		LeftThumbProximal : 		"LeftHandThumb1",
		LeftThumbIntermediate : 	"LeftHandThumb2",
		LeftThumbDistal : 			"LeftHandThumb3",
		LeftIndexProximal : 		"LeftHandIndex1",
		LeftIndexIntermediate : 	"LeftHandIndex2",
		LeftIndexDistal : 			"LeftHandIndex3",
		LeftMiddleProximal : 		"LeftHandMiddle1",
		LeftMiddleIntermediate : 	"LeftHandMiddle2",
		LeftMiddleDistal : 			"LeftHandMiddle3",
		LeftRingProximal : 			"LeftHandRing1",
		LeftRingIntermediate : 		"LeftHandRing2",
		LeftRingDistal : 			"LeftHandRing3",
		LeftLittleProximal : 		"LeftHandPinky1",
		LeftLittleIntermediate : 	"LeftHandPinky2",
		LeftLittleDistal : 			"LeftHandPinky3"
	};
	static getBones(object, jdata){
		return GeneralSkeleton.getBonesForMap(object, jdata, Object.entries(Wolf3DSkeleton.bone_map));
	}
}

export class VRMSkeleton{
	static morph_map = {
		"aa":1,
		"ee":2,
		"ih":3,
		"oh":4,
		"ou":5,
		"blink":12,
		"happy":13,
		"sad":14,
		"angry":15,
		"Suprise":16,
	};
	static bone_map = {
		[THREE_VRM.VRMHumanBoneName.Hips                   ]: 0 ,
		[THREE_VRM.VRMHumanBoneName.RightUpperLeg          ]: 1 ,
		[THREE_VRM.VRMHumanBoneName.RightLowerLeg          ]: 2 ,
		[THREE_VRM.VRMHumanBoneName.RightFoot              ]: 3 ,
		[THREE_VRM.VRMHumanBoneName.LeftUpperLeg           ]: 4 ,
		[THREE_VRM.VRMHumanBoneName.LeftLowerLeg           ]: 5 ,
		[THREE_VRM.VRMHumanBoneName.LeftFoot               ]: 6 ,
		[THREE_VRM.VRMHumanBoneName.Spine                  ]: 7 ,
		[THREE_VRM.VRMHumanBoneName.Chest                  ]: 8 ,
		[THREE_VRM.VRMHumanBoneName.UpperChest             ]: 9 ,
		[THREE_VRM.VRMHumanBoneName.Neck                   ]: 10,
		[THREE_VRM.VRMHumanBoneName.Head                   ]: 11,
		[THREE_VRM.VRMHumanBoneName.LeftShoulder           ]: 12,
		[THREE_VRM.VRMHumanBoneName.LeftUpperArm           ]: 13,
		[THREE_VRM.VRMHumanBoneName.LeftLowerArm           ]: 14,
		[THREE_VRM.VRMHumanBoneName.LeftHand               ]: 15,
		[THREE_VRM.VRMHumanBoneName.RightShoulder          ]: 16,
		[THREE_VRM.VRMHumanBoneName.RightUpperArm          ]: 17,
		[THREE_VRM.VRMHumanBoneName.RightLowerArm          ]: 18,
		[THREE_VRM.VRMHumanBoneName.RightHand              ]: 19,
		[THREE_VRM.VRMHumanBoneName.RightToes              ]: 20,
		[THREE_VRM.VRMHumanBoneName.LeftToes               ]: 21,
		[THREE_VRM.VRMHumanBoneName.RightThumbProximal     ]: 22,
		[THREE_VRM.VRMHumanBoneName.RightThumbMetacarpal   ]: 23,
		[THREE_VRM.VRMHumanBoneName.RightThumbDistal       ]: 24,
		[THREE_VRM.VRMHumanBoneName.RightIndexProximal     ]: 25,
		[THREE_VRM.VRMHumanBoneName.RightIndexIntermediate ]: 26,
		[THREE_VRM.VRMHumanBoneName.RightIndexDistal       ]: 27,
		[THREE_VRM.VRMHumanBoneName.RightMiddleProximal    ]: 28,
		[THREE_VRM.VRMHumanBoneName.RightMiddleIntermediate]: 29,
		[THREE_VRM.VRMHumanBoneName.RightMiddleDistal      ]: 30,
		[THREE_VRM.VRMHumanBoneName.RightRingProximal      ]: 31,
		[THREE_VRM.VRMHumanBoneName.RightRingIntermediate  ]: 32,
		[THREE_VRM.VRMHumanBoneName.RightRingDistal        ]: 33,
		[THREE_VRM.VRMHumanBoneName.RightLittleProximal    ]: 34,
		[THREE_VRM.VRMHumanBoneName.RightLittleIntermediate]: 35,
		[THREE_VRM.VRMHumanBoneName.RightLittleDistal      ]: 36,
		[THREE_VRM.VRMHumanBoneName.LeftThumbProximal      ]: 37,
		[THREE_VRM.VRMHumanBoneName.LeftThumbMetacarpal    ]: 38,
		[THREE_VRM.VRMHumanBoneName.LeftThumbDistal        ]: 39,
		[THREE_VRM.VRMHumanBoneName.LeftIndexProximal      ]: 40,
		[THREE_VRM.VRMHumanBoneName.LeftIndexIntermediate  ]: 41,
		[THREE_VRM.VRMHumanBoneName.LeftIndexDistal        ]: 42,
		[THREE_VRM.VRMHumanBoneName.LeftMiddleProximal     ]: 43,
		[THREE_VRM.VRMHumanBoneName.LeftMiddleIntermediate ]: 44,
		[THREE_VRM.VRMHumanBoneName.LeftMiddleDistal       ]: 45,
		[THREE_VRM.VRMHumanBoneName.LeftRingProximal       ]: 46,
		[THREE_VRM.VRMHumanBoneName.LeftRingIntermediate   ]: 47,
		[THREE_VRM.VRMHumanBoneName.LeftRingDistal         ]: 48,
		[THREE_VRM.VRMHumanBoneName.LeftLittleProximal     ]: 49,
		[THREE_VRM.VRMHumanBoneName.LeftLittleIntermediate ]: 50,
		[THREE_VRM.VRMHumanBoneName.LeftLittleDistal       ]: 51
	};

	static getBones(vrm){
		const ret = []
		for(let [map, to] of Object.entries(VRMSkeleton.bone_map))
			ret[to] = vrm.humanoid.getNormalizedBoneNode(map);
		return ret;
	}
	static getRawBones(vrm){
		const ret = []
		for(let [map, to] of Object.entries(VRMSkeleton.bone_map))
			ret[to] = vrm.humanoid.getRawBoneNode(map);
		return ret;
	}
}

export class Retargeter{
	/*
	bones:
		array of bones from target character

	Maybe replace with a Skeleton class or something
	*/
	constructor(bones){
		this.source_bones = SourceSkeleton.getBones();
		this.ikRig = ikRig;
		this.chains = [];
		this.ikRig_solved = [];
		this.ikRig_poles = [];
		if(bones!==undefined)
			this.scanBones(bones);
	}
	scanBones(bones){
		[
			this.target_root, 
			this.target_bones, 
			this.tpose_rotations, 
			this.target_hip_height,
			this.scene
		] = this._scanBones(bones);
	}
	
	_scanBones(bones){
		const target_bones = [
			bones[SourceSkeleton.bone_map.Hips],
			bones[SourceSkeleton.bone_map.RightUpperLeg],
			bones[SourceSkeleton.bone_map.RightLowerLeg],
			bones[SourceSkeleton.bone_map.RightFoot],
			bones[SourceSkeleton.bone_map.LeftUpperLeg],
			bones[SourceSkeleton.bone_map.LeftLowerLeg],
			bones[SourceSkeleton.bone_map.LeftFoot],
			bones[SourceSkeleton.bone_map.Spine],
			bones[SourceSkeleton.bone_map.Chest],
			bones[SourceSkeleton.bone_map.UpperChest],
			bones[SourceSkeleton.bone_map.Neck],
			bones[SourceSkeleton.bone_map.Head],
			bones[SourceSkeleton.bone_map.LeftShoulder],
			bones[SourceSkeleton.bone_map.LeftUpperArm],
			bones[SourceSkeleton.bone_map.LeftLowerArm],
			bones[SourceSkeleton.bone_map.LeftHand],
			bones[SourceSkeleton.bone_map.RightShoulder],
			bones[SourceSkeleton.bone_map.RightUpperArm],
			bones[SourceSkeleton.bone_map.RightLowerArm],
			bones[SourceSkeleton.bone_map.RightHand],
			bones[SourceSkeleton.bone_map.RightToes],
			bones[SourceSkeleton.bone_map.LeftToes],
			bones[SourceSkeleton.bone_map.RightThumbProximal],
			bones[SourceSkeleton.bone_map.RightThumbIntermediate],
			bones[SourceSkeleton.bone_map.RightThumbDistal],
			undefined,
			bones[SourceSkeleton.bone_map.RightIndexProximal],
			bones[SourceSkeleton.bone_map.RightIndexIntermediate],
			bones[SourceSkeleton.bone_map.RightIndexDistal],
			undefined,
			bones[SourceSkeleton.bone_map.RightMiddleProximal],
			bones[SourceSkeleton.bone_map.RightMiddleIntermediate],
			bones[SourceSkeleton.bone_map.RightMiddleDistal],
			undefined,
			bones[SourceSkeleton.bone_map.RightRingProximal],
			bones[SourceSkeleton.bone_map.RightRingIntermediate],
			bones[SourceSkeleton.bone_map.RightRingDistal],
			undefined,
			bones[SourceSkeleton.bone_map.RightLittleProximal],
			bones[SourceSkeleton.bone_map.RightLittleIntermediate],
			bones[SourceSkeleton.bone_map.RightLittleDistal],
			undefined,
			bones[SourceSkeleton.bone_map.LeftThumbProximal],
			bones[SourceSkeleton.bone_map.LeftThumbIntermediate],
			bones[SourceSkeleton.bone_map.LeftThumbDistal],
			undefined,
			bones[SourceSkeleton.bone_map.LeftIndexProximal],
			bones[SourceSkeleton.bone_map.LeftIndexIntermediate],
			bones[SourceSkeleton.bone_map.LeftIndexDistal],
			undefined,
			bones[SourceSkeleton.bone_map.LeftMiddleProximal],
			bones[SourceSkeleton.bone_map.LeftMiddleIntermediate],
			bones[SourceSkeleton.bone_map.LeftMiddleDistal],
			undefined,
			bones[SourceSkeleton.bone_map.LeftRingProximal],
			bones[SourceSkeleton.bone_map.LeftRingIntermediate],
			bones[SourceSkeleton.bone_map.LeftRingDistal],
			undefined,
			bones[SourceSkeleton.bone_map.LeftLittleProximal],
			bones[SourceSkeleton.bone_map.LeftLittleIntermediate],
			bones[SourceSkeleton.bone_map.LeftLittleDistal],
			undefined,
		];

		let target_root = target_bones[0];
		let scene = null;
		let parent = target_bones[0].parent;
		while (parent !== null) {
			if (parent instanceof THREE.Scene) {
				scene = parent;
				break;
			}
			parent = parent.parent;
		}

		while(target_root.parent && target_root.parent.type!="Scene")
			target_root=target_root.parent;

		const root_rotation_invert=target_root.getWorldQuaternion(new THREE.Quaternion()).clone().invert();
		const tpose_rotations = target_bones.map((bone)=>{
			return bone ? 
				root_rotation_invert.clone().multiply(bone.getWorldQuaternion(new THREE.Quaternion())) : 
				new THREE.Quaternion();
		});
		//const scale = target_root.getWorldScale(new THREE.Vector3());
		const target_hip_height = bones[0].getWorldPosition(new THREE.Vector3()).y;//this.getHipHeight(target_bones, false);///scale.y/target_root.scale.y;
		return [target_root, target_bones, tpose_rotations, target_hip_height, scene];
	}

	getHipHeight(bones, manual=true){
		const Root = bones[0].getWorldPosition(new THREE.Vector3());
		const LeftUpperLeg = bones[1].getWorldPosition(new THREE.Vector3());
		const LeftLowerLeg = bones[2].getWorldPosition(new THREE.Vector3());
		const LeftFoot = bones[3].getWorldPosition(new THREE.Vector3());
		const RightUpperLeg = bones[4].getWorldPosition(new THREE.Vector3());
		const HipMid = RightUpperLeg.clone().add(LeftUpperLeg).multiplyScalar(0.5);
		const foot = manual ? 0.10 : LeftFoot.y;
		return LeftUpperLeg.distanceTo(LeftLowerLeg) + LeftLowerLeg.distanceTo(LeftFoot) + HipMid.distanceTo(Root) + foot;
	}

	setIKRig(ikrig)
	{
		this.ikRig = ikrig;
		//console.log(ikrig);
	}

	setRotations(rotations){
		this.ikRig.height = 0;
		for(let i=0; i<rotations.length; i++)
			this.source_bones[i]?.rotation.setFromQuaternion(rotations[i]);
	}
	setTranslation(translation, lengths){
		this.ikRig.height = 0;
		this.source_bones[0].position.copy(translation);
		for(let i=1; i<lengths.length; i++)
			this.source_bones[i].position.normalize().multiplyScalar(lengths[i-1]);
	}
	solveIKChain(direction, pole, length, root, middle, end, rotation_offset, end_rotation) {
		rotation_offset = new THREE.Quaternion(rotation_offset.x,-rotation_offset.y,-rotation_offset.z,rotation_offset.w);
		
		direction = direction.clone().applyQuaternion(this.target_root.getWorldQuaternion(new THREE.Quaternion()));
		pole = pole.clone().applyQuaternion(this.target_root.getWorldQuaternion(new THREE.Quaternion()));
		const target_chain_root = this.target_bones[root].getWorldPosition(new THREE.Vector3());
		const target_chain_mid = this.target_bones[middle].getWorldPosition(new THREE.Vector3());
		const target_chain_end = this.target_bones[end].getWorldPosition(new THREE.Vector3());
		const src_chain_len = direction.length();
		const target_chain_len = target_chain_root.distanceTo(target_chain_mid) + target_chain_mid.distanceTo(target_chain_end);
		const chain_ratio = src_chain_len / length;
		const dir = direction.clone().normalize();
		let end_effector = target_chain_root.clone().add(dir.clone().multiplyScalar(target_chain_len * chain_ratio));

		//hand contact correction
		if (end == SourceSkeleton.bone_map.LeftHand || end == SourceSkeleton.bone_map.RightHand)
		{
			const hand_ratio = pole.length();
			let mirror_chain_start = this.target_bones[SourceSkeleton.mirrorMap[root]].getWorldPosition(new THREE.Vector3());
			const target_mirror_chain_len = this.target_bones[SourceSkeleton.mirrorMap[root]].getWorldPosition(new THREE.Vector3()).distanceTo(this.target_bones[SourceSkeleton.mirrorMap[middle]].getWorldPosition(new THREE.Vector3())) +
											this.target_bones[SourceSkeleton.mirrorMap[middle]].getWorldPosition(new THREE.Vector3()).distanceTo(this.target_bones[SourceSkeleton.mirrorMap[end]].getWorldPosition(new THREE.Vector3()));
			let end_effector_mirror = new THREE.Vector3();
			let src_end_effector_mirror = new THREE.Vector3();
			if (end == SourceSkeleton.bone_map.LeftHand)
			{
				
				const rchain_ratio = this.ikRig.r_arm_direction.clone().applyQuaternion(this.target_root.getWorldQuaternion(new THREE.Quaternion())).length() / this.ikRig.r_arm_len;
				end_effector_mirror = mirror_chain_start.clone().add(this.ikRig.r_arm_direction.clone().applyQuaternion(this.target_root.getWorldQuaternion(new THREE.Quaternion())).normalize().multiplyScalar(target_mirror_chain_len * rchain_ratio));
				src_end_effector_mirror = this.ikRig.r_arm_direction.clone().applyQuaternion(this.target_root.getWorldQuaternion(new THREE.Quaternion()));
			}
			else if (end == SourceSkeleton.bone_map.RightHand)
			{
				
				const lchain_ratio = this.ikRig.l_arm_direction.clone().applyQuaternion(this.target_root.getWorldQuaternion(new THREE.Quaternion())).length() / this.ikRig.l_arm_len;
				end_effector_mirror = mirror_chain_start.clone().add(this.ikRig.l_arm_direction.clone().applyQuaternion(this.target_root.getWorldQuaternion(new THREE.Quaternion())).normalize().multiplyScalar( target_mirror_chain_len * lchain_ratio));
				src_end_effector_mirror = this.ikRig.l_arm_direction.clone().applyQuaternion(this.target_root.getWorldQuaternion(new THREE.Quaternion()));
			}
			
			const target_armlen = target_chain_len + target_mirror_chain_len +this.target_bones[SourceSkeleton.mirrorMap[root]].getWorldPosition(new THREE.Vector3()).distanceTo(this.target_bones[root].getWorldPosition(new THREE.Vector3()));
			const desired_target_hand_distance = target_armlen * hand_ratio;
			const current_target_hand_distance = end_effector.distanceTo(end_effector_mirror);
			const hand_error = desired_target_hand_distance - current_target_hand_distance;
			end_effector = end_effector.clone().add( src_end_effector_mirror.clone().sub(direction).normalize().multiplyScalar( hand_error * 0.5));

		}


		const chain_dir_l = pole.clone().normalize();
		const hip_l_len = target_chain_root.distanceTo(target_chain_mid);
		const ankle_l_len = target_chain_mid.distanceTo(target_chain_end);
		
		
		this.chains.push(target_chain_root);
		this.chains.push(end_effector);
		const result = this.solve2Bones(target_chain_root, end_effector, target_chain_root.clone().add(end_effector).multiplyScalar(0.5).add(chain_dir_l.clone()), hip_l_len, ankle_l_len);
		let lHip_rot = result.upper;
		let lAnkle_rot = result.lower;

		// const points = [];
		this.ikRig_solved.push(target_chain_root);
		this.ikRig_solved.push(target_chain_root.clone().add(new THREE.Vector3( 0, 0, hip_l_len ).applyQuaternion(lHip_rot.clone())));
		this.ikRig_solved.push(target_chain_root.clone().add(new THREE.Vector3( 0, 0, hip_l_len ).applyQuaternion(lHip_rot.clone())));
		this.ikRig_solved.push(end_effector);
		this.ikRig_poles.push(target_chain_root.clone().add(end_effector).multiplyScalar(0.5));
		this.ikRig_poles.push(target_chain_root.clone().add(end_effector).multiplyScalar(0.5).add(chain_dir_l.clone().multiplyScalar(target_chain_len*0.3)));
		// const geometry = new THREE.BufferGeometry().setFromPoints( points );
		// const material = new THREE.LineBasicMaterial( { color: 0x0000ff,depthTest: false, depthWrite: false } );
		// const line = new THREE.Line( geometry, material );
		// this.scene.add(line);



		// let root_rotation_invert = this.target_root.getWorldQuaternion(new THREE.Quaternion()).invert();
		// let parent_global_rotation=root_rotation_invert.clone().multiply(
		// 	this.target_bones[root]?.parent?.getWorldQuaternion(new THREE.Quaternion())
		// );
		// let q = lHip_rot.multiply(rotation_offset.clone()).multiply(this.tpose_rotations[root]);
		// let q2 = parent_global_rotation.clone().invert().multiply(q);
		// this.target_bones[root]?.rotation.setFromQuaternion(q2);

		// parent_global_rotation=root_rotation_invert.clone().multiply(
		// 	this.target_bones[middle]?.parent?.getWorldQuaternion(new THREE.Quaternion())
		// );
		// q = lAnkle_rot.clone().multiply(rotation_offset.clone()).multiply(this.tpose_rotations[middle]);
		// q2 = parent_global_rotation.clone().invert().multiply(q);
		// this.target_bones[middle]?.rotation.setFromQuaternion(q2);

		const root_rotation_invert = new THREE.Quaternion();//this.target_root.getWorldQuaternion(new THREE.Quaternion()).invert();
		let parent_global_rotation=root_rotation_invert.clone().multiply(
			this.target_bones[root]?.parent?.getWorldQuaternion(new THREE.Quaternion())
		);
		let q = lHip_rot.clone().multiply(rotation_offset.clone()).multiply(this.tpose_rotations[root]);
		let q2 = parent_global_rotation.clone().invert().multiply(q);
		this.target_bones[root]?.rotation.setFromQuaternion(q2);

		parent_global_rotation=root_rotation_invert.clone().multiply(
			this.target_bones[middle]?.parent?.getWorldQuaternion(new THREE.Quaternion())
		);
		q = lAnkle_rot.clone().multiply(rotation_offset.clone()).multiply(this.tpose_rotations[middle]);
		q2 = parent_global_rotation.clone().invert().multiply(q);
		this.target_bones[middle]?.rotation.setFromQuaternion(q2);

		//propagate 50% of wrist twist to forearm
		if (root == SourceSkeleton.bone_map.LeftUpperArm || root == SourceSkeleton.bone_map.RightUpperArm)
		{
			// let sign = 1;
			// if (root == SourceSkeleton.bone_map.LeftUpperArm )
			// 	sign *= -1;
			// let wrist = this.target_root.getWorldQuaternion(new THREE.Quaternion()).multiply(end_rotation);
			// //let foot = this.target_bones[end].getWorldPosition(new THREE.Vector3());
			// //let knee = foot.clone().sub((new THREE.Vector3(0,0.5,0).applyQuaternion(wrist)));
			// let twist = new THREE.Vector3(0,1,0).applyQuaternion(wrist.clone());
			// twist = twist.clone().cross(new THREE.Vector3(0,0,-1).applyQuaternion(lAnkle_rot.clone())).normalize();
			// //knee = foot.clone().sub(twist.multiplyScalar( 0.5));
			// lAnkle_rot =  lAnkle_rot.clone().slerp(this.lookRotation(new THREE.Vector3(0,0,-1).applyQuaternion(lAnkle_rot.clone()), twist.clone().multiplyScalar(sign)), 0.5);

		}
		// parent_global_rotation=root_rotation_invert.clone().multiply(
		// 	this.target_bones[middle]?.parent?.getWorldQuaternion(new THREE.Quaternion())
		// );
		// q = lAnkle_rot.clone().multiply(rotation_offset.clone()).multiply(this.tpose_rotations[middle]);
		// q2 = parent_global_rotation.clone().invert().multiply(q);
		// this.target_bones[middle]?.rotation.setFromQuaternion(q2);
		
		// parent_global_rotation=root_rotation_invert.clone().multiply(
		// 	this.target_bones[end]?.parent?.getWorldQuaternion(new THREE.Quaternion())
		// );
		// let qrot = end_rotation.clone().multiply(this.tpose_rotations[end].clone());
		// q2 = parent_global_rotation.clone().invert().multiply(qrot);
		// this.target_bones[end]?.rotation.setFromQuaternion(q2);
		parent_global_rotation=root_rotation_invert.clone().multiply(
			this.target_bones[middle]?.parent?.getWorldQuaternion(new THREE.Quaternion())
		);
		q = lAnkle_rot.clone().multiply(rotation_offset.clone()).multiply(this.tpose_rotations[middle]);
		q2 = parent_global_rotation.clone().invert().multiply(q);
		this.target_bones[middle]?.rotation.setFromQuaternion(q2);

		parent_global_rotation=this.target_root.getWorldQuaternion(new THREE.Quaternion()).invert().clone().multiply(
			this.target_bones[end]?.parent?.getWorldQuaternion(new THREE.Quaternion())
		);
		q = end_rotation.clone().multiply(this.tpose_rotations[end]);
		q2 = parent_global_rotation.clone().invert().multiply(q);
		this.target_bones[end]?.rotation.setFromQuaternion(q2);
		
	
	
	}
	solveFingerIKChain(direction, pole, length, root, middle, end, rotation_offset) 
	{
		direction = direction.clone().applyQuaternion(this.target_root.getWorldQuaternion(new THREE.Quaternion()));
		pole = pole.clone().applyQuaternion(this.target_root.getWorldQuaternion(new THREE.Quaternion()));

		let bone_chain = [root, middle, end];
		bone_chain = bone_chain.filter(item => this.target_bones[item] !== undefined);
		if(bone_chain.length==0) return;
		if(bone_chain.length==1)
		{
			let rot = new THREE.Quaternion();
			rot.setFromRotationMatrix(new THREE.Matrix4().lookAt(direction.clone().normalize(), new THREE.Vector3(), pole));
			const qrot = rot.clone().multiply(rotation_offset).multiply(this.tpose_rotations[bone_chain[0]]);
			let root_rotation_invert = new THREE.Quaternion();//this.target_root.getWorldQuaternion(new THREE.Quaternion()).invert();
			let parent_global_rotation=root_rotation_invert.clone().multiply(
				this.target_bones[bone_chain[0]]?.parent?.getWorldQuaternion(new THREE.Quaternion())
			);
			let q2 = parent_global_rotation.clone().invert().multiply(qrot);
			this.target_bones[bone_chain[0]]?.rotation.setFromQuaternion(q2);
			return;
		}
		let target_chain_len = 0;
		let sections = []
		let finger_tip = 1;
		if(middle == SourceSkeleton.bone_map.Chest || middle == SourceSkeleton.bone_map.Neck)
			finger_tip = 0;
		for(let i=1; i < bone_chain.length+finger_tip; i++)
		{
		
			if(i==bone_chain.length)
				target_chain_len+=this.target_bones[bone_chain[i-2]].getWorldPosition(new THREE.Vector3()).distanceTo(this.target_bones[bone_chain[i-1]].getWorldPosition(new THREE.Vector3()));
			else
				target_chain_len+=this.target_bones[bone_chain[i-1]].getWorldPosition(new THREE.Vector3()).distanceTo(this.target_bones[bone_chain[i]].getWorldPosition(new THREE.Vector3()));
			sections.push(target_chain_len);
		}

		const target_chain_root = this.target_bones[bone_chain[0]].getWorldPosition(new THREE.Vector3());
		//const target_chain_mid = this.target_bones[middle].getWorldPosition(new THREE.Vector3());
		//const target_chain_end = this.target_bones[end].getWorldPosition(new THREE.Vector3());


		const src_chain_len = direction.length();
		// const target_chain_len =
		// 	target_chain_root.distanceTo(target_chain_mid) +
		// 	target_chain_mid.distanceTo(target_chain_end) +
		// 	target_chain_mid.distanceTo(target_chain_end);
		const chain_ratio = src_chain_len / length;
		const dir = direction.clone().normalize();
		const end_effector = target_chain_root.clone().add(dir.clone().multiplyScalar(target_chain_len * chain_ratio));
		
		//const root_rot = new THREE.Quaternion();
		//const mid_rot = new THREE.Quaternion();
		//const end_rot = new THREE.Quaternion();
		this.chains.push(target_chain_root);
		this.chains.push(end_effector);
		const s = 0.43 * Math.sqrt(Math.abs(target_chain_len * target_chain_len - end_effector.distanceToSquared(target_chain_root)));

		this.ikRig_poles.push(target_chain_root.clone().add(end_effector).multiplyScalar(0.5));
		this.ikRig_poles.push(target_chain_root.clone().add(end_effector).multiplyScalar(0.5).add(pole.clone().normalize().multiplyScalar(0.3*target_chain_len)));

		//const root_end = this.sampleParabola(target_chain_root, end_effector, s, this.target_bones[middle].position.length() / target_chain_len, pole);
		//const mid_end = this.sampleParabola(target_chain_root, end_effector, s, (this.target_bones[middle].position.length() + this.target_bones[end].position.length()) / target_chain_len, pole);
		//const end_end = this.sampleParabola(target_chain_root, end_effector, s, (this.target_bones[middle].position.length() + this.target_bones[end].position.length() * 2) / target_chain_len, pole);
		for(let i=0; i < sections.length; i++)
		{
			sections[i] = this.sampleParabola(target_chain_root, end_effector, s, sections[i] / target_chain_len, pole);
			if(i==0)
			{
				const root_rot = new THREE.Quaternion();
				root_rot.setFromRotationMatrix(new THREE.Matrix4().lookAt(sections[i], target_chain_root, pole));
				this.ikRig_solved.push( target_chain_root);
				this.ikRig_solved.push( sections[i]);
				const qrot = root_rot.clone().multiply(rotation_offset).multiply(this.tpose_rotations[bone_chain[i]]);
				let root_rotation_invert = new THREE.Quaternion();//this.target_root.getWorldQuaternion(new THREE.Quaternion()).invert();
				let parent_global_rotation=root_rotation_invert.clone().multiply(
					this.target_bones[bone_chain[i]]?.parent?.getWorldQuaternion(new THREE.Quaternion())
				);
				let q2 = parent_global_rotation.clone().invert().multiply(qrot);
				this.target_bones[bone_chain[i]]?.rotation.setFromQuaternion(q2);
			}
			else
			{
				const root_rot = new THREE.Quaternion();
				root_rot.setFromRotationMatrix(new THREE.Matrix4().lookAt(sections[i], sections[i-1], pole));
				this.ikRig_solved.push( sections[i-1]);
				this.ikRig_solved.push( sections[i]);
				const qrot = root_rot.clone().multiply(rotation_offset).multiply(this.tpose_rotations[bone_chain[i]]);
				let root_rotation_invert = new THREE.Quaternion();//this.target_root.getWorldQuaternion(new THREE.Quaternion()).invert();
				let parent_global_rotation=root_rotation_invert.clone().multiply(
					this.target_bones[bone_chain[i]]?.parent?.getWorldQuaternion(new THREE.Quaternion())
				);
				let q2 = parent_global_rotation.clone().invert().multiply(qrot);
				this.target_bones[bone_chain[i]]?.rotation.setFromQuaternion(q2);
			}
		}
		
		// const points = [];
		// points.push(target_chain_root);
		// points.push(root_end);
		// points.push(mid_end);
		// points.push(end_end);
		// points.push(end_effector);
		// const geometry = new THREE.BufferGeometry().setFromPoints( points );
		// const material = new THREE.LineBasicMaterial( { color: 0x0000ff,depthTest: false, depthWrite: false } );
		// const line = new THREE.Line( geometry, material );
		// this.scene.add(line);

		// root_rot.setFromRotationMatrix(new THREE.Matrix4().lookAt(root_end, target_chain_root, pole));
		
		// const qrot = root_rot.clone().multiply(rotation_offset).multiply(this.tpose_rotations[root]);
		// let root_rotation_invert = this.target_root.getWorldQuaternion(new THREE.Quaternion()).invert();
		// let parent_global_rotation=root_rotation_invert.clone().multiply(
		// 	this.target_bones[root]?.parent?.getWorldQuaternion(new THREE.Quaternion())
		// );
		// let q2 = parent_global_rotation.clone().invert().multiply(qrot);
		// this.target_bones[root]?.rotation.setFromQuaternion(q2);

	
		// mid_rot.setFromRotationMatrix(new THREE.Matrix4().lookAt(mid_end, root_end, pole));
		// qrot.copy(mid_rot.clone().multiply(rotation_offset)).multiply(this.tpose_rotations[middle]);
		// parent_global_rotation=root_rotation_invert.clone().multiply(
		// 	this.target_bones[middle]?.parent?.getWorldQuaternion(new THREE.Quaternion())
		// );
		// q2 = parent_global_rotation.clone().invert().multiply(qrot);
		// this.target_bones[middle]?.rotation.setFromQuaternion(q2);
	
		// end_rot.setFromRotationMatrix(new THREE.Matrix4().lookAt(end_end, mid_end, pole));
		// qrot.copy(end_rot.clone().multiply(rotation_offset)).multiply(this.tpose_rotations[end]);
		// parent_global_rotation=root_rotation_invert.clone().multiply(
		// 	this.target_bones[end]?.parent?.getWorldQuaternion(new THREE.Quaternion())
		// );
		// q2 = parent_global_rotation.clone().invert().multiply(qrot);
		// this.target_bones[end]?.rotation.setFromQuaternion(q2);
	}
	solve2Bones(root, effector, pole, hip_length, ankle_length) 
	{

		var h = 0;
        var a = hip_length;
        var d = root.distanceTo(effector);
        var condition=true;
        if (d > Math.abs(hip_length + ankle_length))
        {
            h = 0;
            condition = false;
        }
        
        if (d == 0 && hip_length == ankle_length) 
        {
            h = 0;
            condition = false;
        }
        if(condition)
        {
            a = (hip_length * hip_length - ankle_length * ankle_length + d * d) / (2 * d);
            h = Math.sqrt(Math.max(0.0, hip_length * hip_length - a * a));
        }

        var f_dir = effector.clone().sub(root);
        f_dir.normalize();
        var mid = root.clone().add(f_dir.clone().multiplyScalar(a));
        var child = this.projectPointOnPlane(f_dir.clone(), mid.clone(), pole.clone())
        child = mid.add(child.clone().sub(mid).normalize().multiplyScalar(h));
        var mat = new THREE.Matrix4();
        var dir = child.clone().sub(root).normalize();
		var vec1 = pole.clone().sub(root).normalize();
		var vec2= f_dir.clone().cross(vec1).normalize();
        var up = vec2.clone().cross(dir);
		//upper = this.lookRotation((root.clone().sub(child)).normalize(),up.clone());
        mat.lookAt(child, root, up);
        var mat2 = new THREE.Matrix4();
        dir = child.clone().sub(effector).normalize();
		//Vector3.Cross(( child- effector),(Vector3.Cross(effector - root,(pole - root).normalized)))
		vec2 = (f_dir.clone().cross(vec1)).normalize();
		vec2.crossVectors(f_dir, vec1);
        up = dir.clone().cross(vec2);
        mat2.lookAt(effector, child, up);
        const upper = new THREE.Quaternion().setFromRotationMatrix(mat);
        const lower = new THREE.Quaternion().setFromRotationMatrix(mat2);
		return { upper, lower };
	}

	lookRotation(direction, up)
	{
		var mat = new THREE.Matrix4();
		var eye = new THREE.Vector3();
        var target = direction;
        mat.lookAt(eye,target, up);
		var rotation = new THREE.Quaternion();
		rotation.setFromRotationMatrix(mat);
		return rotation;
	}

	sampleParabola(start, end, height, t, outDirection) {
		const parabolicT = t * 2 - 1;
		const travelDirection = end.clone().sub(start);
		//const levelDirection = end.clone().sub(new THREE.Vector3(start.x, end.y, start.z));
		//const right = travelDirection.clone().cross(levelDirection);
		const up = outDirection.clone();
		const result = start.clone().add(travelDirection.clone().multiplyScalar(t));
		result.add(up.clone().multiplyScalar((-parabolicT * parabolicT + 1) * height));
		return result;
	}
	
	projectPointOnPlane(planeNormal, planePoint, point) {
		var distance = 0;
        distance = planeNormal.clone().dot(point.clone().sub(planePoint).normalize());
        //Reverse the sign of the distance
        distance *= -1;
        //Get a translation vector
        planeNormal.normalize();
        var translationVector = planeNormal.multiplyScalar(distance);
        //Translate the point to form a projection
        return point.add(translationVector);
	}

	update(){
		if(this.target_bones === undefined || this.target_root === undefined)
			return;

		this.chains = [];
		this.ikRig_solved = [];
		this.ikRig_poles = [];
		if(this.ikRig.height>0)
		{
			let target_height = this.target_hip_height;
			let source_height = this.ikRig.height;
			let hip_scale = target_height / source_height;
			let target_root_scale = new THREE.Vector3().setFromMatrixScale(this.target_root.matrixWorld);
			let parent_transform_invert = this.target_bones[0].parent.matrixWorld.clone().invert();//cancel scale
			let hips_pos = this.ikRig.root_pos.clone()
				.multiplyScalar(hip_scale)
				.divide(target_root_scale)
				.applyMatrix4(this.target_root.matrixWorld)//apply root transform -> as children of root
				.applyMatrix4(parent_transform_invert)
			;

			const root_rotation_invert = this.target_root.getWorldQuaternion(new THREE.Quaternion()).invert();
			const parent_global_rotation=root_rotation_invert.clone().multiply(
				this.target_bones[0]?.parent?.getWorldQuaternion(new THREE.Quaternion())
			);
			const q = this.ikRig.root_rot.clone().multiply(this.tpose_rotations[0]);
			const q2 = parent_global_rotation.clone().invert().multiply(q);
			this.target_bones[0]?.rotation.setFromQuaternion(q2);
			this.target_bones[SourceSkeleton.bone_map.Hips].position.copy(hips_pos);
			
			this.solveIKChain(this.ikRig.l_leg_direction,this.ikRig.l_leg_pole, this.ikRig.l_leg_len, 
				SourceSkeleton.bone_map.LeftUpperLeg,SourceSkeleton.bone_map.LeftLowerLeg,SourceSkeleton.bone_map.LeftFoot,
				new THREE.Quaternion().setFromEuler(new THREE.Euler(THREE.MathUtils.degToRad(-90),0,0)), this.ikRig.l_foot_rot);
			this.solveIKChain(this.ikRig.r_leg_direction,this.ikRig.r_leg_pole, this.ikRig.r_leg_len, 
					SourceSkeleton.bone_map.RightUpperLeg,SourceSkeleton.bone_map.RightLowerLeg,SourceSkeleton.bone_map.RightFoot,
					new THREE.Quaternion().setFromEuler(new THREE.Euler(THREE.MathUtils.degToRad(-90),0,0)), this.ikRig.r_foot_rot);    
			//let chest = this.lookRotation(this.ikRig.spine_direction.clone().normalize(),this.ikRig.spine_pole.clone().negate()).multiply(new THREE.Quaternion().setFromEuler(new THREE.Euler(THREE.MathUtils.degToRad(-90),0,0)));
			
			this.solveFingerIKChain(this.ikRig.spine_direction, this.ikRig.spine_pole, this.ikRig.spine_len, 
				SourceSkeleton.bone_map.Spine, SourceSkeleton.bone_map.Chest, SourceSkeleton.bone_map.UpperChest,
				new THREE.Quaternion().setFromEuler(new THREE.Euler(THREE.MathUtils.degToRad(90),0,0)));
			// if(this.target_bones[SourceSkeleton.bone_map.Chest] != null && this.target_bones[SourceSkeleton.bone_map.UpperChest] != null)
			// {
			// 	this.solveIKChain(this.ikRig.spine_direction,this.ikRig.spine_pole, this.ikRig.spine_len, 
			// 		SourceSkeleton.bone_map.Spine, SourceSkeleton.bone_map.Chest, SourceSkeleton.bone_map.UpperChest,
			// 		new THREE.Quaternion().setFromEuler(new THREE.Euler(THREE.MathUtils.degToRad(90),0,0)), chest);
			// }
			// else
			// {
			// 	let qrot = chest.clone().premultiply(this.tpose_rotations[SourceSkeleton.bone_map.Spine].clone());
			// 	const endParentRotationInv = this.target_root.getWorldQuaternion(new THREE.Quaternion()).invert().premultiply(this.target_bones[SourceSkeleton.bone_map.Spine]?.parent?.getWorldQuaternion(new THREE.Quaternion()).invert());
			// 	qrot.premultiply(endParentRotationInv);
			// 	this.target_bones[SourceSkeleton.bone_map.Spine]?.rotation.setFromQuaternion(qrot);
			// }
			
			//let head = this.lookRotation( this.ikRig.neck_direction.clone().normalize(), this.ikRig.neck_pole.clone().negate() ).multiply(new THREE.Quaternion().setFromEuler(new THREE.Euler(THREE.MathUtils.degToRad(-90),0,0)));
      
			// this.solveIKChain(this.ikRig.neck_direction,this.ikRig.neck_pole, this.ikRig.neck_len, 
			// 	SourceSkeleton.bone_map.UpperChest, SourceSkeleton.bone_map.Neck, SourceSkeleton.bone_map.Head,
			// 	new THREE.Quaternion().setFromEuler(new THREE.Euler(THREE.MathUtils.degToRad(90),0,0)), head);

			this.solveFingerIKChain(this.ikRig.neck_direction, this.ikRig.neck_pole, this.ikRig.neck_len, 
				SourceSkeleton.bone_map.None, SourceSkeleton.bone_map.Neck, SourceSkeleton.bone_map.Head,
				new THREE.Quaternion().setFromEuler(new THREE.Euler(THREE.MathUtils.degToRad(90),0,0)));

			// //restore the rotation of the chest
			// var neck = this.target_bones[SourceSkeleton.bone_map.Neck].getWorldQuaternion(new THREE.Quaternion());
			// let qrot = chest.clone().premultiply(this.tpose_rotations[SourceSkeleton.bone_map.UpperChest].clone());
			// const endParentRotationInv = this.target_root.getWorldQuaternion(new THREE.Quaternion()).invert().premultiply(this.target_bones[SourceSkeleton.bone_map.UpperChest]?.parent?.getWorldQuaternion(new THREE.Quaternion()).invert());
			// qrot.premultiply(endParentRotationInv);
			// this.target_bones[SourceSkeleton.bone_map.UpperChest]?.rotation.setFromQuaternion(qrot);
			// const parentWorldQuaternionInverse = new THREE.Quaternion();
			// this.target_bones[SourceSkeleton.bone_map.Neck].parent.getWorldQuaternion(parentWorldQuaternionInverse).invert();
			// const localNeckQuaternion = neck.clone().premultiply(parentWorldQuaternionInverse);
			// this.target_bones[SourceSkeleton.bone_map.Neck]?.quaternion.copy(localNeckQuaternion);


			this.solveIKChain(this.ikRig.l_arm_direction,this.ikRig.l_arm_pole, this.ikRig.l_arm_len, 
						SourceSkeleton.bone_map.LeftUpperArm,SourceSkeleton.bone_map.LeftLowerArm,SourceSkeleton.bone_map.LeftHand,
						new THREE.Quaternion().setFromEuler(new THREE.Euler(THREE.MathUtils.degToRad(90),THREE.MathUtils.degToRad(0),THREE.MathUtils.degToRad(-90))), this.ikRig.l_hand_rot);

			this.solveIKChain(this.ikRig.r_arm_direction,this.ikRig.r_arm_pole, this.ikRig.r_arm_len, 
				SourceSkeleton.bone_map.RightUpperArm,SourceSkeleton.bone_map.RightLowerArm,SourceSkeleton.bone_map.RightHand,
				new THREE.Quaternion().setFromEuler(new THREE.Euler(THREE.MathUtils.degToRad(90),THREE.MathUtils.degToRad(0),THREE.MathUtils.degToRad(90))), this.ikRig.r_hand_rot);
			
			let left_finger_pole = new THREE.Vector3(0,0,-1).applyQuaternion(this.ikRig.l_hand_rot);
			let left_index_pole = this.ikRig.l_index_direction.clone().normalize().cross(left_finger_pole).normalize();
			this.solveFingerIKChain(this.ikRig.l_index_direction, left_index_pole, this.ikRig.l_index_len, 
							SourceSkeleton.vp_bone_map.LeftIndex1, SourceSkeleton.vp_bone_map.LeftIndex2, SourceSkeleton.vp_bone_map.LeftIndex3,
							new THREE.Quaternion().setFromEuler(new THREE.Euler(0,THREE.MathUtils.degToRad(-90),0)));

			let left_middle_pole = this.ikRig.l_middle_direction.clone().normalize().cross(left_finger_pole).normalize();
			this.solveFingerIKChain(this.ikRig.l_middle_direction, left_middle_pole, this.ikRig.l_middle_len, 
				SourceSkeleton.vp_bone_map.LeftMiddle1, SourceSkeleton.vp_bone_map.LeftMiddle2, SourceSkeleton.vp_bone_map.LeftMiddle3,
				new THREE.Quaternion().setFromEuler(new THREE.Euler(0,THREE.MathUtils.degToRad(-90),0)));
			let left_ring_pole = this.ikRig.l_ring_direction.clone().normalize().cross(left_finger_pole).normalize();
			this.solveFingerIKChain(this.ikRig.l_ring_direction, left_ring_pole, this.ikRig.l_ring_len, 
				SourceSkeleton.vp_bone_map.LeftRing1, SourceSkeleton.vp_bone_map.LeftRing2, SourceSkeleton.vp_bone_map.LeftRing3,
				new THREE.Quaternion().setFromEuler(new THREE.Euler(0,THREE.MathUtils.degToRad(-90),0)));
			let left_pinky_pole = this.ikRig.l_pinky_direction.clone().normalize().cross(left_finger_pole).normalize();
			this.solveFingerIKChain(this.ikRig.l_pinky_direction, left_pinky_pole, this.ikRig.l_pinky_len, 
				SourceSkeleton.vp_bone_map.LeftPinky1, SourceSkeleton.vp_bone_map.LeftPinky2, SourceSkeleton.vp_bone_map.LeftPinky3,
				new THREE.Quaternion().setFromEuler(new THREE.Euler(0,THREE.MathUtils.degToRad(-90),0)));

			left_finger_pole = new THREE.Vector3(1,0,0).applyQuaternion(this.ikRig.l_hand_rot);
			let left_thumb_pole = this.ikRig.l_thumb_direction.clone().normalize().cross(left_finger_pole).normalize();
			this.solveFingerIKChain(this.ikRig.l_thumb_direction, left_thumb_pole, this.ikRig.l_thumb_len, 
				SourceSkeleton.vp_bone_map.LeftThumb1, SourceSkeleton.vp_bone_map.LeftThumb2, SourceSkeleton.vp_bone_map.LeftThumb3,
				new THREE.Quaternion().setFromEuler(new THREE.Euler(THREE.MathUtils.degToRad(-30),THREE.MathUtils.degToRad(-50),0)));
			

			let right_finger_pole = new THREE.Vector3(0,0,1).applyQuaternion(this.ikRig.r_hand_rot);
			let right_index_pole = this.ikRig.r_index_direction.clone().normalize().cross(right_finger_pole).normalize();
			this.solveFingerIKChain(this.ikRig.r_index_direction, right_index_pole, this.ikRig.r_index_len, 
							SourceSkeleton.vp_bone_map.RightIndex1, SourceSkeleton.vp_bone_map.RightIndex2, SourceSkeleton.vp_bone_map.RightIndex3,
							new THREE.Quaternion().setFromEuler(new THREE.Euler(0,THREE.MathUtils.degToRad(90),0)));
			
			let right_middle_pole = this.ikRig.r_middle_direction.clone().normalize().cross(right_finger_pole).normalize();
			this.solveFingerIKChain(this.ikRig.r_middle_direction, right_middle_pole, this.ikRig.r_middle_len, 
							SourceSkeleton.vp_bone_map.RightMiddle1, SourceSkeleton.vp_bone_map.RightMiddle2, SourceSkeleton.vp_bone_map.RightMiddle3,
							new THREE.Quaternion().setFromEuler(new THREE.Euler(0,THREE.MathUtils.degToRad(90),0)));

			let right_ring_pole = this.ikRig.r_ring_direction.clone().normalize().cross(right_finger_pole).normalize();
			this.solveFingerIKChain(this.ikRig.r_ring_direction, right_ring_pole, this.ikRig.r_ring_len, 
							SourceSkeleton.vp_bone_map.RightRing1, SourceSkeleton.vp_bone_map.RightRing2, SourceSkeleton.vp_bone_map.RightRing3,
							new THREE.Quaternion().setFromEuler(new THREE.Euler(0,THREE.MathUtils.degToRad(90),0)));

			let right_pinky_pole = this.ikRig.r_pinky_direction.clone().normalize().cross(right_finger_pole).normalize();
			this.solveFingerIKChain(this.ikRig.r_pinky_direction, right_pinky_pole, this.ikRig.r_pinky_len, 
							SourceSkeleton.vp_bone_map.RightPinky1, SourceSkeleton.vp_bone_map.RightPinky2, SourceSkeleton.vp_bone_map.RightPinky3,
							new THREE.Quaternion().setFromEuler(new THREE.Euler(0,THREE.MathUtils.degToRad(90),0)));

			right_finger_pole = new THREE.Vector3(1,0,0).applyQuaternion(this.ikRig.r_hand_rot);
			let right_thumb_pole = this.ikRig.r_thumb_direction.clone().normalize().cross(right_finger_pole).normalize();
			this.solveFingerIKChain(this.ikRig.r_thumb_direction, right_thumb_pole, this.ikRig.r_thumb_len, 
				SourceSkeleton.vp_bone_map.RightThumb1, SourceSkeleton.vp_bone_map.RightThumb2, SourceSkeleton.vp_bone_map.RightThumb3,
				new THREE.Quaternion().setFromEuler(new THREE.Euler(THREE.MathUtils.degToRad(-30),THREE.MathUtils.degToRad(50),0)));
			return;
		}
		//root translation
		const hip_scale = this.target_hip_height/this.getHipHeight(this.source_bones);
		const target_root_scale = new THREE.Vector3().setFromMatrixScale(this.target_root.matrixWorld);
		const parent_transform_invert = this.target_bones[0].parent.matrixWorld.clone().invert();//cancel scale
		const pos = this.source_bones[0].position.clone()
			.multiplyScalar(hip_scale)//scale for foot contact
			.divide(target_root_scale)//without scale, 
			.applyMatrix4(this.target_root.matrixWorld)//apply root transform -> as children of root
			.applyMatrix4(parent_transform_invert)//set position local to parent
		;
		this.target_bones[0].position.copy(pos);
		// this.target_bones[0].position.copy(this.source_bones[0].position);

		//rotations
		const root_rotation_invert = this.target_root.getWorldQuaternion(new THREE.Quaternion()).invert();
		for(let i=0; i<this.source_bones.length; i++){
			if(!this.target_bones[i] || !this.target_bones[i].parent)
				continue;
			const parent_global_rotation=root_rotation_invert.clone().multiply(
				this.target_bones[i]?.parent?.getWorldQuaternion(new THREE.Quaternion())
			);
			
			const q = this.source_bones[i].getWorldQuaternion(new THREE.Quaternion()).multiply(this.tpose_rotations[i]);
			const q2 = parent_global_rotation.clone().invert().multiply(q);
			this.target_bones[i]?.rotation.setFromQuaternion(q2);
		}
	}
}