<template>
  <div class="fixed-plugin">
    <div class="card blur" style="overflow: auto !important;">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Settings</h5>
          <!-- <p>See our dashboard options.</p> -->
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <font-awesome-icon icon="fa-solid fa-close"/>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <!-- <hr class="my-1 horizontal dark" /> -->
      <div class="pt-0 card-body pt-sm-3" >
        <div v-if="this.$store.state.isLoggedIn">
          <!-- Sidenav Type -->
          <div class="mt-3">
            <h6 class="mb-0">Connection</h6>
            <p class="text-sm">Run Red Pill Go then connect to the animation stream</p>
          </div>
          <div class="d-flex">
            <i style="cursor:pointer; margin-left:0px; margin-top:10px; cursor: pointer;" @touchstart="onResetId()" @click="onResetId()"><font-awesome-icon icon="fa-solid fa-rotate-right" /></i>
            <input v-model="room" type="number" class="px-3 mb-2 form-control w-100 ms-2" placeholder="Room ID" >
            <button
              id="btn-white"
              class="px-3 mb-2 btn  w-100 ms-2"
              :class="[connected ? 'active' : '', connected ? 'bg-gradient-danger' : 'bg-gradient-success']"
              
              @click="ConnectClick"
            >
              <i v-if="!connected">Connect</i>
              <i v-else>Disconnect</i> 
            </button>
          </div>
          <p class="mt-2 text-sm d-xl-none d-block">
            You can change the sidenav type just on desktop view.
          </p>
          <!-- Navbar Fixed -->
          <div class="mt-3">
            <h6 class="mb-0">Show Source Skeleton</h6>
          </div>
          <div class="form-check form-switch ps-0">
            <input
              class="mt-1 form-check-input"
              :class="this.$store.state.isRTL ? 'float-end  me-auto' : ' ms-auto'"
              type="checkbox"
              id="navbarFixed"
              :checked="this.$store.state.showSkeleton"
              @change="onSkeleton"
              
            />
          </div>
          <!-- Navbar Fixed -->
          <div class="mt-3">
            <h6 class="mb-0">Animate Hands</h6>
          </div>
          <div class="form-check form-switch ps-0">
            <input
              class="mt-1 form-check-input"
              :class="this.$store.state.isRTL ? 'float-end  me-auto' : ' ms-auto'"
              type="checkbox"
              id="navbarExperimental"
              :checked="this.$store.state.experimental"
              @change="setExperimental"
              
            />
          </div>
          <div class="mt-3">
            <h6 class="mb-0">Mic Level</h6>
          </div>
          
          <input type="range" v-model="micVal" v-on:mouseup="setMicVolume" v-on:touchend="setMicVolume" min="0" max="150" value="0" class="w-100">
        
        
          <hr class="horizontal dark my-sm-4" />
          <a
            class="btn bg-gradient-dark w-100"
            v-on:click="onCalibrate"
            >Calibrate A-pose</a
          >
        </div>
        <a
          v-b-modal.modal-1
          class="btn bg-gradient-dark w-100"
          >Setup WIFI</a
        >
        <!-- <div v-if="this.$store.state.isLoggedIn">
          <a
            class="btn bg-gradient-dark w-100"
            href="https://www.creative-tim.com/product/vue-soft-ui-dashboard-pro"
            >Use Camera</a
          >
        </div> -->
      </div>
    </div>
    <b-modal v-model="modal_con" id="modal-1" title="Enter WIFI SSID and passowrd, Then show the qrcode to Red Pill Go Camera" hide-footer >
       
       <canvas id="qrcode" class="qrcode" > </canvas>
       <div id="test" style=" margin: 5px;">
        <input v-model="ssid" type="email" class="form-control" placeholder="WIFI SSID" required>
       </div>
        <div style=" margin: 5px;">
          <input v-model="wifi_pass" type="password" class="form-control" placeholder="Password" required>
        </div>
       
        <b-button v-on:click="genQRCode"   class="form-control" variant="primary">Generate</b-button>
  
      </b-modal>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import { engine_event } from "../engine";
var QRCode = require('qrcode')
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

// function hashCode(s) 
// {
//   let hashString=0;
//   for (let n = 0; n < s.length; n++) 
//   {
//       let charTemp = s.charCodeAt(n);
//       hashString = ((hashString << 5 )- hashString)+charTemp;
//       hashString = hashString & hashString

//   }
//   hashString = hashString & 0x7fffffff;
//   return hashString.toString();
// }

export default {
  name: "configurator",
  props: ["toggle"],

  data() {
    return {
      fixedKey: "",
      micVal : 100,
      room: '',
      modal_con: null,
      ssid: '',
      wifi_pass: '',
      connected: false,
    };
  },
  methods: {
    ...mapMutations(["navbarMinimize", "sidebarType", "navbarFixed"]),
    ...mapActions(["toggleSidebarColor"]),

    sidebarColor(color = "info") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },

    sidebarType(type) {
      this.toggleSidebarColor(type);
    },

    onSkeleton() {

        this.$store.state.showSkeleton = !this.$store.state.showSkeleton;
        engine_event.emit("onSkeleton",this.$store.state.showSkeleton);
      
    },
    setExperimental() {
        this.$store.state.experimental = !this.$store.state.experimental;
        engine_event.emit('onWrist',this.$store.state.experimental);
    },
    setMicVolume()
    {
      //this.sidebarType('active')
      this.$store.state.micVolume = this.micVal;
      var buffer = new Uint8Array([5,this.micVal]);
      engine_event.emit('onVolume',buffer.buffer);
    },
    onCalibrate()
    {
      var buffer = new Uint8Array([6,1]);
      engine_event.emit('onCalibrate',buffer.buffer);
    },
    async onResetId()
    {
      const profile = await API.get('rpgUserProfile','/profile');
      //let user_string = profile.email+profile.created;
      //let hexText = hashCode(user_string);
      this.room = profile.room_id;//hexText;//profile.last_id;
    },
    genQRCode()
    {
        if(this.ssid!='')
        var canvas = document.getElementById('qrcode');
        var test = document.getElementById('test');
        console.log(test.clientWidth)
       
        QRCode.toCanvas(canvas, 'WIFI:S:'+this.ssid+';T:WPA;P:'+this.wifi_pass+';H:false;;',{
        width: test.clientWidth,
        margin: 4
      }, function (error) 
        {
          if (error) console.error(error)
          //console.log('success!');
           
        })
       
    },
    async ConnectClick()
		{
     
      if(!this.connected)
      {
        try{
          const myInit = {
            headers: { 
              "Content-Type":"application/json"
            },
            body: {
              "last_id": this.room
            }
          }
          API.put('rpgUserProfile','/profile', myInit);

        }
        catch(error)
        {
          console.log(error);
        }
      }
      //this.$root.$emit('connect_webrtc', this.room);
      this.loading = true;
      let promiseResolve;
      const promise = new Promise((resolve) => {promiseResolve=resolve;});
      await engine_event.emit('onConnect',this.room,promiseResolve);
      await promise;
      this.loading = false;
      
    },
  },
  computed: {
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },
  },
  beforeMount: async function() {
    //this.$store.state.isTransparent = "bg-transparent";
    //try 
    {     
      var user = await Auth.currentAuthenticatedUser();
      console.log('Logged in '+user.attributes.email) 
      console.log('Logged in '+user.attributes) 
      //mapMutations(["setLoggedIn"])(true);
      this.$store.commit('setLoggedIn',true)
      if(this.$store.state.isLoggedIn)
      {
        const profile = await API.get('rpgUserProfile','/profile');
        //let user_string = profile.email+profile.created;
        //let hexText = hashCode(user_string);
        this.room = profile.last_id==""?profile.room_id:profile.last_id;
      }

    }   
    // catch(e) 
    // {  
    //   this.$store.commit('setLoggedIn',false)
  
    // } 
  },
  mounted()
  {

    engine_event.addListener('room', function(e) {
      this.room = e;
      this.connected = true;
    }.bind(this));
    engine_event.addListener('disconnect', function() {
      this.connected = false;
    }.bind(this));
  },
};
</script>
<style scoped>
.close {display: none;}
.qrcode
{
  height: 100%; 
  width:100%;
  transition: 0.3s;
  left: 50%;
  
}
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
