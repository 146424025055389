<template>
  <div class="fixed-plugin" >
    <div class="card blur" style="overflow: auto !important;">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Characters</h5>
          <!-- <p>See our dashboard options.</p> -->
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <font-awesome-icon icon="fa-solid fa-close"/>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <!-- <hr class="my-1 horizontal dark" /> -->
      <div  >
        <div v-if="this.$store.state.isLoggedIn" >
          <ul class="list-group" :key="index"  v-for="(char, index) in this.$store.state.characters">
            <li
                class="px-4 mb-2 border-0 list-group-item d-flex align-items-center"
                style="background-color: transparent!important; cursor: pointer;"
                v-on:click="setCharater(`${index}`)"
              >
                <soft-avatar
                  class="me-3"
                  :img="`${char[1]}`"
                  alt="kal"
                  border-radius="lg"
                  shadow="regular"
                />
                <!-- "require(`@/assets/img/marie.jpg`)" -->
                <div
                  class="d-flex align-items-start flex-column justify-content-center"
                >
                  <h6 class="mb-0 text-sm">{{char[3]}}</h6>
                  <p  v-if="char[4]" class="mb-0 text-xs">author: <a onclick="event.cancelBubble=true;" :href="`${char[4].author.url}`" target="_blank">{{char[4].author.name}}</a></p>
                  <p  v-if="char[4]" class="mb-0 text-xs">licens: <a onclick="event.cancelBubble=true;" :href="`${char[4].license.url}`" target="_blank">{{char[4].license.label}}</a> <a onclick="event.cancelBubble=true;" :href="`${char[4].source}`" target="_blank">source</a></p>
                </div>
                <!-- <a
                  class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                  href="javascript:;"
                  >Buy</a
                > -->
              </li>
          </ul>
          
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { engine_event } from '@/engine'
//import * as aws_helper from "@/amplify_helper.js";
import SoftAvatar from "@/components/SoftAvatar.vue";
export default {
  name: "characters_panel",
  components: {
    SoftAvatar,
  },
  props: ["toggle"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["toggleSidebarColor"]),
    async setCharater(name)
		{
		
      if(this.$store.state.characters[name][3] == "Custom")
      {
        //this.displayIframe();
        this.$router.push(`/assets`);
        return;
      }
			this.loading = true;
      let ext = this.$store.state.characters[name][3].split(".").pop();
      if(ext=="vrm")
      {
        let promiseResolve;
        const promise = new Promise((resolve) => {promiseResolve=resolve;});
        const file = this.$store.state.characters[name][0];
        await engine_event.emit('onLoadVRM', file,this.$store.state.characters[name][3], promiseResolve);
        await promise;
      }
      else
      {
        let promiseResolve;
        const promise = new Promise((resolve) => {promiseResolve=resolve;});

        await engine_event.emit('onLoadGLB',  this.$store.state.characters[name][0] ,this.$store.state.characters[name][2], promiseResolve);
        await promise;
      }
      //this.rotateCharacter();
			this.loading = false;
		},
  },
  computed: {
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  watch: {
		loading: function (loading) {
			if (loading) 
			{
				this.loader = this.$loading.show({container: this.$refs.loadingContainer});
			} else 
			{
				this.loader.hide();
				this.loader = null;
			}
		},
  },
};
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>

