<template>
  <div
    class="w-auto collapse navbar-collapse "
    id="sidenav-collapse-main"
    style="display:table-cell;  "
  >
   
    <ul class="navbar-nav ">
      <li class="nav-item" v-if="this.$store.state.isLoggedIn"  @mousemove="showTooltip($event,'Projects')" @mouseleave="hideTooltip">
        <sidenav-collapse navText="Projects" :to="{ name: 'projects' }">
          <template #icon>
            <shop />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" v-if="this.$store.state.isLoggedIn" @mousemove="showTooltip($event,'Profile')" @mouseleave="hideTooltip">
        <sidenav-collapse navText="Profile" :to="{ name: 'profile' }" >
          <template #icon>
            <customer-support />
          </template>
        </sidenav-collapse>
      
      </li>
      <li class="nav-item" v-if="!this.$store.state.isLoggedIn"  @mousemove="showTooltip($event,'Sign In')" @mouseleave="hideTooltip">
        <sidenav-collapse navText="Sign In" :to="{ name: 'login' }">
          <template #icon>
            <document />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item iconClass"  @mousemove="showTooltip($event,'Pricing')" @mouseleave="hideTooltip">
        <sidenav-collapse navText="Pricing" :to="{ name: 'pricing' } " >
          <template #icon >
            <font-awesome-icon icon="fa-solid fa-tag" />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item"  @mousemove="showTooltip($event,'Docs')" @mouseleave="hideTooltip">
        <sidenav-collapse navText="Docs" :to="{ name: 'docs' } ">
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-book" />
          </template>
        </sidenav-collapse>
      </li>

      <div v-if="this.$route.name=='viewer'">
        <br>
        <hr class="mt-0 horizontal dark" />
        <li class="nav-item" @click="toggleCharacterPanel"  @mousemove="showTooltip($event,'Characters')" @mouseleave="hideTooltip">
          <sidenav-collapse navText="Characters" :to="{ name: '' } ">
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-person" />
            </template>
          </sidenav-collapse>
        </li>
        <li class="nav-item" @click="toggleEnvironmentPanel"  @mousemove="showTooltip($event,'Environments')" @mouseleave="hideTooltip">
          <sidenav-collapse navText="Environments" :to="{ name: '' }">
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-panorama" />
            </template>
          </sidenav-collapse>
        </li>
      </div>
      <div v-if="this.$route.name=='viewer' && (this.$route.params.project=='Stage') || this.$store.state.hasSlides==true" @click="toggleSlidesPanel">
        <li class="nav-item" @mousemove="showTooltip($event,'Slides')" @mouseleave="hideTooltip">
          <sidenav-collapse navText="Slides" :to="{ name: '' }">
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-images" />
            </template>
            
          </sidenav-collapse>
        </li>
      </div>

      <div v-if="this.$route.name=='viewer' && (this.$route.params.project=='Stage') || this.$store.state.has3dEnvironment==true" @click="toggleCameraPanel">
        <li class="nav-item"  @mousemove="showTooltip($event,'Camera')" @mouseleave="hideTooltip">
          <sidenav-collapse navText="Camera" :to="{ name: '' }">
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-video" />
            </template>
            
          </sidenav-collapse>
        </li>
      </div>
      <div v-if="this.$route.name=='viewer'" @click="toggleRecordingPanel">
        <li class="nav-item" @mousemove="showTooltip($event,'Recording')" @mouseleave="hideTooltip">
          <sidenav-collapse navText="Recording" :to="{ name: '' }">
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-clapperboard" />
            </template>
            
          </sidenav-collapse>
        </li>
      </div>
    </ul>
    <!-- <div class="fixed-plugin">
      <a
        class="nav-item px-3 py-2 fixed-plugin-button text-dark "
        style="margin-left:-10px!important; bottom: 0!important; position: relative!important; display:table-cell;"
        @click="toggleConfigurator"
        @mousemove="showTooltip($event,'Settings')" @mouseleave="hideTooltip"
      >
      <font-awesome-icon icon="fa-solid fa-gear" />
        <i class="py-2 fa fa-cog"> </i>
      </a>
    </div> -->
    
    <!-- <div class="p-3 card-body">
    </div>
    <div class="p-3 card-body">
    </div> -->
    
  </div>
  
  <!-- <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :class="cardBg"
      textPrimary="Need Help?"
      textSecondary="Please check our docs"
      route="https://www.creative-tim.com/learning-lab/vue/overview/soft-ui-dashboard/"
      label="Documentation"
      icon="ni ni-diamond"
    />
    <a
      class="btn bg-gradient-success mt-4 w-100"
      href="https://www.creative-tim.com/product/vue-soft-ui-dashboard-pro?ref=vsud"
      type="button"
      >Upgrade to pro</a
    >
  </div> -->
  
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
//import SidenavCard from "./SidenavCard.vue";
import Shop from "../../components/Icon/Shop.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Document from "../../components/Icon/Document.vue";
import { mapMutations } from "vuex";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
    onShowTooltip: {
      required: true,
    },
    onHideTooltip: {
      required: true,
    },
  },
  mounted()
  {
    
  },
  data() {
    return {
      title: "Red Pill Go",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    SidenavCollapse,
    //SidenavCard,
    Shop,
    //Office,
    //CreditCard,
    //Box3d,
    CustomerSupport,
    Document,
    //Spaceship,
    //Settings,
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator", "toggleCharacterPanel", "toggleEnvironmentPanel", "toggleSlidesPanel","toggleRecordingPanel", "toggleCameraPanel"]),
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    showTooltip(evt,text) {
     
     if(this.onShowTooltip!=undefined)
       this.onShowTooltip(evt.pageX,evt.pageY,text);
     
   },
   hideTooltip() {
     if(this.onHideTooltip!=undefined)
       this.onHideTooltip()
     //var tooltip = document.getElementById("tooltip");
     //tooltip.style.display = "none";
     //console.log("HIDE");
   },
  },
};
</script>
<style scoped>
.fa:active
{
  color: white;
}
.iconClass2
{
  color: black;
}
</style>
