
import VueRouter from 'vue-router';
import Editor from '../views/Home2.vue';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import store from '../store'
const routes = [
    {
      path: "/",
      name: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "home",
      beforeEnter: checkLogged,
      component: Editor
      // component: () => import(/* webpackChunkName: "home" */ '../views/Login.vue'),
    },
    {
      path: "/viewer/:project",
      name: "viewer",
      beforeEnter: guardLogged,
      component: () => import(/* webpackChunkName: "viewer" */ '../views/Viewer.vue'),
      props: true
    },
    {
      path: "/payment",
      name: "payment",
      beforeEnter: guardLogged,
      component: () => import(/* webpackChunkName: "viewer" */ '../views/Payment.vue'),
    },
    {
      path: "/profile",
      name: "profile",
      beforeEnter: guardLogged,
      component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    },
    {
      path: "/assets",
      name: "assets",
      beforeEnter: guardLogged,
      component: () => import(/* webpackChunkName: "profile" */ '../views/Assets.vue'),
    },
    {
        path: "/login",
        name: "login",
        beforeEnter: skipLogged,
        component: () => import(/* webpackChunkName: "login" */ '../views/SignIn.vue'),
    },
    {
      path: "/projects",
      name: "projects",
      beforeEnter: guardLogged,
      component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
    },
    {
      path: '/admin',
      name: 'Admin',
      beforeEnter: checkAdminRights,
      component: () =>
          import(/* webpackChunkName: "market" */ '../views/Admin.vue'),
    },
    {
      path: '/license',
      name: 'License',
      beforeEnter: checkAdminRights,
      component: () =>
          import(/* webpackChunkName: "market" */ '../views/License.vue'),
    },
    {
      path: "/docs",
      name: "docs",
      beforeEnter(){window.open("https://docs.rplab.online");}
    },
    {
      path: "/pricing",
      name: "pricing",
      component: () =>
          import(/* webpackChunkName: "market" */ '../views/Pricing.vue'),
    },
    
]
async function checkLogged(to, from, next) {

  try 
    {     
      var user = await Auth.currentAuthenticatedUser();
      console.log('Logged in '+user.attributes.email) 
      //mapMutations(["setLoggedIn"])(true);
      store.commit('setLoggedIn',true)
      next();
    }   
    catch(e) 
    {  
      store.commit('setLoggedIn',false)
      next();   
    } 
  
}

async function guardLogged(to, from, next) {

  try 
    {     
      var user = await Auth.currentAuthenticatedUser();
      console.log('Logged in '+user.attributes.email)
      store.state.email = user.attributes.email;
      //mapMutations(["setLoggedIn"])(true);
      store.commit('setLoggedIn',true)
      next();
    }   
    catch(e) 
    {  
      store.commit('setLoggedIn',false)
      store.state.email = undefined;
      next("/login");   
    } 
  
}

async function skipLogged(to, from, next) {

  try 
    {     
      var user = await Auth.currentAuthenticatedUser();
      console.log('Logged in '+user.attributes.email) 
      //mapMutations(["setLoggedIn"])(true);
      store.commit('setLoggedIn',true)
      next('/home');
    }   
    catch(e) 
    {  
      store.commit('setLoggedIn',false)
      next();   
    } 
  
}

async function checkAdminRights(to, from, next) {
   
  const profile = await API.get('rpgUserProfile','/profile');
  let userIsAdmin = profile.isAdmin;
  if(userIsAdmin) 
  {
    store.state.isAdmin=true;
    next();
  }
  else
  {
    store.state.isAdmin=false;
    next("/");
  }
}


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
});
  
  export default router;