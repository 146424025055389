import { Auth } from 'aws-amplify';
import { Signer } from "@aws-amplify/core";
import { Storage} from 'aws-amplify';
import store from '@/store'

export async function getCredentials()
{
    return await Auth.currentCredentials();
}
export async function getS3(path) {
    let result;
    try{
        result = await Storage.get(path,{level:"public", download:true});
    }
    catch(err){
        console.log(err);
    }
    console.log(result);
    return  URL.createObjectURL(await result.Body.blob());
}

export async function getS3File(path, cache = 'default', with_etag = false ) {
    let encoded_path = path.replace('+', '%2B').replace('@', '%40').replace(':', '%3A');
    const credentials =  await Auth.currentCredentials();
    const signature = Signer.sign(
        {
        url: "https://red-pill-go.s3.ap-northeast-2.amazonaws.com/"+path,
        method: "GET",
        headers: {
            "x-amz-content-sha256":
            "e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855", // this is the SHA256 of an empty string ("")
        }
        },
        {
            access_key: credentials.accessKeyId,
            secret_key: credentials.secretAccessKey,
            session_token: credentials.sessionToken
        },
        { region: "ap-northeast-2", service: "s3" }
    );
    try{
        var result = await fetch("https://red-pill-go.s3.ap-northeast-2.amazonaws.com/"+encoded_path,
            {
                cache: cache,
                method: "GET",
                headers : signature.headers
            });
            if(with_etag)
            {
                let eTag = result.headers.get('ETag');
                eTag = eTag.replace(/['"]+/g, '');
                console.log(eTag);
                return [URL.createObjectURL(await result.blob()),eTag];
            }

        return  URL.createObjectURL(await result.blob());
    }catch(e){
        console.warn(e);
    }
}
export async function getProtectedS3File(path) {
    let encoded_path = path.replace('+', '%2B').replace('@', '%40').replace(':', '%3A');
    const credentials =  await Auth.currentCredentials();
    const signature = Signer.sign(
        {
        url: "https://red-pill-go.s3.ap-northeast-2.amazonaws.com/protected/"+store.state.email+'/'+credentials.identityId+"/"+path,
        method: "GET",
        headers: {
            "x-amz-content-sha256":
            "e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855" // this is the SHA256 of an empty string ("")
        }
        },
        {
            access_key: credentials.accessKeyId,
            secret_key: credentials.secretAccessKey,
            session_token: credentials.sessionToken
        },
        { region: "ap-northeast-2", service: "s3" }
    );
    try{
        var result = await fetch("https://red-pill-go.s3.ap-northeast-2.amazonaws.com/protected/"+store.state.email+'/'+credentials.identityId+"/"+encoded_path,
            {
                method: "GET",
                headers : signature.headers
            });

        return  URL.createObjectURL(await result.blob());
    }catch(e){
        console.warn(e);
    }
}
export async function putS3File(path, size) {
    const credentials =  await Auth.currentCredentials();
    const signature = Signer.sign(
        {
        url: "https://red-pill-go.s3.ap-northeast-2.amazonaws.com/private/"+credentials.identityId+"/"+path,
        method: "PUT",
        headers: {
            "x-amz-content-sha256": "e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855", // this is the SHA256 of an empty string ("")
            "Content-Length": size.toString()
        }
        },
        {
            access_key: credentials.accessKeyId,
            secret_key: credentials.secretAccessKey,
            session_token: credentials.sessionToken
        },
        { region: "ap-northeast-2", service: "s3" }
    );
    return signature;
}