<template>
  <div class="" :class="getSize(size)">
    <div class="grid-container">
      <div class="item1 float-start" style="cursor:pointer; margin-left: -30px;" v-on:click="cam.active=!cam.active; activate(cam,cam.active);">
        <div v-if="this.cam.active" ><font-awesome-icon icon="fa-solid fa-video" style="color:red"/></div>
        <div v-else ><font-awesome-icon icon="fa-solid fa-video" /></div>
      </div>
      <div class="item2" style="cursor:pointer; margin-right: 10px;">
        <a>{{cam.type}}</a><CustomSelect v-model="cam.type"
        :options="['Fixed','Slider','Crane']"
        :default="cam.type"
        class="select"
        v-on:input="onType"/></div>
        <div class="item3 float-end" style="cursor:pointer; margin-left: -10px;" @click="del(cam)"><font-awesome-icon icon="fa-solid fa-trash"/></div>
    </div>
    
    <div class="mt-3" style="margin-left:5px;">
      <div class="grid-container2">
        <a class="mb-0">fov: {{Math.round(this.cam.fov)}}</a>
        <input type="range" value="cam.fov" v-on:mouseup="setFov" v-on:touchend="setFov"  v-on:input="setFov" min="15" max="120" class="w-85" >
      </div>
      <div class="grid-container2">
        <a class="item1 mb-0">duration: {{this.cam.duration}}</a>
        <input class="item2 w-85" type="range" v-model="time" v-on:mouseup="setTime" v-on:touchend="setTime" min="1" max="120" value="5" >
      </div>
    </div>
    <div v-if="cam.type=='Slider' || cam.type=='Crane'" class="mt-1" style="margin-left:5px;">
      <a class="float-start" style="cursor:pointer" v-on:click="onStartPin"><font-awesome-icon icon="fa-solid fa-location-pin"/> start</a>
      <a class="float-end" style="cursor:pointer; margin-right: 15px;" v-on:click="onEndPin"><font-awesome-icon icon="fa-solid fa-location-dot"/> stop</a>
    </div>
    <div v-else class="mt-1" style="margin-left:5px;">
      <a class="float-start" style="cursor:pointer" v-on:click="onStartPin"><font-awesome-icon icon="fa-solid fa-location-pin"/> start</a>
     
    </div>
  </div>
</template>

<script>
import CustomSelect from "@/components/CustomSelect.vue";
import Camera from "@/camera.js";
import { engine_event } from "../engine";
export default {
  name: "ProjectCard",
  components: {
    CustomSelect,
  },
  props: {
    cam: {
      type: Camera,
      required: true,
    },
    del: {
      required: false,
    },
    activate:{
      required: false,
    },
    size: {
      type: String,
      default: null,
    },
    shadow: {
      type: String,
      default: null,
    },
    borderRadius: {
      type: String,
      default: null,
    },
    circular: Boolean,
  },
  methods: {
    getSize: (size) => (size ? `avatar-${size}` : null),
    setFov(event)
    {
      this.cam.fov = event.target.value;
      this.$emit('update:cam.fov', event.target.value)
     
    },
    onType(event)
    {
      console.log(event);
      //this.cam.type = event;
    },
    onStartPin()
    {
      engine_event.emit('getViewportCamera', (camera)=>{
        this.cam.setStart(camera.matrix);
      });
      
    },
    onEndPin()
    {
      engine_event.emit('getViewportCamera', (camera)=>{
        this.cam.setEnd(camera.matrix);
      });
    },
    setTime(event)
    {
      this.cam.duration = event.target.value;
      //this.$emit('update:cam.fov', event.target.value)
      //console.log(this.cam.type+" "+this.cam.fov);
    },
    setActive(a)
    {
      this.cam.active = a;
    },
    getClasses: (shadow, circular, borderRadius) => {
      let shadowValue, circularValue, borderRadiusValue;

      if (shadow) {
        shadowValue = shadow === "regular" ? "shadow" : `shadow-${shadow}`;
      } else {
        shadowValue = null;
      }

      circularValue = circular ? "rounded-circle" : null;

      borderRadiusValue = borderRadius ? `border-radius-${borderRadius}` : null;

      return `${shadowValue} ${circularValue} ${borderRadiusValue}`;
    },
  },
  data() {
    return {
      //selected_type: null,
      fov:this.cam.fov,
      time:5,
      //active:false,
    };
  },
};
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 20px auto 20px;
  grid-gap: 0px;
  /* background-color: #2196F3; */
  padding: 0px;
}
.grid-container > div {
  /* background-color: rgba(255, 255, 255, 0.8); */
  text-align: center;
  padding: 0px 0;
  
}
.grid-container2 {
  display: grid;
  grid-template-columns: 110px auto;
  grid-gap: 0px;
  /* background-color: #2196F3; */
  padding: 0px;
}
</style>